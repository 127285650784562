import PageLayout from "../../components/PageLayout";
import { useContext } from "react";
import { UserUnitContext } from "../../contexts/userUnitContext";
import { useTranslation } from "react-i18next";
import AdvisorCard from "./components/AdvisorCard";
import useAdvisor from "../../hooks/useAdvisor";
import UnionEducationCard from "./components/UnionEducationCard";
import ProgressCard from "./components/ProgressCard";
import CardDeck from "react-bootstrap/CardDeck";
import "./HomePage.scss";
import BuildOcCard from "./components/BuildOcCard";
import Spinner from "../../components/Spinner";
import NonMemberHomePage from "./components/NonMemberHomePage";
import MapWorkplaceCard from "./components/MapWorkplaceCard";
import useOcMembers from "../../hooks/useOcMembers";
import useCoworkers from "../../hooks/useCoworkers";
import { ocMemberGoal } from "../../utilities/oc";

// For /ochome
function OcHomePage() {
  const { unit, loading: userUnitLoading } = useContext(UserUnitContext);
  const [advisor, advisorLoading] = useAdvisor(unit?.advisorId);
  const [ocMembers, ocMembersLoading] = useOcMembers({ unitId: unit?.id });
  const [coworkers, coworkersLoading] = useCoworkers({ unitId: unit?.id });

  const { t } = useTranslation();

  return (
    <PageLayout pageTitle={t("pageName.home")} requireVerifiedEmail>
      <OcHomePageContents
        unit={unit}
        advisor={advisor}
        ocMembers={ocMembers}
        coworkers={coworkers}
        // wait for everything to load before rendering page, we don't want to flash through the
        // dashboard progression as each element loads in
        loading={
          userUnitLoading ||
          advisorLoading ||
          ocMembersLoading ||
          coworkersLoading
        }
        userUnitLoading={userUnitLoading}
      />
    </PageLayout>
  );
}

function OcHomePageContents({
  unit,
  loading,
  userUnitLoading,
  advisor,
  ocMembers,
  coworkers,
}) {
  if (userUnitLoading) return <Spinner />;
  if (!unit) return <NonMemberHomePage />;
  if (loading) return <Spinner />;

  const numNonManagerEmployees = unit.numNonManagerEmployees;
  const numOcGoal = ocMemberGoal(numNonManagerEmployees);
  const voteThreshold = unit.voteThreshold ?? 0.6;
  const numMappedGoal = Math.ceil(voteThreshold * numNonManagerEmployees);

  const { r1c, r2c1, r2c2 } = getCards();

  function getCards() {
    const signaturesCard = (
      <ProgressCard
        unit={unit}
        showMembersLink={true}
        className="mx-auto mb-3"
      />
    );
    const buildOcCard = (
      <BuildOcCard
        ocMembers={ocMembers}
        numNonManagerEmployees={numNonManagerEmployees}
        numOcGoal={numOcGoal}
      />
    );
    const advisorCard = <AdvisorCard advisor={advisor} />;
    const mapWorkplaceCard = (
      <MapWorkplaceCard
        coworkers={coworkers}
        numNonManagerEmployees={numNonManagerEmployees}
      />
    );

    if (ocMembers.length === 1) {
      return { r1c: buildOcCard, r2c1: null, r2c2: null };
    } else if (ocMembers.length < numOcGoal) {
      return {
        r1c: buildOcCard,
        r2c1: mapWorkplaceCard,
        r2c2: advisorCard,
      };
    } else if (coworkers.length < numMappedGoal) {
      return {
        r1c: null,
        r2c1: mapWorkplaceCard,
        r2c2: advisorCard,
      };
    } else {
      return {
        r1c: signaturesCard,
        r2c1: advisorCard,
        r2c2: mapWorkplaceCard,
      };
    }
  }

  return (
    <div className="home-page-container">
      <CardDeck className="with-max-width">{r1c}</CardDeck>
      <CardDeck className="my-3">
        {r2c1}
        {r2c2}
      </CardDeck>
      <UnionEducationCard className="my-3" />
    </div>
  );
}

export default OcHomePage;
