import colors from "../../styles/colors.module.scss";

function EnvelopePencilIcon({ color = "black", size = "140", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 140 140"
      title="Envelope pencil icon"
      width={size}
      height={size}
      {...props}
    >
      <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
        <path
          d="M22.63,14.87,15,22.5l-3.75.75L12,19.5l7.63-7.63a2.114,2.114,0,0,1,2.992,0l.008.008A2.114,2.114,0,0,1,22.63,14.87Z"
          fill="none"
          stroke={colors[color]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
        <path
          d="M11.25,15.75h-9a1.5,1.5,0,0,1-1.5-1.5v-12A1.5,1.5,0,0,1,2.25.75h18a1.5,1.5,0,0,1,1.5,1.5v6"
          fill="none"
          stroke={colors[color]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
        <path
          d="M21.411,1.3,13.267,7.564a3.308,3.308,0,0,1-4.034,0L1.089,1.3"
          fill="none"
          stroke={colors[color]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
      </g>
    </svg>
  );
}

export default EnvelopePencilIcon;
