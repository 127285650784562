import { ModalContext } from "../contexts/modalContext";
import { useContext } from "react";
import AddCoworkerModal from "./addEdit/AddCoworkerModal";
import EditCoworkerModal from "./addEdit/EditCoworkerModal";
import InviteCoworkerModal from "./invite/InviteCoworkerModal";
import DeleteCoworkerModal from "./delete/DeleteCoworkerModal";
import ChangePasswordModal from "./password/ChangePasswordModal";
import EditUnitModal from "./editUnit/EditUnitModal";
import LeaveUnitModal from "./leaveUnit/LeaveUnitModal";
import UpdateOcMembershipModal from "./updateOcMembership/UpdateOcMembershipModal";
import InviteToOcModal from "./inviteToOc/InviteToOcModal";
import TagsModal from "./tags/TagsModal";

function ModalPicker() {
  const { modal } = useContext(ModalContext);

  switch (modal?.name) {
    case "invite":
      return <InviteCoworkerModal {...modal.props} />;
    case "add":
      return <AddCoworkerModal {...modal.props} />;
    case "edit":
      return <EditCoworkerModal {...modal.props} />;
    case "tags":
      return <TagsModal {...modal.props} />;
    case "delete":
      return <DeleteCoworkerModal {...modal.props} />;
    case "password":
      return <ChangePasswordModal {...modal.props} />;
    case "editUnit":
      return <EditUnitModal {...modal.props} />;
    case "leaveUnit":
      return <LeaveUnitModal {...modal.props} />;
    case "updateOcMembership":
      return <UpdateOcMembershipModal {...modal.props} />;
    case "inviteToOc":
      return <InviteToOcModal {...modal.props} />;
    default:
      return null;
  }
}

export default ModalPicker;
