import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import SearchIcon from "./icons/SearchIcon";

function SearchInput({ setFilterText, placeholder, value, ...props }) {
  return (
    <InputGroup {...props}>
      <Form.Control
        data-cy="search-input"
        onChange={(event) => {
          setFilterText(event.target.value);
        }}
        placeholder={placeholder}
        value={value}
      />
      <InputGroup.Append>
        <InputGroup.Text>
          <SearchIcon size={16} />
        </InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  );
}

export default SearchInput;
