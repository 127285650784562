import { useState, useEffect } from "react";
import useFirestoreDoc from "./useFirestoreDoc";

class InviteValidationError extends Error {
  constructor(message = "The link is invalid or has already been used") {
    super(message);
    this.name = "InviteValidationError";
  }
}

/* Hook to subscribe to an invite document
 * @param inviteId
 * @returns [invite, loading, error]
 *   invite: contains the contents of the document if one exists, or null
 *   loading: bool
 *   error: FirebaseError if we cannot load data or InviteValidationError if
 *     the invite ID is invalid or the invite was already used
 */
export default function useInvite(inviteId, oc = false) {
  const [invite, loading, dbError] = useFirestoreDoc(
    inviteId ? `${oc ? "ocInvites" : "invites"}/${inviteId}` : null
  );
  const [error, setError] = useState(null);

  // Validate that the invite exists and has not been used
  // Set the error if the invite is invalid
  useEffect(() => {
    if (loading) {
      setError(null);
    } else if (!invite || invite.used || invite.expired) {
      setError(new InviteValidationError());
    } else if (dbError) {
      setError(dbError);
    }
  }, [invite, loading, dbError]);

  return [invite, loading, error];
}
