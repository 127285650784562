function TableBody({ rows, getTableBodyProps, prepareRow, onClickEntry }) {
  return (
    <tbody {...getTableBodyProps()}>
      {rows.map((row) => {
        prepareRow(row);
        return (
          <TableRow
            key={`row-${row.id}`}
            row={row}
            onClickEntry={onClickEntry}
          />
        );
      })}
    </tbody>
  );
}

function TableRow({ row, onClickEntry }) {
  return (
    <tr {...row.getRowProps()} data-cy={"row-" + row.original.id}>
      {row.cells.map((cell) => {
        return (
          <td
            {...cell.getCellProps()}
            data-cy={`cell-${cell.column.id}`}
            onClick={() => {
              // This is kind of a hack to stop hijacking the click from the checkbox column
              // I looked at other alternates like e.stopPropagation but that can only easily target the
              // checkbox itself, not the padding around it, which is easy enough to hit (and then
              // without this hack you'd get navigated away and lose all your selections.)
              if (cell.column.id === "selection") {
                return;
              }
              onClickEntry(row.original);
            }}
            style={{ cursor: "pointer" }}
          >
            {cell.render("Cell")}
          </td>
        );
      })}
    </tr>
  );
}

export default TableBody;
