import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function LoginLink(props) {
  const { t } = useTranslation();

  return (
    <div className {...{ "data-cy": "login-link", ...props }}>
      <small className="text-secondary">
        {t("account.alreadyHave")} <Link to="login">{t("account.login")}.</Link>
      </small>
    </div>
  );
}

export default LoginLink;
