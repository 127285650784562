import { Link } from "react-router-dom";
import MemberCheck from "./MemberCheck";
import OcMemberCheck from "./OcMemberCheck";
import YouCheck from "./YouCheck";
import PendingOcInviteCheck from "./PendingOcInviteCheck";

// you can either pass a coworker or these individually
function NameAndTitle({
  showOc = true, // show the fist
  showSigned = true, // show the green check
  coworker,
  firstName,
  lastName,
  jobTitle,
  isMember,
  isOcMember,
  memberUid, // to determine if this is "(you)"
  pendingOcInvite, // for /oc page
  linkTo = null,
  ...props
}) {
  if (coworker) {
    firstName = coworker.firstName;
    lastName = coworker.lastName;
    jobTitle = coworker.jobTitle;
    isMember = !!coworker.memberUid;
    isOcMember = coworker.isOcMember;
    memberUid = coworker.memberUid;
  }
  return (
    <div {...props} data-cy="name-and-title">
      <div>
        <span className="semibold">
          {linkTo ? (
            <Link to={linkTo} onClick={(e) => e.stopPropagation()}>
              {firstName} {lastName}
            </Link>
          ) : (
            <>
              {firstName} {lastName}
            </>
          )}
        </span>{" "}
        <PendingOcInviteCheck pendingOcInvite={pendingOcInvite} />{" "}
        <YouCheck memberUid={memberUid} />{" "}
        {showSigned && <MemberCheck isMember={isMember} />}{" "}
        {showOc && <OcMemberCheck isOcMember={isOcMember} />}
      </div>
      <small className="text-secondary">{jobTitle}</small>
    </div>
  );
}

export default NameAndTitle;
