import Alert from "react-bootstrap/Alert";
import { formatShortDate } from "../../../utilities/dateTime";
import { useState, useEffect } from "react";
import { useTranslation, Trans } from "react-i18next";

function NewMemberAlert({ members, currentUserUid, ...props }) {
  const [show, setShow] = useState(false);
  const [newMembers, setNewMembers] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    // Show members who joined within the last 7 days
    // and are not the current user
    const sevenDaysAgo = Date.now() - 7 * 24 * 60 * 60 * 1000;
    const membersToShow = members
      .filter((member) => {
        const joinedDate = member.matchedToMember?.toDate().getTime() || 0;
        return joinedDate > sevenDaysAgo && member.memberUid !== currentUserUid;
      })
      .sort(
        (a, b) =>
          (b.matchedToMember?.toDate().getTime() || 0) -
          (a.matchedToMember?.toDate().getTime() || 0)
      );
    setNewMembers(membersToShow);
    setShow(!!membersToShow.length);
  }, [members, currentUserUid]);

  return (
    <Alert
      variant="primary"
      onClose={() => setShow(false)}
      show={show}
      dismissible
      {...props}
    >
      <Alert.Heading>
        {t("signatures.newCount", { count: newMembers.length })}
      </Alert.Heading>
      <ul className="mb-0">
        {newMembers.map(({ firstName, lastName, matchedToMember, id }) => {
          return (
            <li>
              <Trans
                i18nKey="signatures.signedDate"
                components={[
                  <Alert.Link href={`/coworker/${id}`}></Alert.Link>,
                ]}
                values={{
                  firstName,
                  lastName,
                  date: formatShortDate(matchedToMember),
                }}
              />
            </li>
          );
        })}
      </ul>
    </Alert>
  );
}

export default NewMemberAlert;
