import PageLayout from "../../components/PageLayout";
import { UserUnitContext } from "../../contexts/userUnitContext";
import { useContext, useState, useEffect } from "react";
import MemberProfile from "./components/MemberProfile";
import AccountSettings from "./components/AccountSettings";
import UnitProfile from "./components/UnitProfile";
import CardDeck from "react-bootstrap/CardDeck";
import { useTranslation } from "react-i18next";

function AccountPage() {
  const { t } = useTranslation();
  const { user, unit } = useContext(UserUnitContext);
  const [canEditPassword, setCanEditPassword] = useState(false);

  useEffect(() => {
    // a single user can technically have multiple providers but we specifically care if they
    // have the provider for logging in with email/password
    const canEditPassword = user?.providerData?.some(
      (provider) => provider.providerId === "password"
    );
    setCanEditPassword(canEditPassword);
  }, [user]);

  // TODO it's possible this page shows nothing but the header if
  // the user is not in a unit and does not have a password
  return (
    <PageLayout pageTitle="Account">
      <h3>{t("account.title")}</h3>

      {unit ? (
        <CardDeck>
          <UnitProfile unit={unit} />
          <MemberProfile />
        </CardDeck>
      ) : null}
      {unit || canEditPassword ? (
        <AccountSettings
          showEditPassword={canEditPassword}
          showLeaveUnit={!!unit}
        />
      ) : null}
    </PageLayout>
  );
}

export default AccountPage;
