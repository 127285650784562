import { useTranslation } from "react-i18next";
import LinkButton from "./buttonsAndLinks/LinkButton";
import { ModalContext } from "../contexts/modalContext";
import { useContext } from "react";

// shows a clickable (invite pending) if there's a pendingOcInvite
function PendingOcInviteCheck({ pendingOcInvite }) {
  const { t } = useTranslation();
  const { setModal, setShowModal } = useContext(ModalContext);
  if (!pendingOcInvite) return null;
  return (
    <>
      (
      <LinkButton
        data-cy="oc-invite-pending"
        onClick={(e) => {
          setModal({
            name: "inviteToOc",
            props: {
              coworkerId: pendingOcInvite?.invitee?.coworkerId,
              isLoadingCreatedData: true,
            },
          });
          setShowModal(true);
          e.stopPropagation(); // need to stop the click from going to the parent or it'll navigate to coworker detail
        }}
      >
        {t("oc.invitePending")}
      </LinkButton>
      )
    </>
  );
}

export default PendingOcInviteCheck;
