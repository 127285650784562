import "./WizardStep.scss";
import firebase from "firebase/compat/app";
import { useEffect } from "react";

function WizardStep({
  name,
  headerText,
  subText,
  post = null,
  action,
  backButton = null,
}) {
  useEffect(() => {
    firebase.analytics().logEvent(`eligibility_${name}`);
  }, [name]);

  return (
    <div className="wizard-step">
      <div>
        <h2>{headerText}</h2>
        <div className="text-secondary mb-4">{subText}</div>
      </div>

      <div className="d-flex align-items-end">
        {backButton}
        {action}
      </div>
      {post}
    </div>
  );
}

export default WizardStep;
