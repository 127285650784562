import colors from "../../styles/colors.module.scss";

// Based on Streamline icons with added ability to modify color and direction
// direction can be "up" or "down"
function ArrowIcon({
  show = true,
  color = "black",
  direction = "up",
  className,
}) {
  const visibilityClass = show ? "visible" : "invisible";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 140 140"
      width="140"
      height="140"
      data-cy={`arrow-icon-${direction}`}
      className={`${visibilityClass} ${className}`}
      title={`${direction} arrow icon`}
    >
      <g transform="matrix(5.833333333333333,0,0,5.833333333333333,0,0)">
        <path
          d={direction === "up" ? "M12 23.25L12 0.75" : "M12 0.75L12 23.25"}
          fill="none"
          stroke={colors[color]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
        <path
          // d="M1.5 12.75L12 23.25 22.5 12.75"
          d={
            direction === "up"
              ? "M22.5 11.25L12 0.75 1.5 11.25"
              : "M1.5 12.75L12 23.25 22.5 12.75"
          }
          fill="none"
          stroke={colors[color]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
      </g>
    </svg>
  );
}

export default ArrowIcon;
