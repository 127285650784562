import AuthWidget from "../../../components/AuthWidget";
import PrivacyInfo from "../../../components/PrivacyInfo";
import { NavLink } from "react-router-dom";
import WizardStep from "./WizardStep";
import ContactUnitLink from "../../../components/buttonsAndLinks/ContactUnitLink";
import OutboundLink from "../../../components/buttonsAndLinks/OutboundLink";
import Button from "react-bootstrap/Button";
import { useTranslation, Trans } from "react-i18next";

function EligibilitySuccess({ loggedIn }) {
  const { t } = useTranslation();

  const step = {
    name: "finished",
    headerText: (
      <>
        {t("getStarted.eligibilitySuccess")}
        {"  "}
        {loggedIn ? null : <small>{t("getStarted.signIn")}</small>}
      </>
    ),
    subText: (
      <Trans
        i18nKey="getStarted.moreInfo"
        components={[
          <ContactUnitLink inline></ContactUnitLink>,
          <OutboundLink
            href={process.env.REACT_APP_knowledgeBaseHostname}
          ></OutboundLink>,
        ]}
      />
    ),
    // Found that the Firebase UI widget can throw an error if it is
    // conditionally added to the DOM and the user logs out while on the page
    // with the conditional display. To avoid this, we can just keep the
    // widget in the DOM and conditionally hide it using display: none
    action: (
      <>
        <NavLink
          className={`mx-auto my-3 ${!loggedIn ? "d-none" : "d-block"}`}
          to="/aoe2345nsth"
        >
          <Button>{t("getStarted.createUnitLink")}</Button>
        </NavLink>

        <AuthWidget
          className={`mx-auto my-3 ${loggedIn ? "d-none" : "d-block"}`}
          loginRedirectPath="/aoe2345nsth"
        />
      </>
    ),
    post: <PrivacyInfo className="my-5" />,
  };

  return <WizardStep {...step} />;
}

export default EligibilitySuccess;
