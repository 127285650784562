import Button from "react-bootstrap/Button";
import { useTranslation, Trans } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tag from "../../components/Tag";

// delete a single tag for the unit
function DeleteTag({ tag, setShowDelete, deleteTag }) {
  const { t } = useTranslation();

  function handleCancel() {
    setShowDelete(false);
  }

  async function handleDelete() {
    await deleteTag({ tagId: tag.id });
    setShowDelete(false);
  }

  return (
    <Row>
      <Col>
        <div data-cy="delete-tag-warning">
          <Trans
            i18nKey="tags.deleteAreYouSure"
            components={[<Tag name={tag.name} backgroundColor={tag.color} />]}
          />
        </div>
        {/* borrow the footer styling but drop the padding and border */}
        <div className="modal-footer pt-0 pr-0 border-0">
          <Button
            data-cy="cancel-button"
            variant="secondary"
            onClick={handleCancel}
          >
            {t("tags.cancel")}
          </Button>
          <Button data-cy="delete-button" onClick={handleDelete}>
            {t("tags.delete")}
          </Button>
        </div>
      </Col>
    </Row>
  );
}

export default DeleteTag;
