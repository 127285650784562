import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { useState, useContext, useEffect } from "react";
import useCoworkers from "../../hooks/useCoworkers";
import { UserUnitContext } from "../../contexts/userUnitContext";
import NameAndTitle from "../../components/NameAndTitle";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./ChooseCoworker.scss";
import { ModalContext } from "../../contexts/modalContext";
import { useTranslation } from "react-i18next";
import PlusCircleIcon from "../../components/icons/PlusCircleIcon";
import { SUPPORTIVE_OR_UNDECIDED } from "../../constants/supportRatings";

function ChooseCoworker(props) {
  const { t } = useTranslation();

  const { unit } = useContext(UserUnitContext);
  const { setModal } = useContext(ModalContext);

  const [coworkers, loading] = useCoworkers({ unitId: unit?.id });

  const [selected, setSelected] = useState();

  // Set the coworker we are inviting on the modal
  // Leave the choose coworker open in case they selected wrong
  useEffect(() => {
    setModal({
      name: "invite",
      props: { coworkerId: selected?.id, showChooseCoworker: true },
    });
  }, [selected, setModal]);

  const filterFields = ["firstName", "lastName", "jobTitle"];

  function coworkerOption(coworker) {
    const filterFieldData = {};
    filterFields.forEach((field) => {
      filterFieldData[field] = coworker[field] || "";
    });
    return {
      key: coworker.id,
      value: coworker.id,
      label: `${coworker.firstName} ${coworker.lastName}`,
      disabled:
        !!coworker.memberUid ||
        coworker.supportRating > SUPPORTIVE_OR_UNDECIDED, // enabled for 🤷, 👍 and 👍👍
      supportRating: coworker.supportRating,
      id: coworker.id,
      memberUid: coworker.memberUid,
      ...filterFieldData,
    };
  }

  return (
    <div data-cy="choose-coworker">
      <Typeahead
        id="coworker-invite-select"
        placeholder={t("invite.chooseCoworker.searchPlaceholder")}
        clearButton
        autoFocus
        isLoading={loading}
        defaultOpen={true}
        // Note that selected is always an array even though
        // we don't have multi-select on
        onChange={(selected) => setSelected(selected[0])}
        renderMenu={(results, menuProps) => (
          <CoworkerSelectMenu
            results={results}
            menuProps={menuProps}
            t={t}
            setModal={setModal}
          />
        )}
        filterBy={filterFields}
        options={coworkers.map(coworkerOption)}
        {...props}
      />
    </div>
  );
}

function CoworkerSelectMenu({ results, menuProps, t, setModal }) {
  return (
    <Menu {...menuProps}>
      {results.map((result, index) => {
        return (
          <MenuItem
            key={result.id}
            disabled={result.disabled}
            option={result}
            position={index}
          >
            <CoworkerSelectOption option={result} t={t} />
          </MenuItem>
        );
      })}

      <AddNewCoworker setModal={setModal} t={t} />
    </Menu>
  );
}

function CoworkerSelectOption({ option, t }) {
  return (
    <Row className="coworker-select-option">
      <Col>
        <NameAndTitle coworker={option} />
      </Col>
      <Col>
        <small data-cy="reason-not-selectable">
          {!!option?.memberUid
            ? t("invite.chooseCoworker.alreadyMember")
            : option?.supportRating > SUPPORTIVE_OR_UNDECIDED
            ? t("invite.chooseCoworker.unsupportive")
            : ""}
        </small>
      </Col>
    </Row>
  );
}

function AddNewCoworker({ setModal, t }) {
  return (
    <button
      className="dropdown-button"
      data-cy="add-coworker-instead"
      onClick={() => {
        setModal({ name: "add", props: {} });
      }}
    >
      <PlusCircleIcon id="plus-icon" className="inline-icon-big" />{" "}
      {t("invite.chooseCoworker.createCoworker")}
    </button>
  );
}

export default ChooseCoworker;
