import LinkButton from "./LinkButton";
import { showIntercom } from "../../utilities/intercom";
import { useTranslation } from "react-i18next";

function ContactUnitLink({
  text,
  className = "my-3",
  inline = false,
  children,
  ...props
}) {
  const { t } = useTranslation();

  const button = (
    <LinkButton data-cy="contact-unit-link" onClick={() => showIntercom()}>
      {children || t("askUnit")}
    </LinkButton>
  );

  if (inline) {
    return button;
  }
  return (
    <div className={className} {...props}>
      <span className="text-secondary small">
        {text || t("needHelp")} {button}
      </span>
    </div>
  );
}

export default ContactUnitLink;
