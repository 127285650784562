import Card from "react-bootstrap/Card";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./UnionEducationCard.scss";
import OutboundLink from "../../../components/buttonsAndLinks/OutboundLink";

function UnionEducationCard(props) {
  const { t } = useTranslation();

  // this is the 4 core entries from https://guide.unitworkers.com/tag/crash-courses/
  const data = {
    1: {
      src: "https://guide.unitworkers.com/content/images/size/w400/2022/04/Reclaimi--31--3.png",
      url: "/7-articles-to-help-you-take-effective-collective-action/",
    },
    2: {
      src: "https://guide.unitworkers.com/content/images/size/w400/2022/04/Reclaimi--31--2.png",
      url: "/7-articlesr-anti-union-pushback/",
    },
    3: {
      src: "https://guide.unitworkers.com/content/images/size/w400/2022/04/Reclaimi--31--1.png",
      url: "/6-articles-union-eligible/",
    },
    4: {
      src: "https://guide.unitworkers.com/content/images/size/w400/2022/04/Reclaimi--31-.png",
      url: "/6-articles-to-help-you-understand-what-unions-do-and-how-to-form-one/",
    },
  };

  return (
    <Card data-cy="education" {...props}>
      <Card.Body>
        <Card.Title>{t("home.education.title")}</Card.Title>
        <Carousel className="education-carousel">
          {Object.entries(data).map(([key, { src, url }]) => (
            <Carousel.Item key={`carousel${key}`}>
              <Row>
                <Col sm={4} className="py-3">
                  <OutboundLink
                    data-cy={`guide-image-link-${key}`}
                    href={`${process.env.REACT_APP_knowledgeBaseHostname}${url}`}
                  >
                    <img src={src} alt={t(`home.education.${key}.title`)} />
                  </OutboundLink>
                </Col>
                <Col sm={8} className="pt-3">
                  <h5>{t(`home.education.${key}.title`)}</h5>
                  <p>{t(`home.education.${key}.excerpt`)}</p>
                  <p>
                    <OutboundLink
                      data-cy={`guide-read-more-link-${key}`}
                      className="stretched-link"
                      href={`${process.env.REACT_APP_knowledgeBaseHostname}${url}`}
                    >
                      Read more
                    </OutboundLink>
                    {" • "}
                    {t(`home.education.${key}.date`)}
                    {" • "}
                    {t(`home.education.${key}.length`)}
                  </p>
                </Col>
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </Card.Body>
    </Card>
  );
}

export default UnionEducationCard;
