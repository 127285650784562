import useFirestoreDoc from "./useFirestoreDoc";

// Hook to subscribe to the user's unit
export default function useUnit(unitId, unitIdLoading) {
  const [unit, loading, error] = useFirestoreDoc(
    unitId ? `units/${unitId}` : null,
    unitIdLoading
  );

  return [unit, loading, error];
}
