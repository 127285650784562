import Confetti from "react-confetti";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function UnitCreationSuccess({ unit }) {
  const { t } = useTranslation();

  return (
    <div className="unit-creation-container">
      <Confetti recycle={false} />

      <h2>{t("createUnit.congrats")}</h2>
      <h4 className="text-secondary my-3">
        {t("createUnit.createSuccess", { unitName: unit?.unitName })}
      </h4>

      <Link to="/ochome">
        <Button className="my-4" variant="primary" data-cy="enter-unit-button">
          {t("createUnit.homePageLink")}
        </Button>
      </Link>
    </div>
  );
}

export default UnitCreationSuccess;
