import LinkOutIcon from "../icons/LinkOutIcon";

function OutboundLink({
  children,
  href,
  newTab = true,
  showIcon = false,
  ...props
}) {
  if (newTab) {
    props.target = "_blank";
    props.rel = "noopener noreferrer";
  }
  if (!href) throw new Error("href is a required property");
  // there's no such thing as a disabled link but we can just render the children
  if (props.disabled) {
    return <>{children}</>;
  }
  return (
    <a href={href} {...props}>
      {children}
      {showIcon && <LinkOutIcon className="inline-icon mb-1" />}
    </a>
  );
}

export default OutboundLink;
