import { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { UserUnitContext } from "../../../contexts/userUnitContext";

function SwitchUnit({ onClickSwitch }) {
  const { unit, error } = useContext(UserUnitContext);

  const [newUnitId, setNewUnitId] = useState(null);

  const currentUnitLabel = unit
    ? `${unit.unitName} @ ${unit.companyName} (${unit.id})`
    : "None";

  return (
    <Card>
      <Card.Body>
        <Card.Title>Switch Unit</Card.Title>
        <Card.Text data-cy="current-unit">
          <span className="semibold">Current Unit:</span> {currentUnitLabel}
        </Card.Text>
        <Form.Group>
          <Form.Label>Unit ID</Form.Label>
          <Form.Control
            data-cy="switch-unit-input"
            onChange={(event) => setNewUnitId(event.target.value)}
          />
        </Form.Group>
        <Button
          data-cy="switch-unit-button"
          onClick={() => onClickSwitch(newUnitId)}
        >
          Switch!
        </Button>
      </Card.Body>

      <Alert className="mx-3" show={!!error} variant="danger">
        ERROR: {JSON.stringify(error)}
      </Alert>
    </Card>
  );
}

export default SwitchUnit;
