import "./Tag.scss";
import { getTextColorFromBackgroundColor } from "../utilities/tags";

function Tag({ name, backgroundColor, ...props }) {
  return (
    <>
      {" "}
      <span
        className="tag"
        style={{
          backgroundColor,
          color: getTextColorFromBackgroundColor(backgroundColor),
        }}
        {...props}
      >
        {name}
      </span>{" "}
    </>
  );
}

export default Tag;
