import { useContext, useState } from "react";
import { UserUnitContext } from "../../contexts/userUnitContext";
import PageLayout from "../../components/PageLayout";
import JoinLayout from "../../components/join/JoinLayout";
import JoinLogin from "./components/JoinLogin";
import AuthCardJoin from "../../components/join/AuthCardJoin";
import { useParams } from "react-router-dom";
import useInvite from "../../hooks/useInvite";
import useUnit from "../../hooks/useUnit";
import "./JoinPage.scss";
import AlreadyAMember from "../../components/AlreadyAMember";
import { useTranslation } from "react-i18next";

function JoinPage() {
  const {
    user,
    unit: existingUnit,
    loading: userLoading,
  } = useContext(UserUnitContext);
  const { inviteId } = useParams();
  const [invite, inviteLoading, inviteError] = useInvite(inviteId);
  const [unit, unitLoading, unitError] = useUnit(invite?.unitId);
  const [isWrongPerson, setIsWrongPerson] = useState(false);
  const { t } = useTranslation();

  if (existingUnit) {
    return (
      <PageLayout showNav={true} pageTitle={t("pageName.join")}>
        <AlreadyAMember
          className="join-layout-container"
          unitName={existingUnit.unitName}
          firstName={user?.firstName}
          isOcMember={user?.isOcMember}
        />
      </PageLayout>
    );
  }

  const error = inviteError || unitError;
  const loading = inviteLoading || unitLoading || userLoading || !unit;

  return (
    <PageLayout showNav={false} pageTitle={t("pageName.join")}>
      <JoinLayout
        unit={unit}
        error={error}
        loading={loading}
        isWrongPerson={isWrongPerson}
      >
        {user ? (
          <AuthCardJoin
            unit={unit}
            user={user}
            invite={invite}
            setIsWrongPerson={setIsWrongPerson}
          />
        ) : (
          <JoinLogin
            invite={invite}
            unit={unit}
            setIsWrongPerson={setIsWrongPerson}
          />
        )}
      </JoinLayout>
    </PageLayout>
  );
}

export default JoinPage;
