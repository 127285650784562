import { useState } from "react";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tag from "../../components/Tag";
import FormFields from "../../components/FormFields";
import { HexColorPicker } from "react-colorful";

// edit/create a single tag for the unit
function EditTag({ tag, control, errors, handleSubmit, setShowEdit, editTag }) {
  const { t } = useTranslation();
  const [color, setColor] = useState(tag.color);
  const [name, setName] = useState(tag.name);

  function handleCancel() {
    setShowEdit(false);
  }

  async function handleSave(data) {
    await editTag({
      tag: { id: tag.id, name, color },
    });
    setShowEdit(false);
  }

  const fields = [
    {
      name: "tagName",
      label: t("fieldName.tagName"),
      type: "text",
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.tagName"),
        }),
        maxLength: {
          value: 30,
          message: t("fieldValidation.tag.tooLong"),
        },
      },
      defaultValue: tag.name,
      onChange: (e) => {
        setName(e.target.value);
      },
    },
  ];

  return (
    <Row>
      <Col xs={6}>
        <FormFields fields={fields} control={control} errors={errors} />
        {name && (
          <div>
            <div>{t("tags.preview")}</div>
            <Tag
              data-cy="preview-tag"
              name={name.substring(0, 30)}
              backgroundColor={color}
            />
          </div>
        )}
      </Col>
      <Col xs={6}>
        <HexColorPicker
          style={{ width: "100%" }}
          color={color}
          onChange={setColor}
        />
        <div className="modal-footer pr-0 border-0">
          <Button
            data-cy="cancel-modal"
            variant="secondary"
            onClick={handleCancel}
          >
            {t("tags.cancel")}
          </Button>
          <Button data-cy="save-button" onClick={handleSubmit(handleSave)}>
            {t("tags.save")}
          </Button>
        </div>
      </Col>
    </Row>
  );
}

export default EditTag;
