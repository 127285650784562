import BsModal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "../components/Spinner";
import { ModalContext } from "../contexts/modalContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

// Note that the modal's animation causes a warning in the console when
// react is in strict mode. https://github.com/react-bootstrap/react-bootstrap/issues/5075
// We can get rid of the warning by removing the animation, but the default animation
// is quite nice. For now we just deal with the warning and wait for the
// issue to be resolved.

function Modal({
  showCancel = true,
  loading = false,
  title,
  body,
  footerActions,
  ...props
}) {
  const { showModal, setShowModal } = useContext(ModalContext);
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <BsModal show={showModal} onHide={closeModal} {...props}>
      <BsModal.Header closeButton>
        <BsModal.Title>{title}</BsModal.Title>
      </BsModal.Header>

      <BsModal.Body>{loading ? <Spinner /> : body}</BsModal.Body>

      <BsModal.Footer>
        {showCancel ? <CancelButton onClick={closeModal} /> : null}
        {footerActions}
      </BsModal.Footer>
    </BsModal>
  );
}

function CancelButton({ onClick }) {
  const { t } = useTranslation();

  return (
    <Button data-cy="cancel-modal" onClick={onClick} variant="secondary">
      {t("cancel")}
    </Button>
  );
}

export default Modal;
