import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import Tooltip from "./Tooltip";
import { ModalContext } from "../contexts/modalContext";
import Tag from "./Tag";
import CogIcon from "./icons/CogIcon";

function CoworkerTags({ coworkerId, unitTags, coworkerTags, onClickTag }) {
  const { t } = useTranslation();
  const { setModal, setShowModal } = useContext(ModalContext);

  return (
    <>
      {coworkerTags && (
        <span data-cy="coworker-tags">
          {unitTags
            .filter((tag) => coworkerTags?.[coworkerId]?.includes?.(tag.id))
            .map((tag) => {
              return (
                <Tag
                  backgroundColor={tag.color}
                  name={tag.name}
                  key={`tag-${tag.id}`}
                  onClick={(e) => onClickTag(e, tag)}
                />
              );
            })}
        </span>
      )}

      <Tooltip contents={t("tags.tooltip")}>
        <CogIcon
          size="16"
          data-cy="edit-tags-cog"
          onClick={(e) => {
            setModal({
              name: "tags",
              props: { coworkerId },
            });
            setShowModal(true);
            e.stopPropagation(); // need to stop the click from going to the parent or it'll navigate to coworker detail}
          }}
        />
      </Tooltip>
    </>
  );
}

export default CoworkerTags;
