import Card from "react-bootstrap/Card";
import UnitProgressBar from "../../../components/UnitProgressBar";
import { useTranslation, Trans } from "react-i18next";
import { ModalContext } from "../../../contexts/modalContext";
import { useContext } from "react";
import LinkButton from "../../../components/buttonsAndLinks/LinkButton";

function ProgressCard({
  unit,
  showMembersLink = true,
  className = "",
  ...props
}) {
  const { t } = useTranslation();
  const { setModal, setShowModal } = useContext(ModalContext);

  // home-page-progress className is used by Intercom tour -- careful if changing!
  const mergedClassName = (className + " home-page-progress").trim();

  return (
    <Card
      data-cy="collect-signatures-card"
      className={mergedClassName}
      {...props}
    >
      <Card.Body>
        <Card.Title>{t("home.collectSignatures.title")}</Card.Title>
        <p>
          <Trans
            i18nKey="home.collectSignatures.explanatoryText"
            components={[
              <LinkButton
                onClick={() => {
                  setModal({
                    name: "invite",
                    props: { showChooseCoworker: true },
                  });
                  setShowModal(true);
                }}
              ></LinkButton>,
            ]}
          />
        </p>
        <UnitProgressBar
          className="mt-3"
          unit={unit}
          showMembersLink={showMembersLink}
        />
      </Card.Body>
    </Card>
  );
}

export default ProgressCard;
