import ProgressBar from "react-bootstrap/ProgressBar";
import { NavLink } from "react-router-dom";
import partyPoppers from "../assets/images/party-poppers.svg";
import Image from "react-bootstrap/Image";
import { useTranslation, Trans } from "react-i18next";

function UnitProgressBar({
  unit,
  variant = "primary",
  show = true,
  showMembersLink = true,
  ...props
}) {
  // Gracefully hide if we don't have the data we need
  if (!unit?.numMembers || !unit?.numNonManagerEmployees || !show) {
    return null;
  }

  // We require {voteThreshold}% membership to call for a vote
  const percent = unit.voteThreshold ?? 0.6;
  const numRequired = Math.ceil(percent * unit.numNonManagerEmployees);

  return (
    <div {...props}>
      {unit.numMembers < numRequired ? (
        <PreThreshold
          variant={variant}
          unit={unit}
          showMembersLink={showMembersLink}
          numRequired={numRequired}
        />
      ) : (
        <PostThreshold unit={unit} showMembersLink={showMembersLink} />
      )}
    </div>
  );
}

function PreThreshold({ variant, unit, showMembersLink, numRequired }) {
  const currentPct = (unit.numMembers * 100) / numRequired;
  return (
    <>
      <ProgressBar
        data-cy="unit-progress-bar"
        variant={variant}
        now={currentPct}
      />
      <p className="my-2">
        <Trans
          i18nKey="progressToVoteExplanation"
          components={[
            <NumMembers
              numMembers={unit.numMembers}
              showMembersLink={showMembersLink}
            />,
            <span className="semibold"></span>,
          ]}
          values={{ unitName: unit.unitName, numRequired }}
        />
      </p>
    </>
  );
}

function PostThreshold({ unit, showMembersLink }) {
  return (
    <div className="d-flex align-items-center">
      <Image
        className="m-3"
        src={partyPoppers}
        alt="Party poppers"
        height="80px"
      />
      <p>
        <Trans
          i18nKey="progressPostThresholdExplanation"
          components={[
            <NumMembers
              numMembers={unit.numMembers}
              showMembersLink={showMembersLink}
            />,
          ]}
          values={{ unitName: unit.unitName }}
        />
      </p>
    </div>
  );
}

function NumMembers({ numMembers, showMembersLink }) {
  const { t } = useTranslation();

  const text = t("numMembers", { count: numMembers });
  return showMembersLink ? (
    <NavLink data-cy="num-members-link" to="/signatures">
      {text}
    </NavLink>
  ) : (
    text
  );
}

export default UnitProgressBar;
