import { Badge } from "react-bootstrap";
import { supportRatings } from "../constants/supportRatings";
import Tooltip from "./Tooltip";
import { useTranslation } from "react-i18next";

// Takes the numerical support rating and returns a nicely formatted rating
function SupportRating({ rating, fullText = false, badge = false, ...props }) {
  const { t } = useTranslation();

  if (!rating || !(rating in supportRatings)) {
    rating = 0;
  }

  const { textKey, emoji, color } = supportRatings[rating];
  const text = t(textKey);

  if (badge) {
    return (
      <Badge
        data-cy="support-badge"
        className="semibold"
        style={{ backgroundColor: color }}
        {...props}
      >
        {text} {emoji}
      </Badge>
    );
  } else if (fullText) {
    return (
      <span {...props}>
        {text} {emoji}
      </span>
    );
  } else {
    return (
      <Tooltip contents={text}>
        <span {...props}>{emoji}</span>
      </Tooltip>
    );
  }
}

export default SupportRating;
