import FormFields from "./FormFields";

function GenericSelect({
  selectOptions,
  fieldName,
  control,
  errors,
  onChange,
}) {
  const fields = [
    {
      name: fieldName,
      type: "select",
      selectOptions,
      onChange,
    },
  ];

  return (
    <FormFields
      className="mx-2 mb-2"
      fields={fields}
      control={control}
      errors={errors}
    />
  );
}

export default GenericSelect;
