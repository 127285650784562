import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import TransLinks from "../../../components/TransLinks";
import { ProgressBar } from "react-bootstrap";

function MapWorkplaceCard({ coworkers, numNonManagerEmployees }) {
  const { t } = useTranslation();

  return (
    <Card data-cy="map-workplace-card">
      <Card.Body>
        <Card.Title>{t("home.mapWorkplace.title")}</Card.Title>

        <ProgressBar
          now={100 * (coworkers.length / numNonManagerEmployees)}
          className="mb-2"
        />
        <p>
          <TransLinks
            i18nKey="home.mapWorkplace.explanatoryText"
            urls={[
              "/coworkers",
              `${process.env.REACT_APP_knowledgeBaseHostname}/workplacemapping/`,
            ]}
          />
        </p>

        <Link data-cy="coworkers-link" to="/coworkers">
          <Button size="sm">{t("home.mapWorkplace.button")}</Button>
        </Link>
      </Card.Body>
    </Card>
  );
}

export default MapWorkplaceCard;
