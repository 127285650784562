import { useTranslation } from "react-i18next";
import LinkButton from "../buttonsAndLinks/LinkButton";

function JoinGreeting({ firstName, setIsWrongPerson }) {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <div className="d-inline-block">
        <div className="text-center semibold">
          {firstName
            ? t("join.login.welcome", { firstName })
            : t("join.login.welcomeAnon")}
        </div>
        {firstName ? (
          <LinkButton
            data-cy="wrong-person-link"
            onClick={() => setIsWrongPerson(true)}
          >
            <small>{t("join.login.notYou", { firstName })}</small>
          </LinkButton>
        ) : null}
      </div>
    </div>
  );
}

export default JoinGreeting;
