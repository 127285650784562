import FormFields from "../../components/FormFields";
import { useTranslation } from "react-i18next";

function CaptureEmail({ control, errors, firstName }) {
  const { t } = useTranslation();
  const fields = [
    {
      name: "email",
      label: t("fieldName.email"),
      type: "email",
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.email"),
        }),
      },
    },
  ];

  return (
    <>
      <p>{t("invite.missingEmail", { firstName })}</p>
      <FormFields fields={fields} control={control} errors={errors} />
    </>
  );
}

export default CaptureEmail;
