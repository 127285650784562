import React, { useEffect, useState, useMemo } from "react";
import { useFilters, useGlobalFilter, useSortBy, useTable } from "react-table";
import MemberCheck from "../../../components/MemberCheck";
import NameAndTitle from "../../../components/NameAndTitle";
import SupportRating from "../../../components/SupportRating";
import UnitTable from "../../../components/table/UnitTable";
import useBreakpointColumns from "../../../hooks/useBreakpointColumns";
import useViewportSize from "../../../hooks/useViewportSize";
import { formatShortDate } from "../../../utilities/dateTime";
import Form from "react-bootstrap/Form";
import SearchInput from "../../../components/SearchInput";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useTranslation } from "react-i18next";
import OcMemberCheck from "../../../components/OcMemberCheck";
import CoworkerTags from "../../../components/CoworkerTags";
import useTags from "../../../hooks/useTags";
import Tooltip from "../../../components/Tooltip";
import QuestionCircleIcon from "../../../components/icons/QuestionCircleIcon";
import { Trans } from "react-i18next";
import CogIcon from "../../../components/icons/CogIcon";

// Column headers and accessors for coworkers table
function getColumns(t, unitTags, coworkerTags, setFilterText) {
  return [
    {
      Header: (
        <NameAndTitle
          firstName={t("coworkers.table.name")}
          lastName=""
          jobTitle={t("fieldName.jobTitle")}
        />
      ),
      id: "nameTitle",
      accessor: (row) => `${row?.firstName} ${row?.lastName} ${row.jobTitle}`,
      Cell: ({ row }) => {
        return (
          <NameAndTitle
            coworker={row.original}
            showOc={false}
            showSigned={false}
            linkTo={{
              pathname: `/coworker/${row.original.id}`,
              state: { from: "/coworkers" },
            }}
          />
        );
      },
    },
    {
      Header: t("fieldName.supportRating"),
      id: "supportRating",
      accessor: (row) => {
        return (
          <>
            {row.isOcMember ? (
              <OcMemberCheck isOcMember={true} />
            ) : (
              <SupportRating rating={row.supportRating} />
            )}{" "}
            {!!row.memberUid && <MemberCheck isMember={true} />}
          </>
        );
      },
      // custom sort function to order like this: ✊ > 👍👍 > 👍 > 🤷 = ❓ > 👎 > 👎👎
      // with ✅ breaking ties
      sortType: (rowA, rowB) => {
        let a = rowA.original.supportRating;
        let b = rowB.original.supportRating;
        if (!a) a = 3;
        if (!b) b = 3;
        if (rowA.original.isOcMember) a = -1;
        if (rowB.original.isOcMember) b = -1;
        if (!!rowA.original.memberUid) a -= 0.5;
        if (!!rowB.original.memberUid) b -= 0.5;
        if (a > b) return 1;
        if (b > a) return -1;
        return 0;
      },
    },
    {
      Header: (
        <span>
          {t("fieldName.tags")}
          <Tooltip
            contents={
              <Trans
                i18nKey="coworkers.table.tagsTooltip"
                components={[<CogIcon color="white" size="16" />]}
              />
            }
          >
            <QuestionCircleIcon className="inline-icon" />
          </Tooltip>
        </span>
      ),
      id: "tags",
      // turn the active tags into a string so filtering can work
      accessor: (row) =>
        !unitTags || !coworkerTags || !(row.id in coworkerTags)
          ? ""
          : `${unitTags
              .filter((tag) => coworkerTags[row.id].includes(tag.id))
              .map((tag) => tag.name)
              .join(" ")}`,
      Cell: ({ row }) => {
        return (
          <CoworkerTags
            coworkerId={row.original.id}
            unitTags={unitTags}
            coworkerTags={coworkerTags}
            onClickTag={(e, tag) => {
              setFilterText(tag.name);
              e.stopPropagation(); // avoid navigating to coworker detail
            }}
          />
        );
      },
    },
    {
      Header: t("fieldName.department"),
      accessor: "department",
    },
    // visually this column looks like check marks for Members
    // internally the value of memberLevel for this column is 1 for members and 2 for non-members
    // which allows sorting and filtering to both work (need to avoid falsy values like 0 and false)
    {
      Header: t("coworkers.table.memberLevel"),
      accessor: (row) => (row?.memberUid !== undefined ? 1 : 2),
      id: "memberLevel", // need an id if accessor is a function
      Cell: ({ value }) => <MemberCheck isMember={value === 1} />,
    },
    {
      Header: t("coworkers.table.lastInvited"),
      accessor: "lastInvited",
      Cell: ({ value, row }) => {
        // Hide last invited date for members, since it can be used
        // to show who created the unit and who the first members were
        if (row.values.memberLevel === 1) return "--";

        return formatShortDate(value);
      },
      sortType: (rowA, rowB, columnId) => {
        const a = rowA.original[columnId];
        const b = rowB.original[columnId];
        if (!a) return -1;
        if (!b) return 1;
        const aDate = a.toDate();
        const bDate = b.toDate();
        if (aDate > bDate) return 1;
        if (bDate > aDate) return -1;
        return 0;
      },
    },
  ];
}

// TODO may be able to remove memberLevel column now that signed is in support
// but other places depend on it so needs some rework to do so, just hide it for now
const hiddenOnSmallScreens = ["memberLevel", "department", "lastInvited"];
const hiddenOnLargeScreens = ["memberLevel"];

/**
 * A sortable, filterable, clickable table for displaying coworker info
 * @param {function} onClickCoworker called with coworker object when an entry is clicked
 **/
function CoworkersTable({ onClickCoworker, coworkers, unit }) {
  const { t } = useTranslation();
  const { breakpoint } = useViewportSize();
  const [showOnlyNonMembers, setShowOnlyNonMembers] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [unitTags, coworkerTags] = useTags({ unitId: unit?.id });

  const initialState = useMemo(() => {
    const filters = [];

    const hiddenColumns = ["xs", "sm", "md"].includes(breakpoint)
      ? hiddenOnSmallScreens
      : hiddenOnLargeScreens;

    if (showOnlyNonMembers) {
      hiddenColumns.push("memberLevel");
      filters.push({ id: "memberLevel", value: 2 });
    }
    return {
      sortBy: [{ id: "nameTitle", desc: false }],
      filters,
      hiddenColumns,
    };
  }, [showOnlyNonMembers, breakpoint]);

  const columns = useMemo(() => {
    return getColumns(t, unitTags, coworkerTags, setFilterText);
  }, [t, unitTags, coworkerTags, setFilterText]);

  const reactTable = useTable(
    {
      columns,
      data: coworkers,
      initialState,
      disableSortRemove: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  useBreakpointColumns({
    setHiddenColumns: reactTable.setHiddenColumns,
    breakpoint,
    hiddenOnLargeScreens,
    hiddenOnSmallScreens,
  });

  // Global filter
  useEffect(() => {
    reactTable.setGlobalFilter(filterText);
  }, [filterText, reactTable]);

  // Show only non-members / show all
  useEffect(() => {
    if (showOnlyNonMembers) {
      reactTable.setFilter("memberLevel", 2);
    } else {
      reactTable.setFilter("memberLevel", undefined);
    }
  }, [showOnlyNonMembers, breakpoint, reactTable]);

  return (
    <>
      <Row className="align-items-center">
        <Col xs={12} md={6}>
          <SearchInput
            className="my-3"
            placeholder={t("coworkers.table.search")}
            setFilterText={setFilterText}
            value={filterText} // in case we change the value somewhere else like clicking on a tag
          />
        </Col>
        <Col xs={12} md={6}>
          <Form.Check
            data-cy="non-members-only-checkbox"
            className="mb-3 mb-md-0" // add mb-3 below `md` screen size
            type="checkbox"
            id="only-non-members-checkbox" // this allows you to click the label in addition to the box
            label={t("coworkers.table.showOnlyNonMembers")}
            checked={showOnlyNonMembers}
            onChange={(e) => setShowOnlyNonMembers(e.target.checked)}
          />
        </Col>
      </Row>

      <UnitTable
        onClickEntry={onClickCoworker}
        filterText={filterText}
        reactTable={reactTable}
      />
    </>
  );
}

export default CoworkersTable;
