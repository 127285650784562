import UnitNameLogo from "./UnitNameLogo";
import AuthWidget from "./AuthWidget";
import PrivacyInfo from "./PrivacyInfo";
import "./Login.scss";

function Login({
  welcomeHeader,
  welcomeText,
  unitLogoText = "u",
  loginRedirectPath,
  showLogo = true,
}) {
  return (
    <div className="mx-auto">
      <div className="login-container">
        {showLogo && (
          <h1>
            <UnitNameLogo text={unitLogoText} />
          </h1>
        )}
        <h2 className="login-text my-3">{welcomeHeader}</h2>
        <h4 className="login-text-small mb-5">{welcomeText}</h4>
        <AuthWidget loginRedirectPath={loginRedirectPath} />
      </div>
      <PrivacyInfo />
    </div>
  );
}

export default Login;
