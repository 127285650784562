import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import FormFields from "./FormFields";
import OutboundLink from "./buttonsAndLinks/OutboundLink";
import LinkButton from "./buttonsAndLinks/LinkButton";
import Tooltip from "./Tooltip";
import InfoIcon from "./icons/InfoIcon";
import { Card } from "react-bootstrap";
import ContactUnitLink from "./buttonsAndLinks/ContactUnitLink";
import "./AuthCardForm.scss";
import { useTranslation, Trans } from "react-i18next";
import PrivacyDetail from "./PrivacyDetail";

function AuthCardForm({
  oc = false,
  unit,
  user,
  invite,
  setIsWrongPerson,
  onSubmit: onSubmitExternal,
}) {
  const { t } = useTranslation();
  const { handleSubmit, control, errors, formState } = useForm();

  const stateOptions = t("constants:states", { returnObjects: true }).map(
    (state) => {
      return {
        key: state.abbreviation,
        value: state.abbreviation,
        label: state.name,
      };
    }
  );

  const languageAgreedTo = t("authCard.languageAgreedTo", {
    unitName: unit.unitName,
    companyName: unit.companyName,
  });
  const notManager = (
    <>
      {t("authCard.notManager")}
      <Tooltip contents={t("authCard.managerDefinition")}>
        <InfoIcon data-cy="manager-info" className="inline-icon" />
      </Tooltip>
    </>
  );

  async function onSubmit(data) {
    // Trim whitespace from strings
    for (const key in data) {
      const value = data[key];
      if (typeof value === "string") {
        data[key] = value.trim();
      }
    }
    data.languageAgreedTo = languageAgreedTo;
    data.employerName = unit.companyName;
    onSubmitExternal(data);
  }

  const fields = [
    {
      name: "firstName",
      label: t("fieldName.firstName"),
      type: "text",
      defaultValue: invite?.invitee?.firstName,
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.firstName"),
        }),
      },
      append: invite?.invitee?.firstName ? (
        <LinkButton
          className="float-right"
          data-cy="wrong-person-link"
          onClick={() => setIsWrongPerson(true)}
        >
          <small>Not {invite?.invitee?.firstName}?</small>
        </LinkButton>
      ) : null,
    },
    {
      name: "lastName",
      label: t("fieldName.lastName"),
      type: "text",
      defaultValue: invite?.invitee?.lastName,
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.lastName"),
        }),
      },
    },
    {
      name: "email",
      label: (
        <>
          {t("fieldName.email")}
          {!!user.email ? (
            <Tooltip contents={t("authCard.emailUsed")}>
              <InfoIcon data-cy="email-info" className="inline-icon" />
            </Tooltip>
          ) : null}
        </>
      ),
      type: "email",
      disabled: !!user.email,
      defaultValue: user.email,
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.email"),
        }),
      },
    },
    {
      name: "mobilePhone",
      label: t("fieldName.mobilePhone"),
      type: "phone",
      defaultValue: user.phoneNumber ?? invite?.invitee?.mobilePhone,
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.mobilePhone"),
        }),
      },
    },
    {
      name: "jobTitle",
      label: t("fieldName.jobTitle"),
      type: "text",
      defaultValue: invite?.invitee?.jobTitle,
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.jobTitle"),
        }),
      },
    },
    {
      name: "city",
      label: t("fieldName.workSite.city"),
      type: "text",
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.workSite.city"),
        }),
      },
    },
    {
      name: "state",
      label: t("fieldName.workSite.state"),
      type: "select",
      placeholder: t("fieldName.workSite.state"),
      selectOptions: stateOptions,
      className: "w-50",
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.workSite.state"),
        }),
      },
    },
    {
      name: "isNotManager",
      checkboxLabel: notManager,
      type: "checkbox",
      defaultValue: false,
      rules: {
        required: t("authCard.notManagerError"),
      },
    },
    {
      name: "authorized",
      checkboxLabel: languageAgreedTo,
      type: "checkbox",
      defaultValue: false,
      rules: {
        required: t("authCard.languageAgreedToError"),
      },
    },
  ];

  let introContents;
  if (oc) {
    introContents = (
      <>
        <p className="text-secondary">{t("authCard.ocIntro")}</p>
        <h6>{t("authCard.introQ")}</h6>
        <p className="text-secondary">
          <Trans
            i18nKey="authCard.introA"
            components={[
              <OutboundLink
                href={`${process.env.REACT_APP_knowledgeBaseHostname}/making-it-official-union-election-certification/`}
              ></OutboundLink>,
              <OutboundLink href="https://www.nlrb.gov/about-nlrb/rights-we-protect/the-law/employees/your-right-to-form-a-union"></OutboundLink>,
            ]}
          />
        </p>
        <p className="text-secondary">{t("authCard.ocIntro2")}</p>
      </>
    );
  } else {
    introContents = (
      <>
        <p className="text-secondary">
          <Trans
            i18nKey="authCard.intro"
            components={[
              <OutboundLink
                href={`${process.env.REACT_APP_knowledgeBaseHostname}/making-it-official-union-election-certification/`}
              ></OutboundLink>,
              <OutboundLink href="https://www.nlrb.gov/about-nlrb/rights-we-protect/the-law/employees/your-right-to-form-a-union"></OutboundLink>,
            ]}
          />
        </p>
        <p className="text-secondary">{t("authCard.intro2")}</p>
      </>
    );
  }
  return (
    <div className="authcard">
      {introContents}
      <Card>
        <Card.Header className="semibold">
          {unit.unitName} {t("authCard.title")}
        </Card.Header>
        <Card.Body>
          <p>{t("authCard.text", { unitName: unit.unitName })}</p>
          <p>{t("authCard.text2", { companyName: unit.companyName })}</p>
          <FormFields fields={fields} control={control} errors={errors} />
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center">
          <Button
            data-cy="join-unit-button"
            disabled={formState.isSubmitting}
            onClick={handleSubmit(onSubmit)}
          >
            {oc ? t("authCard.submitOc") : t("authCard.submit")}
          </Button>
          <PrivacyDetail className="ml-2" contents={t("privacy.authCard")} />
        </Card.Footer>
      </Card>
      <ContactUnitLink />
    </div>
  );
}

export default AuthCardForm;

/*
A. Requirements for Acceptance of Electronic Signatures
I have determined that an acceptable submission supported by electronic signature in
support of a showing of interest must have the following elements to establish its authenticity
and provide a mechanism for the Agency to investigate allegations of forgery or fraud where
appropriate.

1. Submissions supported by electronic signature must contain the following:
a. the signer’s name;
b. the signer’s email address or other known contact information (e.g., social
media account);
c. the signer’s telephone number;
d. the language to which the signer has agreed (e.g., that the signer wishes to
be represented by ABC Union for purposes of collective bargaining or no
longer wishes to be represented by ABC Union for purposes of collective
bargaining);
e. the date the electronic signature was submitted; and,
f. the name of the employer of the employee.3
2. A party submitting either electronic or digital signatures must submit a
declaration (1) identifying what electronic or digital signature technology was
used and explaining how its controls ensure: (i) that the electronic or digital
signature is that of the signatory employee, and (ii) that the employee herself
signed the document; and (2) that the electronically transmitted information
regarding what and when the employees signed is the same information seen and
signed by the employees.
4

3 If an employee fails to name the petitioned-for employer in his/her electronic submission, then, at the time
that the showing of interest is provided to the Agency, the petitioner shall attest, in writing, that the employee
is currently employed by the petitioned-for employer.
4 For example, a party submitting a simple electronic signature could submit a declaration explaining that
electronic signatures were collected through a website set up by the organizers, and asserting that the
organizers believe that the employee herself signed the showing of interest because the employee submitted
her contact information to the website, and because the organizers sent a Confirmation Transmission as
described in Paragraph III (A)(3) below. For illustrative purposes only, see Example 1, attached to this
Memorandum. Similarly, a party submitting showings of interest collected via email could submit a
declaration explaining that the submitter knows the showing was signed because the text of the email contains
evidence that the employee acted with the intent to sign the showing of interest, and that the submitting party
believes the employee herself signed the showing of interest because (1) the email was sent from an address
known to be used by the employee and (2) because the organizers sent a Confirmation Transmission as
described in Paragraph III(A)(3) below. See Example 2.
5

3. When the electronic signature technology being used does not support digital
signatures that can be independently verified by a third party as in the example in
the second paragraph of n.4, above, the submitting party must submit evidence
that, after the electronic signature was obtained, the submitting party promptly
transmitted a communication stating and confirming all the information listed in
1a through 1f above (the “Confirmation Transmission”).
a. The Confirmation Transmission must be sent to an individual account
(i.e., email address, text message via mobile phone, social media account,
etc.) provided by the signer.5
b. If any responses to the Confirmation Transmission are received by the
time of submission to the NLRB of the showing of interest to support a
petition, those responses must also be provided to the NLRB.
4. Submissions supported by electronic signature may include other information
such as work location, classification, home address, and additional telephone
numbers, but may not contain dates of birth, social security numbers, or other
sensitive personal identifiers. Submissions with sensitive personal identifiers will
not be accepted and will be returned to the petitioner. They will not be accepted
until personal identifiers are redacted.
I recognize that the requirements set forth above are more stringent than what is currently
required for non-electronic signatures. Presently, signature lists are not required to contain any
personal contact information. However, the contact information (email address, phone number
or other social media account) is easy to obtain electronically from the signer and will enable the
NLRB to promptly investigate forgery or fraud, where appropriate.6

Moreover, the
Confirmation Transmission will allow an employee, who receives the notification but did not
actually intend to sign the document, with the means to alert the Agency, the employer, a union,
or others that he or she did not, in fact, electronically sign a showing of interest.

Additionally, a party using digital signature technology based on public key infrastructure (“PKI”) could
submit a declaration identifying this technology. Because commercially available PKI solutions allow for
identity verification by an independent third party, a submitting party can rely on PKI technology when
asserting that it knows that the electronic signature is that of the signatory employee, that the employee herself
signed the document, and that what is being submitted is the same information seen and signed by the
employee. Therefore, if these solutions are used, the Confirmation Transmission described in Paragraph III
(A)(3) need not be sent.
5 For illustrative purposes only, a sample Confirmation Transmission to support an RC and RD petition are
attached to this memorandum as Examples 3 and 4, respectively.
6 As is now the case with handwritten signatures, an electronic signature submitted in support of a showing of
interest that meets the requirements set forth herein will be presumed to be valid absent sufficient probative
evidence warranting an investigation of possible fraud. Mere speculation or assertions of fraud are not now,
and will not in the future, be sufficient to cause the Agency to investigate.
*/
