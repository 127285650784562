import EmailLink from "../../../components/buttonsAndLinks/EmailLink";
import PhoneLink from "../../../components/buttonsAndLinks/PhoneLink";
import ContactUnitLink from "../../../components/buttonsAndLinks/ContactUnitLink";
import Card from "react-bootstrap/Card";
import OutboundLink from "../../../components/buttonsAndLinks/OutboundLink";
import { useTranslation, Trans } from "react-i18next";

// similar to AdvisorCard for now
function ContactOcCard({ inviter, showExplanatoryText = false }) {
  const { t } = useTranslation();

  return (
    <Card data-cy="inviter" className="home-page-inviter">
      <Card.Body>
        <Card.Title>
          {inviter
            ? t("home.contactOc.title", {
                name: `${inviter.firstName} ${inviter.lastName}`,
              })
            : t("home.contactOc.titleAnon")}
        </Card.Title>

        {showExplanatoryText && (
          <p>
            {inviter?.firstName ? (
              <Trans
                i18nKey="home.contactOc.explanatoryText"
                values={{ firstName: inviter.firstName }}
                components={[
                  <OutboundLink
                    href={`${process.env.REACT_APP_knowledgeBaseHostname}/whats-an-organizing-committee/`}
                  ></OutboundLink>,
                ]}
              />
            ) : (
              <Trans
                i18nKey="home.contactOc.explanatoryTextAnon"
                components={[
                  <OutboundLink
                    href={`${process.env.REACT_APP_knowledgeBaseHostname}/whats-an-organizing-committee/`}
                  ></OutboundLink>,
                ]}
              />
            )}
          </p>
        )}

        {inviter?.mobilePhone ? (
          <>
            <dt>{t("fieldName.mobilePhone")}:</dt>
            <dd>
              <PhoneLink
                data-cy="inviter-phone"
                phoneNumber={inviter.mobilePhone}
              />
            </dd>
          </>
        ) : (
          <>
            <dt>{t("home.contactOc.chat")}:</dt>
            <dd>
              <ContactUnitLink inline>Click to chat</ContactUnitLink>
            </dd>
          </>
        )}

        <dt>{t("fieldName.email")}:</dt>
        <dd>
          <EmailLink
            data-cy="inviter-email"
            emails={inviter?.email ? [inviter.email] : ["info@unitworkers.com"]}
          />
        </dd>
      </Card.Body>
    </Card>
  );
}

export default ContactOcCard;
