import useCoworker from "../../hooks/useCoworker";
import {
  saveTagsOnUnit,
  deleteTagFromUnitAndCoworkers,
} from "../../utilities/firestore";
import { useContext, useState } from "react";
import { UserUnitContext } from "../../contexts/userUnitContext";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import { ModalContext } from "../../contexts/modalContext";
import { useTranslation } from "react-i18next";
import Modal from "../Modal";
import Spinner from "../../components/Spinner";
import TagSelect from "./TagSelect";
import EditTagsForUnitBody from "./EditTagsForUnitBody";
import useTags from "../../hooks/useTags";

function TagsModal({ coworkerId, ...props }) {
  const { t } = useTranslation();
  const { unit, user } = useContext(UserUnitContext);
  const { setShowModal } = useContext(ModalContext);
  const [coworker] = useCoworker({
    coworkerId,
    unitId: unit?.id,
  });
  const { handleSubmit, control, errors } = useForm();
  const [showEditTags, setShowEditTags] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [unitTags, coworkerTags] = useTags({ unitId: unit?.id });

  // remove the tag from the unit and from all coworkers
  async function deleteTag({ tagId }) {
    setShowSpinner(true);
    await deleteTagFromUnitAndCoworkers({
      tagId,
      unitTags,
      coworkerTags,
      unitId: unit.id,
      updatedByUid: user.uid,
    });
    setShowSpinner(false);
  }

  // used for both create or edit
  // create - just add new tag to list
  // edit - same as existing tags except use the new data for the passed in tag
  async function editTag({ tag }) {
    setShowSpinner(true);
    const isEdit = unitTags.some((t) => t.id === tag.id);
    const newTags = isEdit
      ? unitTags.map((t) => (t.id === tag.id ? tag : t))
      : unitTags.concat(tag);
    await saveTagsOnUnit({
      newTags,
      unitId: unit.id,
      updatedByUid: user.uid,
    });
    setShowSpinner(false);
  }

  async function close() {
    setShowEditTags(false);
    setShowModal(false);
  }

  // regardless of how the modal is closed, reset the state
  function handleExited() {
    setShowEditTags(false);
  }

  return (
    <Modal
      showCancel={false}
      onExited={handleExited}
      className="tags-modal"
      data-cy="tags-modal"
      title={
        showEditTags
          ? t("tags.editTagsForUnitTitle")
          : coworker?.firstName
          ? t("tags.editCoworkerTitle", { firstName: coworker.firstName })
          : t("tags.editCoworkerTitleAnon")
      }
      body={
        // loading is bugged so look at coworker and unit directly
        !coworker || !unit || !unitTags || showSpinner ? (
          <Spinner />
        ) : showEditTags ? (
          <EditTagsForUnitBody
            control={control}
            errors={errors}
            handleSubmit={handleSubmit}
            coworker={coworker}
            deleteTag={deleteTag}
            editTag={editTag}
            unitTags={unitTags}
          />
        ) : (
          <TagSelect
            setShowEditTags={setShowEditTags}
            coworker={coworker}
            unit={unit}
            user={user}
            unitTags={unitTags}
            coworkerTags={coworkerTags}
            setShowSpinner={setShowSpinner}
          />
        )
      }
      footerActions={ModalFooter({
        close,
        t,
      })}
      {...props}
    />
  );
}

// all tag related actions are saved as you do them so all that's left is to close the modal
function ModalFooter({ close, t }) {
  return (
    <Button data-cy="close-modal" variant="secondary" onClick={close}>
      {t("tags.close")}
    </Button>
  );
}

export default TagsModal;
