import { useEffect, useState } from "react";
import {
  hideIntercomLauncher,
  showIntercomLauncher,
} from "../utilities/intercom";

function useModalState(initialShow = false) {
  const [show, setShow] = useState(initialShow);

  useEffect(() => {
    if (show) {
      hideIntercomLauncher();
    } else {
      showIntercomLauncher();
    }
  }, [show]);

  return [show, setShow];
}

export default useModalState;
