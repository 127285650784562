import { useLocation, Redirect } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import { useContext } from "react";
import useStateWithLocalStorage from "../../hooks/useStateWithLocalStorage";
import { UserUnitContext } from "../../contexts/userUnitContext";
import { useTranslation } from "react-i18next";
import Login from "../../components/Login";

function LoginPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useContext(UserUnitContext);

  // There might be a previous loginRedirectPath in local storage
  // if we have a new one, override whatever is there
  if (location?.state?.from?.pathname) {
    localStorage.setItem(
      "loginRedirectPath",
      JSON.stringify(location?.state?.from?.pathname)
    );
  }
  const [loginRedirectPath] = useStateWithLocalStorage("loginRedirectPath");

  if (user) {
    return <Redirect to="/" />;
  } else {
    return (
      <PageLayout
        pageTitle={t("pageName.login")}
        showNav={false}
        showLoginOut={false}
        className="d-flex flex-column"
      >
        <Login
          welcomeHeader={t("account.loginWelcomeHeader")}
          welcomeText={t("account.loginWelcomeText")}
          loginRedirectPath={loginRedirectPath}
        />
      </PageLayout>
    );
  }
}

export default LoginPage;
