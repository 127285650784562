import LinkButton from "./LinkButton";
import { logOut } from "../../utilities/auth";
import { useTranslation } from "react-i18next";

function LogoutLink({ text, className, ...props }) {
  const { t } = useTranslation();

  return (
    <div className={className} {...props}>
      <small className="text-secondary">
        {text}{" "}
        <LinkButton data-cy="logout-link" onClick={logOut}>
          {t("account.logout")}.
        </LinkButton>
      </small>
    </div>
  );
}

export default LogoutLink;
