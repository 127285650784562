import { createContext, useState } from "react";

const ToastContext = createContext(null);

function ToastContextProvider({ children }) {
  const [showToast, setShowToast] = useState(false);
  const [toastProps, setToastProps] = useState({
    header: null,
    body: null,
  });

  return (
    <ToastContext.Provider
      value={{
        showToast,
        setShowToast,
        toastProps,
        setToastProps,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
}

export { ToastContext, ToastContextProvider };
