import AuthCardForm from "../../../components/AuthCardForm";
import firebase from "firebase/compat/app";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function AuthCardCreate({ unit, user, onSubmit }) {
  const { t } = useTranslation();

  useEffect(() => {
    firebase.analytics().logEvent("join_unit_as_first");
    // When arriving here after creating a unit the user will be partway
    // down the page. Need to make sure we start at the top of the form.
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <h5>{t("createUnit.authCardIntro")}</h5>
      <AuthCardForm unit={unit} user={user} onSubmit={onSubmit} />
    </>
  );
}

export default AuthCardCreate;
