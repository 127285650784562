import Tooltip from "./Tooltip";
import { useTranslation } from "react-i18next";

function OcMemberCheck({ isOcMember = false }) {
  const { t } = useTranslation();

  return isOcMember ? <Tooltip contents={t("ocMember")}>✊</Tooltip> : null;
}

export default OcMemberCheck;
