import LinkButton from "./buttonsAndLinks/LinkButton";
import { useContext } from "react";
import { ModalContext } from "../contexts/modalContext";
import { useTranslation } from "react-i18next";
import OcOnlyTitle from "./OcOnlyTitle";
import CoworkerTags from "./CoworkerTags";
import { wizardAbortAt } from "../constants/inviteWizard";
import Button from "react-bootstrap/Button";
import { supportRatings } from "../constants/supportRatings";

function CoworkerOcInfo({
  coworker,
  coworkerOcOnly,
  canEdit,
  isMember,
  isInvited,
  unitTags,
  coworkerTags,
}) {
  const { t } = useTranslation();
  const { setModal, setShowModal } = useContext(ModalContext);

  const ClickToAdd = canEdit ? (
    <LinkButton
      variant="secondary"
      onClick={() => {
        setModal({
          name: "edit",
          props: { coworkerId: coworker?.id, showOcInfoFields: true },
        });
        setShowModal(true);
      }}
    >
      {t("coworkerDisplay.clickToAdd")}
    </LinkButton>
  ) : (
    <span className="text-secondary">{t("unknown")}</span>
  );

  const memberStatusText = isMember
    ? t("isMember")
    : isInvited
    ? t("isInvited")
    : t("notYetInvited");

  return (
    <>
      <OcOnlyTitle className="mt-3" />
      {canEdit && (
        <Button
          className="m-2"
          onClick={() => {
            setModal({
              name: "edit",
              props: {
                coworkerId: coworker.id,
                showOcInfoFields: true,
                isMember,
              },
            });
            setShowModal(true);
          }}
          variant="outline-primary"
          data-cy="edit-oc-info-button"
        >
          {t("coworkerDetail.edit")}
        </Button>
      )}
      <dl className="m-2">
        <dt>{t("coworkerDisplay.support")}</dt>
        <dd data-cy="coworker-support">
          {coworker?.supportRating
            ? `${supportRatings[coworker.supportRating].emoji} ${t(
                supportRatings[coworker.supportRating].textKey
              )}`
            : ClickToAdd}
        </dd>
        <dt>{t("coworkerDisplay.memberStatus")}</dt>
        <dd data-cy="membership-status">{memberStatusText}</dd>
        <dt>{t("coworkerDisplay.tags")}</dt>
        <dd>
          <CoworkerTags
            coworkerId={coworker.id}
            unitTags={unitTags}
            coworkerTags={coworkerTags}
          />
        </dd>
        <dt>{t("coworkerDisplay.note")}</dt>
        <dd data-cy="coworker-note">{coworkerOcOnly?.note || ClickToAdd}</dd>

        {/* hide invite wizard fields for memembers */}
        {!isMember && (
          <>
            <dt>{t("inviteWizard.reason.title")}</dt>
            <dd data-cy="coworker-reason">
              {coworkerOcOnly?.reason || ClickToAdd}
            </dd>
            <dt>{t("inviteWizard.understand.title")}</dt>
            <dd data-cy="coworker-understand">
              {coworkerOcOnly?.understand
                ? t(
                    `inviteWizard.understand.${
                      coworkerOcOnly.understand < wizardAbortAt.understand
                        ? "yes"
                        : "no"
                    }`
                  )
                : ClickToAdd}
            </dd>
            <dt>{t("inviteWizard.warned.title")}</dt>
            <dd data-cy="coworker-warned">
              {coworkerOcOnly?.warned
                ? t(
                    `inviteWizard.warned.${
                      coworkerOcOnly.warned < wizardAbortAt.warned
                        ? "yes"
                        : "no"
                    }`
                  )
                : ClickToAdd}
            </dd>
          </>
        )}
      </dl>
    </>
  );
}

export default CoworkerOcInfo;
