import { Bar } from "react-chartjs-2";
import { supportRatings } from "../constants/supportRatings";
import colors from "../styles/colors.module.scss";
import { useTranslation } from "react-i18next";

function SupportBarChart({ coworkers, numNonManagerEmployees }) {
  const { t } = useTranslation();

  let memberCount = 0;
  // We expect numNonManagerEmployees >= coworkers.length, but if not we'll use 0
  const notYetAddedCount = Math.max(
    numNonManagerEmployees - coworkers.length,
    0
  );
  const supportCounts = [0, 0, 0, 0, 0, 0]; // index 0 is unrecorded support

  coworkers.forEach((coworker) => {
    if (coworker.memberUid) {
      memberCount += 1;
      return;
    }
    if (coworker.supportRating) {
      supportCounts[coworker.supportRating] += 1;
    } else {
      supportCounts[0] += 1;
    }
  });

  const labels = [
    `${t("likelyYes")} (${memberCount + supportCounts[1] + supportCounts[2]})`,
    `${t("likelyNo")} (${supportCounts[4] + supportCounts[5]})`,
    `${t("unknownSupport")} (${
      supportCounts[0] + supportCounts[3] + notYetAddedCount
    })`,
  ];

  const options = {
    indexAxis: "y", // horizontal bar
    responsive: true,
    scales: {
      x: {
        stacked: true,
        ticks: {
          precision: 0, // Integer ticks only, no decimals
        },
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        // Only show the items with nonzero values within the
        // bar group the user is hovering on
        filter: (tooltipItem) => {
          return tooltipItem.dataset.data[tooltipItem.dataIndex] > 0;
        },
      },
    },
  };

  // Have to make each datapoint here its own dataset because there can be
  // only one label per dataset. The zeros in the arrays are padding, data
  // is structured [likelyYesCount, likelyNoCount, UnknownCount]
  function supportRatingDataset(rating, barGroupingIndex) {
    const data = [0, 0, 0];
    data[barGroupingIndex] = supportCounts[rating];
    const ratingDetail = supportRatings[rating];
    return {
      data,
      label: `${t(ratingDetail.textKey)} ${ratingDetail.emoji}`,
      backgroundColor: [ratingDetail.color],
    };
  }

  const datasets = [
    // Likely yeses
    {
      data: [memberCount, 0, 0],
      label: t("members"),
      backgroundColor: colors.darkGreen,
    },
    supportRatingDataset(1, 0),
    supportRatingDataset(2, 0),

    // Likely nos
    supportRatingDataset(5, 1),
    supportRatingDataset(4, 1),

    // Maybe / unknown
    supportRatingDataset(3, 2),
    supportRatingDataset(0, 2),
    {
      data: [0, 0, notYetAddedCount],
      label: t("notOnList"),
      backgroundColor: [colors.grey],
    },
  ];

  return <Bar options={options} data={{ labels, datasets }} />;
}

export default SupportBarChart;
