import { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import Spinner from "../../components/Spinner";
import { UserUnitContext } from "../../contexts/userUnitContext";
import useStateWithLocalStorage from "../../hooks/useStateWithLocalStorage";
import useUnitByCreatedByUid from "../../hooks/useUnitByCreatedByUid";
import { joinUnitAsFirstMember } from "../../utilities/firestore";
import AuthCardCreate from "./components/AuthCardCreate";
import CreateLayout from "./components/CreateLayout";
import NewUnionSetup from "./components/NewUnionSetup";
import UnitCreationSuccess from "./components/UnitCreationSuccess";
import "./CreateUnitPage.scss";
import firebase from "firebase/compat/app";
import { sendEmailVerificationIfNeeded } from "../../utilities/auth";
import { useTranslation } from "react-i18next";

/* This page is intended as a completion step for the /getstarted flow
 * The /getstarted flow puts user input from the eligibility check into local storage
 * which will be used if available to prepopulate fields. However, the main thing
 * happening on this page is a firestore query to determine if this user has created
 * a unit. If they have, they can join as the first member. If they haven't, they can
 * create an empty unit.
 *
 * If the user got here by accident we send them to /ochome or /memberhome
 */

function CreateUnitPage() {
  const { t } = useTranslation();
  // unit will be populated after unit creation is finished
  const { user, unit, loading: userUnitLoading } = useContext(UserUnitContext);
  const [creatingUnit, setCreatingUnit] = useState(false);
  const [unitCreatedByMe, unitCreatedByMeLoading] = useUnitByCreatedByUid({
    createdByUid: user?.uid,
  });
  const [localStorageUnitInfo] = useStateWithLocalStorage("getStartedUnitInfo");

  async function onSubmitAuthCard(data) {
    setCreatingUnit(true);
    await joinUnitAsFirstMember({
      uid: user.uid,
      unitId: unitCreatedByMe.id,
      coworkerData: {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        mobilePhone: data.mobilePhone,
        jobTitle: data.jobTitle,
        city: data.city,
        state: data.state,
      },
      authCardData: data,
    });
    sendEmailVerificationIfNeeded();
    firebase.analytics().logEvent("joined_unit_as_first");
    setCreatingUnit(false);
  }

  return (
    <PageLayout pageTitle={t("pageName.createUnit")} showNav={false}>
      <CreateLayout unit={unitCreatedByMe}>
        <CreateUnitPageContents
          localStorageUnitInfo={localStorageUnitInfo}
          userUnitLoading={userUnitLoading}
          unit={unit}
          unitCreatedByMe={unitCreatedByMe}
          unitCreatedByMeLoading={unitCreatedByMeLoading}
          user={user}
          creatingUnit={creatingUnit}
          onSubmitAuthCard={onSubmitAuthCard}
        />
      </CreateLayout>
    </PageLayout>
  );
}

function CreateUnitPageContents({
  localStorageUnitInfo,
  userUnitLoading,
  unit,
  user,
  unitCreatedByMe,
  unitCreatedByMeLoading,
  onSubmitAuthCard,
  creatingUnit,
}) {
  if (userUnitLoading || unitCreatedByMeLoading || creatingUnit) {
    return <Spinner />;
  }

  // The user is already in a unit.
  if (unit) {
    // If they created it, congratulate them!
    if (unitCreatedByMe) {
      return <UnitCreationSuccess unit={unit} />;
      // otherwise they shouldn't be here, send them to /ochome or /memberhome
    } else {
      return <Redirect to={user?.isOcMember ? "/ochome" : "/memberhome"} />;
    }
  }

  // Either they've already made the new unit and need to join it
  if (unitCreatedByMe) {
    return (
      <AuthCardCreate
        unit={unitCreatedByMe}
        user={user}
        onSubmit={onSubmitAuthCard}
      />
    );
  } else {
    // or they need to create the unit
    return <NewUnionSetup localStorageUnitInfo={localStorageUnitInfo} />;
  }
}

export default CreateUnitPage;
