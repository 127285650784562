import { useContext } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useHistory } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import CoworkersTable from "./components/CoworkersTable";
import SupportBarChart from "../../components/SupportBarChart";
import { UserUnitContext } from "../../contexts/userUnitContext";
import { ModalContext } from "../../contexts/modalContext";
import Error from "../../components/Error";
import Spinner from "../../components/Spinner";
import useCoworkers from "../../hooks/useCoworkers";
import { useTranslation } from "react-i18next";
import InviteButton from "../../components/buttonsAndLinks/InviteButton";
import PlusCircleIcon from "../../components/icons/PlusCircleIcon";
import "./CoworkersPage.scss";
import PrivacyDetail from "../../components/PrivacyDetail";
import CoworkersDownload from "./components/CoworkersDownload";

function CoworkersPage() {
  const { t } = useTranslation();

  return (
    <PageLayout
      pageTitle={t("pageName.coworkers")}
      data-cy="coworkers-page"
      requireVerifiedEmail
    >
      <CoworkersPageContents />
    </PageLayout>
  );
}

function CoworkersPageContents() {
  const { t } = useTranslation();

  const { unit, loading: userUnitLoading } = useContext(UserUnitContext);
  const [coworkers, loading, error] = useCoworkers({ unitId: unit?.id });
  const history = useHistory();
  const { setModal, setShowModal } = useContext(ModalContext);

  if (loading || userUnitLoading) return <Spinner />;

  if (error) return <Error error={error} />;

  if (!coworkers || coworkers.length === 0) {
    return (
      <Error
        error={{ name: "NoCoworkersError" }}
        displayText={t("coworkers.noCoworkersError")}
      />
    );
  }

  return (
    <>
      <Row className="align-items-center">
        <Col xs={12} md={6}>
          <h3>{t("pageName.coworkers")}</h3>
          <h5
            className={`text-secondary${
              !coworkers?.length || !unit.numNonManagerEmployees
                ? " d-none"
                : ""
            }`}
          >
            {t("coworkers.counts", {
              addedCount: coworkers.length,
              expectedCount: unit.numNonManagerEmployees,
            })}
          </h5>
          <PrivacyDetail
            className="mb-3"
            contents={t("privacy.coworkers")}
            placement="bottom"
            triggerText={t("privacy.whoCanSee.coworkers")}
          />
        </Col>
        <Col
          xs={12}
          md={6}
          className="d-flex justify-content-end align-items-center"
        >
          <Button
            data-cy="add-coworker-button"
            className="add-coworker-button"
            onClick={() => {
              setModal({
                name: "add",
                props: {},
              });
              setShowModal(true);
            }}
          >
            <PlusCircleIcon className="inline-icon button-plus-icon mr-1" />{" "}
            {t("coworkers.add")}
          </Button>

          <InviteButton showChooseCoworker />
        </Col>
      </Row>

      <Row className="my-3">
        <Col xs={0} md={2} />
        <Col xs={12} md={6}>
          <SupportBarChart
            coworkers={coworkers}
            numNonManagerEmployees={unit.numNonManagerEmployees}
          />
        </Col>
      </Row>

      <CoworkersTable
        onClickCoworker={(coworker) => {
          if (coworker.id)
            history.push("/coworker/" + coworker.id, {
              from: "/coworkers",
            });
        }}
        coworkers={coworkers}
        unit={unit}
      />

      <CoworkersDownload coworkers={coworkers} />
    </>
  );
}

export default CoworkersPage;
