import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserUnitContext } from "../contexts/userUnitContext";

// returns "(you)" if the coworker memberUid matches the logged in user, otherwise null
function YouCheck({ memberUid }) {
  const { t } = useTranslation();
  const { user } = useContext(UserUnitContext);

  let isYou = false;
  if (memberUid) {
    isYou =
      user.uid === memberUid ||
      (user.uid.includes("controlled by") && user.uid.startsWith(memberUid));
  }
  return isYou ? `(${t("coworkerIsYou")})` : null;
}

export default YouCheck;
