import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CSVLink } from "react-csv";
import useAuthCards from "../../../hooks/useAuthCards";
import { formatFullDateTime, getTimeZone } from "../../../utilities/dateTime";
import { formatPhoneNumber } from "react-phone-number-input";

const AuthCardDownload = ({ unit }) => {
  const [cards, loading] = useAuthCards({ unitId: unit?.id });

  const timeZone = getTimeZone();

  return (
    <Card data-cy="auth-card-download">
      <Card.Body>
        <Card.Title>Download Auth Cards</Card.Title>
        <Card.Text>
          <dt>Unit</dt>
          <dd>
            {unit?.unitName} - {unit?.id}
          </dd>
          <dt>Card Count</dt>
          <dd data-cy="auth-card-count">{cards.length}</dd>
        </Card.Text>

        {loading || !cards ? (
          <Button disabled>Download</Button>
        ) : (
          <CSVLink
            data={cards.map((card) => ({
              ...card,
              mobilePhone: formatPhoneNumber(card.mobilePhone),
              created: formatFullDateTime(card.created),
              updated: formatFullDateTime(card.updated),
              timeZone,
            }))}
            headers={[
              { label: "First Name", key: "firstName" },
              { label: "Last Name", key: "lastName" },
              { label: "Job Title", key: "jobTitle" },
              { label: "Mobile Phone", key: "mobilePhone" },
              { label: "Email", key: "email" },
              { label: "Employer Name", key: "employerName" },
              { label: "Language Agreed To", key: "languageAgreedTo" },
              { label: "Created Date", key: "created" },
              { label: "Updated Date", key: "updated" },
              { label: "Time Zone", key: "timeZone" },
            ]}
            filename={`authCards_${unit?.unitName}.csv`}
            className="btn btn-primary text-light"
          >
            Download
          </CSVLink>
        )}
      </Card.Body>
    </Card>
  );
};

export default AuthCardDownload;
