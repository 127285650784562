/**
 * Takes a Firestore timestamp and returns it formatted as M/D
 * or an empty string if passed a falsy value
 * @param {firestore.Timestamp} fsTimestamp
 * @returns {string}
 **/
function formatShortDate(fsTimestamp) {
  if (!fsTimestamp) return "";

  const d = fsTimestamp.toDate(),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate();

  return [month, day].join("/");
}

/**
 * Takes a Firestore timestamp and returns it formatted like
 * 2021-05-26 3:47:57 PM
 * because that is the only format Excel / google sheets will interpret automatically as datetime
 * https://stackoverflow.com/questions/804118/best-timestamp-format-for-csv-excel
 * @param {firestore.Timestamp} fsTimestamp
 * @returns {string}
 **/
function formatFullDateTime(fsTimestamp) {
  if (!fsTimestamp) return "";

  const date = fsTimestamp.toDate();
  const year = date.getFullYear();
  const month = (1 + date.getMonth()).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day} ${date.toLocaleTimeString()}`;
}

// Take a string like '08:46:27 GMT-0400 (Eastern Daylight Time)' and strip away the time
function getTimeZone() {
  return new Date().toTimeString().substring(9);
}

export { formatShortDate, formatFullDateTime, getTimeZone };
