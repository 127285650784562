import React from "react";
import { Trans } from "react-i18next";
import OutboundLink from "./buttonsAndLinks/OutboundLink";
import ContactUnitLink from "./buttonsAndLinks/ContactUnitLink";
import { Link } from "react-router-dom";

// Uses <Trans> component and deals with urls
// - uses `<Link` for internal "/links"
// - uses regular `<a href` for mailto
// - uses `<OutboundLink for links to the guide and for external links
//
// Expects the translation file to have the <0>same</0> number of <1>numbered</1>
// sections as the length of `urls`.
//
// Can specify url via: urls, url, guide or guideUrls
// START-INTERCOM-CHAT is magic string for rendering intercom link
function TransLinks({ i18nKey, values, urls, url, guide, guideUrls }) {
  if (url && !urls) {
    urls = [url];
  }
  if (guide && !urls) {
    urls = [`${process.env.REACT_APP_knowledgeBaseHostname}${guide}`];
  }
  if (guideUrls && !urls) {
    urls = guideUrls.map(
      (x) => `${process.env.REACT_APP_knowledgeBaseHostname}${x}`
    );
  }
  return (
    <Trans
      i18nKey={i18nKey}
      values={values}
      components={urls?.map((url) => {
        if (url.includes("START-INTERCOM-CHAT")) {
          return <ContactUnitLink inline />;
        } else if (url.startsWith("/")) {
          // link body will get replaced by i18n
          return <Link to={url}>link</Link>;
        } else if (url.startsWith("mailto:")) {
          return <a href={url}>link</a>;
        } else {
          return <OutboundLink href={url} />;
        }
      })}
    />
  );
}

export default TransLinks;
