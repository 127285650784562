import { createContext, useState } from "react";
import useModalState from "../hooks/useModalState";

const ModalContext = createContext(null);

function ModalContextProvider({ children }) {
  const [showModal, setShowModal] = useModalState(false);
  const [modal, setModal] = useState({
    name: "",
    props: {},
  });

  return (
    <ModalContext.Provider
      value={{
        modal,
        setModal,
        showModal,
        setShowModal,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export { ModalContext, ModalContextProvider };
