import { createContext, useEffect, useState } from "react";
import useAuthUser from "../hooks/useAuthUser";
import useMembership from "../hooks/useMembership";
import useUnit from "../hooks/useUnit";
import useCoworker from "../hooks/useCoworker";
import useStateWithLocalStorage from "../hooks/useStateWithLocalStorage";

const UserUnitContext = createContext(null);

const UserUnitContextProvider = ({ children, ...props }) => {
  const [unitId, setUnitId] = useState(null);
  const [uid, setUid] = useState(null);
  const [adminUnitId, setAdminUnitId] = useStateWithLocalStorage("adminUnitId");
  const [adminActAsUid, setAdminActAsUid] =
    useStateWithLocalStorage("adminActAsUid");
  const [user, userLoading, userError] = useAuthUser();
  // Membership is loading if the user is loading
  const [membership, membershipLoading, membershipError] = useMembership(
    uid,
    userLoading
  );
  // Unit is loading if the membership is loading
  const [unit, unitLoading, unitError] = useUnit(unitId, membershipLoading);
  // The user's coworker document will be loading if we don't have
  // a user uid or a unitId (either because they are loading or because they don't exist)
  const [userCoworker, userCoworkerLoading] = useCoworker({
    unitId, // request the coworker in paralell with the unit doc once the membership loads
    coworkerId: membership?.coworkerId,
  });

  useEffect(() => {
    setUnitId(user?.isAdmin && adminUnitId ? adminUnitId : membership?.unitId);
  }, [user?.isAdmin, adminUnitId, membership?.unitId]);

  // An admin assuming a uid will lose their isAdmin status and uid as far as the app is concerned
  // however they will still have isOgAdmin in the app to switch back
  // and as far as the firestore rules are concerned they are an admin with their original uid
  useEffect(() => {
    setUid(user?.isOgAdmin && adminActAsUid ? adminActAsUid : user?.uid);
    if (user?.isAdmin && adminActAsUid) {
      // be explicit about "act as" so we can tell from audits and updatedByUid etc
      user.uid = `${adminActAsUid} controlled by ${user.uid}`;
      user.isAdmin = false;
    }
  }, [user?.isAdmin, adminActAsUid, user]);

  return (
    <UserUnitContext.Provider
      {...props}
      value={{
        user: user
          ? {
              ...user,
              ...userCoworker,
              isOcMember: membership?.isOcMember,
              ocContactCoworkerId: membership?.ocContactCoworkerId,
            }
          : null,
        unit,
        // unitLoading accounts for user and membership loading
        // userCoworker loading state only matters if we have a user and unit
        loading: unitLoading || (user && unit && userCoworkerLoading),
        error: userError || membershipError || unitError,
        setAdminUnitId,
        setAdminActAsUid,
      }}
    >
      {children}
    </UserUnitContext.Provider>
  );
};

export { UserUnitContext, UserUnitContextProvider };
