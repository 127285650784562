import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import BsTooltip from "react-bootstrap/Tooltip";

// Tooltip with a trigger
// Can wrap any child (text, icon, button, etc)

const Tooltip = ({
  show = true,
  contents,
  children,
  trigger = ["click", "hover", "focus"],
  placement = "top", // Top is default bootstrap behavior anyway
  ...props
}) => {
  if (!show) {
    return children;
  }

  const Tip = <BsTooltip data-cy="tooltip">{contents}</BsTooltip>;

  return (
    <OverlayTrigger
      rootClose
      overlay={Tip}
      placement={placement}
      trigger={trigger}
      {...props}
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
};

export default Tooltip;
