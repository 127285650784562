import colors from "../../styles/colors.module.scss";

function GlobeChatIcon({ color, size = "24", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      style={{ color: color ? colors[color] : "currentColor" }}
      {...props}
    >
      <g transform="matrix(1,0,0,1,0,0)">
        <g>
          <path
            d="M9.29,22.92C7.77,20.69,6.75,16.63,6.75,12s1-8.69,2.54-10.92"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
            stroke="currentColor"
          ></path>
          <path
            d="M14.71,22.92c1.52-2.23,2.54-6.29,2.54-10.92s-1-8.69-2.54-10.92"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
          ></path>
          <line
            x1="0.75"
            y1="12"
            x2="23.25"
            y2="12"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
          ></line>
          <line
            x1="2.05"
            y1="6.75"
            x2="21.95"
            y2="6.75"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
          ></line>
          <line
            x1="2.05"
            y1="17.25"
            x2="21.95"
            y2="17.25"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
          ></line>
          <path
            d="M23.25,12A11.18,11.18,0,0,1,21,18.74l2.24,4.51-6-1.3a11.25,11.25,0,1,1,6-9.95Z"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5px"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default GlobeChatIcon;
