import { useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserUnitContext } from "../../contexts/userUnitContext";
import PageLayout from "../../components/PageLayout";
import CoworkerDisplay from "../../components/CoworkerDisplay";
import { ModalContext } from "../../contexts/modalContext";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CoworkerBreadcrumb from "./components/CoworkerBreadcrumb";
import CoworkerDetailButtons from "./components/CoworkerDetailButtons";
import useCoworker from "../../hooks/useCoworker";
import useCoworkerOcOnly from "../../hooks/useCoworkerOcOnly";
import { Redirect } from "react-router-dom";
import Spinner from "../../components/Spinner";
import NameAndTitle from "../../components/NameAndTitle";
import CoworkerHistory from "./components/CoworkerHistory";
import useCoworkerInvites from "../../hooks/useCoworkerInvites";
import { useTranslation } from "react-i18next";
import useTags from "../../hooks/useTags";
import CoworkerOcInfo from "../../components/CoworkerOcInfo";

function CoworkerDetailPage({ initModal }) {
  const { t } = useTranslation();

  const { coworkerId } = useParams();
  const { unit, user } = useContext(UserUnitContext);
  const { setModal, setShowModal } = useContext(ModalContext);
  const [coworker, coworkerLoading, coworkerError] = useCoworker({
    coworkerId,
    unitId: unit?.id,
  });
  const [coworkerOcOnly, coworkerOcOnlyLoading] = useCoworkerOcOnly({
    coworkerId,
    unitId: unit?.id,
  });
  const [unitTags, coworkerTags] = useTags({ unitId: unit?.id });
  const [invites, invitesLoading, invitesError] = useCoworkerInvites({
    coworkerId,
    unitId: unit?.id,
  });

  useEffect(() => {
    if (initModal) {
      setModal({ name: initModal, props: { coworkerId } });
      setShowModal(true);
    }
  }, [initModal, coworkerId, setModal, setShowModal]);

  // This coworkerId does not correspond to a coworker document
  // or the coworker has been soft deleted
  if (coworkerError?.name === "NonexistentDocError" || coworker?.isDeleted) {
    return <Redirect to="/coworkers" />;
  }

  if (coworkerLoading || invitesLoading || coworkerOcOnlyLoading) {
    return (
      <PageLayout pageTitle={t("pageName.coworkerDetail")}>
        <Spinner />
      </PageLayout>
    );
  }

  const isMember = !!coworker?.memberUid;
  const isSelf = coworker?.memberUid === user?.uid;
  const canEdit = !isMember || isSelf || user?.isAdmin;
  const canDelete = !isMember || user?.isAdmin;
  const canRescind = isMember && user?.isAdmin;
  const canUpdateOcMembership = user?.isAdmin && isMember;

  return (
    <PageLayout pageTitle={t("pageName.coworkerDetail")} requireVerifiedEmail>
      <Container className="coworker-detail">
        <CoworkerBreadcrumb coworker={coworker} />
        <Row>
          <Col xs={12} md={6}>
            <NameAndTitle coworker={coworker} className="h4" />
          </Col>
          <Col xs={12} md={6}>
            <CoworkerDetailButtons
              showEdit={canEdit}
              showDelete={canDelete}
              showRescindCard={canRescind}
              showInvite={!isMember}
              showUpdateOcMembership={canUpdateOcMembership}
              coworker={coworker}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={6}>
            <CoworkerDisplay coworker={coworker} canEdit={canEdit} />
            {!isMember && !invitesError ? (
              <CoworkerHistory
                coworker={coworker}
                user={user}
                invites={invites}
              />
            ) : null}
          </Col>
          <Col xs={12} md={6}>
            <CoworkerOcInfo
              coworker={coworker}
              canEdit={canEdit}
              isMember={isMember}
              isInvited={!!invites.length}
              showOcOnlyFields={true}
              coworkerOcOnly={coworkerOcOnly}
              unitTags={unitTags}
              coworkerTags={coworkerTags}
            />
          </Col>
        </Row>
      </Container>
    </PageLayout>
  );
}

export default CoworkerDetailPage;
