import LinkButton from "./buttonsAndLinks/LinkButton";
import LockIcon from "./icons/LockIcon";
import Tooltip from "./Tooltip";
import { useTranslation } from "react-i18next";

function PrivacyDetail({
  triggerText,
  contents,
  className,
  placement,
  ...props
}) {
  const { t } = useTranslation();
  if (!triggerText) {
    triggerText = t("privacy.whoCanSee.generic");
  }

  return (
    <div className={`privacy-detail ${className || ""}`.trim()} {...props}>
      <Tooltip contents={contents} placement={placement} trigger="click">
        <LinkButton>
          <LockIcon className="inline-icon" />
          {triggerText}
        </LinkButton>
      </Tooltip>
    </div>
  );
}

export default PrivacyDetail;
