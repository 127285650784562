import useFirestoreOnce from "./useFirestoreOnce";
import { useState } from "react";

function useCoworkersOnce({ unitId }) {
  const [queries] = useState([["isDeleted", "==", false]]);
  const [data, loading, error] = useFirestoreOnce(
    `units/${unitId}/coworkers`,
    queries,
    !unitId
  );

  return [data, loading, error];
}

export default useCoworkersOnce;
