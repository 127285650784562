import CoworkerDisplay from "../../../components/CoworkerDisplay";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import NameAndTitle from "../../../components/NameAndTitle";
import { ModalContext } from "../../../contexts/modalContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserUnitContext } from "../../../contexts/userUnitContext";
import useCoworkerInvites from "../../../hooks/useCoworkerInvites";

function MemberProfile() {
  const { user, unit } = useContext(UserUnitContext);

  const { t } = useTranslation();
  const { setModal, setShowModal } = useContext(ModalContext);
  const [invites] = useCoworkerInvites({
    coworkerId: user?.id,
    unitId: unit?.id,
  });

  const isMember = !!user?.memberUid;

  return (
    <>
      <Card className="mt-3" data-cy="member-profile-card">
        <Card.Header className="d-flex align-items-center">
          <span>{t("account.profileTitle")}</span>
          <Button
            data-cy="edit-self-button"
            size="sm"
            // we have to wait for the coworkerId before we can activate the modal
            // probably only Cypress will ever go fast enough to see this disabled
            disabled={!user?.id}
            variant="link"
            className="ml-auto"
            onClick={() => {
              setModal({
                name: "edit",
                props: { coworkerId: user.id, showContactInfoFields: true },
              });
              setShowModal(true);
            }}
          >
            {t("account.edit")}
          </Button>
        </Card.Header>
        <Card.Body>
          {user?.isAdmin ? (
            <>
              <h4>Admin User</h4>
              <dl>
                <dt>Email</dt>
                <dd>{user?.email}</dd>
              </dl>
            </>
          ) : (
            <>
              <NameAndTitle coworker={user} className="h4" />
              <CoworkerDisplay
                // The user object contains the user's coworker document data
                coworker={user}
                canEdit={true}
                showEditButton={false}
                showMembershipStatus={true}
                isMember={isMember}
                isInvited={!!invites.length}
              />
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default MemberProfile;
