import colors from "../styles/colors.module.scss";

// starter tags for a unit that hasn't customized anything tag related yet
const DEFAULT_TAGS = [
  { id: 1, name: "follow-up needed", color: colors.blue },
  { id: 2, name: "influential leader", color: colors.darkGreen },
  { id: 3, name: "potential supervisor", color: colors.red },
];

const MAX_TAG_LENGTH = 30;

// colors to randomly choose from for new tags
// basically all our colors except for the white or light ones
const TAG_COLORS = [
  colors.grey,
  colors.black,
  colors.blue,
  colors.medBlue,
  colors.red,
  colors.lightRed,
  colors.yellow,
  colors.green,
  colors.darkGreen,
  colors.orange,
  colors.skyBlue,
  colors.navy,
];

// return the lowest tag id that's not in use
function getNextTagId(tags) {
  if (!tags) tags = DEFAULT_TAGS;
  // try all ids starting at 1, O(N) time and space (not that we really care)
  const usedIds = new Set(tags.map((tag) => tag.id));
  for (let i = 1; i < 999999; i++) {
    if (!usedIds.has(i)) {
      return i;
    }
  }
  return -1; // should be impossible
}

// return a random tag color that's not in use, from TAG_COLORS if possible, otherwise completely randomly
function getNextTagColor(tags) {
  if (!tags) tags = DEFAULT_TAGS;
  const usedColors = new Set(tags.map((tag) => tag.color));
  const availableColors = TAG_COLORS.filter((color) => !usedColors.has(color));
  if (availableColors.length === 0) {
    // one liner for random hex color: https://css-tricks.com/snippets/javascript/random-hex-color/
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }
  const randomIndex = Math.floor(Math.random() * availableColors.length);
  return availableColors[randomIndex];
}

// from https://stackoverflow.com/a/5624139
function hexToRgb(hex) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

// from https://github.com/omgovich/react-colorful/blob/master/demo/src/index.tsx
const getBrightness = ({ r, g, b }) => (r * 299 + g * 587 + b * 114) / 1000;

// chooses white or black text to be readable based on background color
function getTextColorFromBackgroundColor(hex) {
  const rgb = hexToRgb(hex);
  const textColor = getBrightness(rgb) > 128 ? "#000" : "#FFF";
  return textColor;
}

// only needed for testing of randomly generated strings in Cypress
// have to run this manually and copy-paste, Cypress isn't happy to include css colors modules
function getTagColorsInRGBStringsForCypress() {
  return TAG_COLORS.map((hex) => {
    const rgb = hexToRgb(hex);
    return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
  });
}

export {
  getNextTagColor,
  getNextTagId,
  getTextColorFromBackgroundColor,
  getTagColorsInRGBStringsForCypress,
  MAX_TAG_LENGTH,
  DEFAULT_TAGS,
};
