import colors from "../../styles/colors.module.scss";

function EnvelopeIcon({ color = "black", size = "30", ...props }) {
  return (
    <svg
      viewBox="0 0 30 30"
      height={size}
      width={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="matrix(1.25,0,0,1.25,0,0)">
        <path
          d="M1.500 4.750 L22.500 4.750 L22.500 19.750 L1.500 19.750 Z"
          fill="none"
          stroke={colors[color]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
        <path
          d="M22.161,5.3l-8.144,6.264a3.308,3.308,0,0,1-4.034,0L1.839,5.3"
          fill="none"
          stroke={colors[color]}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        ></path>
      </g>
    </svg>
  );
}

export default EnvelopeIcon;
