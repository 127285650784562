import { Col, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import TableHeaders from "./TableHeaders";
import TableBody from "./TableBody";

function UnitTable({
  onClickEntry = () => {},
  reactTable, // The initialized react table (ie result of useTable() hook)
}) {
  return (
    <Col data-cy="coworkers-table">
      <Row>
        <Table {...reactTable.getTableProps()} hover>
          <TableHeaders headerGroups={reactTable.headerGroups} />
          <TableBody
            rows={reactTable.rows}
            getTableBodyProps={reactTable.getTableBodyProps}
            prepareRow={reactTable.prepareRow}
            onClickEntry={onClickEntry}
          />
        </Table>
      </Row>
    </Col>
  );
}

export default UnitTable;
