import { useState, useEffect } from "react";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";

class NonexistentDocError extends Error {
  constructor(path) {
    const message = `The document at ${path} does not exist`;
    super(message);
    this.name = "NonexistentDocError";
  }
}

/* Hook to subscribe to a Firestore document's data
 * @param {String} path - a path to the document, delineated by slashes
 *   In firestore, the collection must always come first, so the string should
 *   look like "collectionA/docX/collectionB/docY"
 *   You can pass a null path and the hook will simply set data to null and loading false
 *   Be careful not to pass a path with part of the sub-path missing (eg "units/uni1/signatures/undefined")
 * @param {Boolean} pathLoading - a boolean representing whether the path is
 *   currently loading. If the path is loading, we set the data to null and loading to true
 *   This can be useful if the loading state here should depend on the loading state of
 *   another piece of data, for example the user's unit's loading state should not be
 *   resolved until their membership is resolved.
 *
 * @returns {Array} [data, loading, error]
 */
function useFirestoreDoc(path, pathLoading) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const db = getFirestore();

  useEffect(() => {
    if (pathLoading) {
      setData(null);
      setLoading(true);
      return;
    }

    if (!path) {
      setData(null);
      setLoading(false);
      return;
    }

    const handleData = (docSnap) => {
      if (docSnap.metadata.hasPendingWrites) {
        setLoading(true);
      } else if (!docSnap.exists()) {
        setError(new NonexistentDocError(path));
        setData(null);
        setLoading(false);
      } else {
        setData({ ...docSnap.data(), id: docSnap.id });
        setLoading(false);
      }
    };

    const handleError = (error) => {
      if (process.env.REACT_APP_development) {
        console.error(`Error with Firestore doc on ${path}`);
        console.error(error);
      }
      setError(error);
      const analytics = getAnalytics();
      logEvent(analytics, "app_error", {
        error_message: error.message ?? "Unspecified",
        error_name: error.name ?? "FirebaseError",
        error_path: `useFirestoreDoc on ${path}`,
      });
    };

    const unsubscribe = onSnapshot(doc(db, path), handleData, handleError);

    return unsubscribe;
  }, [path, pathLoading, db]);

  return [data, loading, error];
}

export default useFirestoreDoc;
