import firebase from "firebase/compat/app";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

// Logs every route change as a screen_view
function useFbAnalyticsScreenView() {
  let location = useLocation();
  useEffect(() => {
    firebase.analytics().logEvent(firebase.analytics.EventName.SCREEN_VIEW, {
      screen_name: location.pathname,
    });
  }, [location]);
}

export default useFbAnalyticsScreenView;
