import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";
import { ModalContext } from "../../contexts/modalContext";
import { useContext } from "react";

function InviteFooter({
  inviteMethod,
  invitee,
  disabled,
  disableUpdateSupport,
  disableInviteWizardContinue,
  showUpdateSupport,
  invite,
  save,
  close,
  inviteUrl,
  oc = false,
  wizardStep,
  wizardAbort,
  setWizardAbort,
  incrementWizardStep,
}) {
  const { setModal } = useContext(ModalContext);

  const { t } = useTranslation();

  if (!invitee) {
    return null;
  }

  if (wizardStep) {
    if (wizardAbort) {
      return (
        <Button
          data-cy="wizard-back"
          onClick={() => {
            setWizardAbort(false);
          }}
        >
          {t("inviteWizard.back")}
        </Button>
      );
    }
    if (wizardStep === "ocUpsell") {
      return (
        <>
          <Button data-cy="wizard-no" onClick={incrementWizardStep}>
            {t("inviteWizard.ocUpsell.no")}
          </Button>
          <Button
            data-cy="wizard-yes"
            onClick={() => {
              setModal({
                name: "inviteToOc",
                props: {
                  coworkerId: invitee.id,
                },
              });
            }}
          >
            {t("inviteWizard.ocUpsell.yes")}
          </Button>
        </>
      );
    }
    return (
      <Button
        disabled={disabled || disableInviteWizardContinue}
        data-cy="continue"
        onClick={incrementWizardStep}
      >
        {t("inviteWizard.continue")}
      </Button>
    );
  }

  if (showUpdateSupport) {
    return (
      <Button
        disabled={disabled || disableUpdateSupport}
        data-cy="save-coworker-submit"
        onClick={save}
      >
        {t("invite.saveAndContinue")}
      </Button>
    );
  }
  if (inviteMethod === "sms") {
    return invitee?.mobilePhone ? (
      <Button
        disabled={disabled}
        data-cy="invite-coworker-submit"
        onClick={invite}
      >
        {oc ? t("inviteOc.sendText") : t("inviteAction")}
      </Button>
    ) : (
      <Button disabled={disabled} data-cy="save-coworker-submit" onClick={save}>
        {t("invite.save")}
      </Button>
    );
  } else if (inviteMethod === "email") {
    return invitee?.email ? (
      <Button
        disabled={disabled}
        data-cy="invite-coworker-submit"
        onClick={invite}
      >
        {oc ? t("inviteOc.sendEmail") : t("inviteAction")}
      </Button>
    ) : (
      <Button disabled={disabled} data-cy="save-coworker-submit" onClick={save}>
        {t("invite.save")}
      </Button>
    );
  } else if (inviteMethod === "link") {
    return !inviteUrl ? (
      <Button disabled={disabled} data-cy="create-link-submit" onClick={invite}>
        {t("invite.createLinkSubmit")}
      </Button>
    ) : (
      <Button data-cy="close-modal" onClick={close}>
        {t("invite.close")}
      </Button>
    );
  } else {
    // TODO
    return null;
  }
}

export default InviteFooter;
