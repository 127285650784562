import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CoworkersPage from "./pages/coworkers/CoworkersPage";
import CoworkerDetailPage from "./pages/coworkerDetail/CoworkerDetailPage";
import AdminPage from "./pages/admin/AdminPage";
import SignaturesPage from "./pages/signatures/SignaturesPage";
import OcPage from "./pages/oc/OcPage";
import LoginPage from "./pages/login/LoginPage";
import JoinPage from "./pages/join/JoinPage";
import GetStartedPage from "./pages/getStarted/GetStartedPage";
import PrivateRoute from "./components/PrivateRoute";
import "./styles/main.scss";
import CreateUnitPage from "./pages/createUnit/CreateUnitPage";
import useFbAnalyticsScreenView from "./hooks/useFbAnalyticsScreenView";
import AccountPage from "./pages/account/AccountPage";
import JoinOcPage from "./pages/joinOc/JoinOcPage";
import CardSignerHomePage from "./pages/home/CardSignerHomePage";
import OcHomePage from "./pages/home/OcHomePage";
import ScrollToTop from "./components/ScrollToTop";

function Routes() {
  useFbAnalyticsScreenView();

  return (
    <Switch>
      <Route path="/login">
        <LoginPage />
      </Route>

      <Route path="/xorenlrk23348lker/success">
        <GetStartedPage success={true} />
      </Route>

      <Route path="/xorenlrk23348lker">
        <GetStartedPage />
      </Route>

      <Route path="/join/:inviteId">
        <JoinPage />
      </Route>

      <Route path="/jointheoc/:inviteId/ready">
        <JoinOcPage ready />
      </Route>

      <Route path="/jointheoc/:inviteId">
        <JoinOcPage />
      </Route>

      <PrivateRoute path="/aoe2345nsth">
        <CreateUnitPage />
      </PrivateRoute>

      <PrivateRoute ocOnly path="/account">
        <AccountPage />
      </PrivateRoute>

      <PrivateRoute ocOnly path="/signatures">
        <SignaturesPage />
      </PrivateRoute>

      <PrivateRoute ocOnly path="/oc">
        <OcPage />
      </PrivateRoute>

      <PrivateRoute ocOnly path="/ochome">
        <OcHomePage />
      </PrivateRoute>

      <PrivateRoute ocOnly path="/coworkers">
        <CoworkersPage />
      </PrivateRoute>

      <PrivateRoute ocOnly path="/coworker/:coworkerId/invite">
        <CoworkerDetailPage initModal="invite" />
      </PrivateRoute>

      <PrivateRoute ocOnly path="/coworker/:coworkerId">
        <CoworkerDetailPage />
      </PrivateRoute>

      <PrivateRoute exact ogAdminOnly path="/admin">
        <AdminPage />
      </PrivateRoute>

      <PrivateRoute adminOnly path="/admin/unit/:unitId">
        <AdminPage />
      </PrivateRoute>

      {/* NOT membersOnly because we also have to handle case with a login but no unit */}
      <PrivateRoute path="/memberhome">
        <CardSignerHomePage />
      </PrivateRoute>

      <PrivateRoute redirectToMemberHomeOrOcHome={true} />
    </Switch>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop>
        <Routes />
      </ScrollToTop>
    </Router>
  );
}

export default App;
