import Tooltip from "./Tooltip";
import { useTranslation } from "react-i18next";

function MemberCheck({ isMember = false }) {
  const { t } = useTranslation();

  return isMember ? <Tooltip contents={t("cardSigner")}>✅</Tooltip> : null;
}

export default MemberCheck;
