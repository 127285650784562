import { Controller } from "react-hook-form";
import Form from "react-bootstrap/Form";
import PhoneInput from "react-phone-number-input/input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { Typeahead } from "react-bootstrap-typeahead";
import { useTranslation } from "react-i18next";
import "./FormFields.scss";

/* Takes an array of fields and returns a bootstrap <Form>
 * with those fields. Includes a default render for checkbox, phone, select, email,
 * number, typeahead and generic fields. But you can pass a custom render function
 * if you need a specialized field component.
 *
 * @param {Array} fields
 * [{
 *   name: String,
 *   label: String, (optional, puts the label above the input)
 *   checkboxLabel: String, (only for checkbox inputs)
 *   type: String,  (checkbox, phone, select, text, typeahead, textarea)
 *   rules: Object, (see react-hook-form, optional)
 *   defaultValue: String (optional) (text)
 *   placeholder: String (optional) (text)
 *   disabled: boolean (optional) (text)
 *   render: Function, (optional)
 *   selectOptions: Array (only for select inputs [{ key, value, label }] )
 *   append: JSX (will render after the field and any errors)
 *  }]
 *
 * @param {Object} control - the control object you get from the useForm() hook
 * @param {Object} errors - the errors object you get from the useForm() hook
 * @param {boolean} showOptionalLabel - whether to show an optional label for non-required fields
 */
function FormFields({
  fields,
  control,
  errors,
  showOptionalLabel = true,
  ...props
}) {
  const { t } = useTranslation();
  const optionalLabel = showOptionalLabel ? (
    <small className="font-italic text-secondary">
      {" "}
      - {t("fieldValidation.optional")}
    </small>
  ) : null;
  return (
    <Form {...props}>
      {fields.map((field) => {
        return (
          <Form.Group key={field.name} controlId={field.name}>
            {field.label && (
              <Form.Label data-cy={`${field.name}-label`}>
                {field.label}
                {field?.rules?.required ? null : optionalLabel}
              </Form.Label>
            )}
            <Controller
              name={field.name}
              defaultValue={field.defaultValue || ""}
              control={control}
              rules={addDefaultRules(field, t)}
              render={field.render || defaultRenderFunction(field)}
            />
            {errors[field.name] && (
              <div className="text-danger ml-2" data-cy={`${field.name}-error`}>
                <small>{errors[field.name]?.message}</small>
              </div>
            )}
            {field.append}
          </Form.Group>
        );
      })}
    </Form>
  );
}

/* VALIDATION RULES */

function addDefaultRules(field, t) {
  const rules = { validate: {}, ...field.rules };

  // react-hook-form doesn't check for whitespace strings
  // in required validation so we add our own check
  if (field.rules?.required) {
    rules.validate.noWhitespaceString = (value) =>
      typeof value === "string"
        ? value.trim().length > 0 || field.rules?.required
        : true;
  }

  switch (field.type) {
    case "phone":
      rules.validate.validatePhone = (value) => validatePhone(value, t);
      break;
    case "email":
      rules.validate.validateEmail = (value) => validateEmail(value, t);
      break;
    default:
      break;
  }
  return rules;
}

function validatePhone(value, t) {
  return (
    !value || isValidPhoneNumber(value) || t("fieldValidation.invalidPhone")
  );
}

function validateEmail(value, t) {
  return !value || isValidEmail(value) || t("fieldValidation.invalidEmail");
}

/* Returns true only if the email is valid
 * Apparently there's no perfect regex for email validation, and many of the ones that try for perfect end up
 * incomprehensible or buggy, see https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
 * This one from https://stackoverflow.com/a/9204568 is just looking for obvious mistakes and is happy with
 * anystring@anystring.anystring
 */
function isValidEmail(value) {
  const re = /\S+@\S+\.\S+/;
  return re.test(value);
}

/* RENDER FUNCTIONS */

function defaultRenderFunction(field) {
  switch (field.type) {
    case "phone":
      return renderPhone(field);
    case "checkbox":
      return renderCheckbox(field);
    case "select":
      return renderSelect(field);
    case "textarea":
      return renderTextArea(field);
    case "number":
      return renderNumber(field);
    case "password":
      return renderPassword(field);
    case "typeahead":
      return renderTypeAhead(field);
    default:
      return renderBasicInput(field);
  }
}

function renderBasicInput(field) {
  return ({ onChange, ...props }) => (
    <Form.Control
      placeholder={field.placeholder}
      disabled={field.disabled}
      data-cy={`${field.name}-input`}
      inputMode={field.inputMode}
      className={field.className}
      onChange={(e) => {
        if (field.onChange) {
          field.onChange(e);
        }
        onChange(e);
      }}
      {...props}
    />
  );
}

function renderPhone(field) {
  return (props) => (
    <PhoneInput
      className={`form-control ${field.className}`}
      data-cy={`${field.name}-input`}
      country="US"
      {...props}
    />
  );
}

function renderCheckbox(field) {
  return ({ value, onChange, ...props }) => (
    <Form.Check
      type="checkbox"
      label={field.checkboxLabel}
      data-cy={`${field.name}-checkbox`}
      className={field.className}
      checked={value}
      onChange={(e) => {
        if (field.onChange) {
          field.onChange(e.target.checked);
        }
        onChange(e.target.checked);
      }}
      {...props}
    />
  );
}

function renderSelect(field) {
  return ({ onChange, ...props }) => (
    <Form.Control
      as="select"
      data-cy={`${field.name}-select`}
      className={field.className}
      onChange={(e) => {
        if (field.onChange) {
          field.onChange(e.target.value);
        }
        onChange(e);
      }}
      {...props}
    >
      <option key="default" value={undefined} />
      {field.selectOptions.map((opt) => (
        <option key={opt.key} value={opt.value}>
          {opt.label}
        </option>
      ))}
    </Form.Control>
  );
}

function renderTextArea(field) {
  return ({ onChange, ...props }) => (
    <Form.Control
      as="textarea"
      rows={field.rows ?? 7}
      placeholder={field.placeholder}
      data-cy={`${field.name}-input`}
      className={field.className}
      onChange={(e) => {
        if (field.onChange) {
          field.onChange(e.target.value);
        }
        onChange(e);
      }}
      {...props}
    />
  );
}

function renderNumber(field) {
  return (props) => (
    <Form.Control
      type="number"
      as="input"
      rows={field.rows ?? 7}
      placeholder={field.placeholder}
      data-cy={`${field.name}-input`}
      className={field.className}
      {...props}
      size="lg"
      style={{ width: "6em" }}
    />
  );
}

function renderPassword(field) {
  return (props) => (
    <Form.Control
      type="password"
      as="input"
      ref={field.register}
      data-cy={`${field.name}-input`}
      className={field.className}
      {...props}
    />
  );
}

// found a problem with the TypeAhead component which is if you don't choose one of it's options you get nothing.
// most perverse version of this is if you type "Alaska" but don't select "Alaska" from dropdown you can't proceed
function renderTypeAhead(field) {
  return ({ value, onChange, ...props }) => (
    <Typeahead
      id={`${field.name}-input`} // this is a prefix on selection options, etc
      maxResults={1000}
      className={field.className}
      multiple={field.multiple} // boolean
      placeholder={field.placeholder ?? "Type to Search"}
      selected={value}
      onChange={onChange}
      options={field.options} // array of strings
      inputProps={{ "data-cy": `${field.name}-input` }} // this will be the input box you type in
      {...props}
    />
  );
}

export default FormFields;
