import Error from "../Error";
import { useTranslation } from "react-i18next";

function JoinError({ error }) {
  const { t } = useTranslation();

  let errorMessage = t("join.error.generic");
  let showOops = true;
  if (error.name === "InviteValidationError") {
    errorMessage = t("join.error.invalid");
  } else if (error.name === "InviteForWrongPerson") {
    errorMessage = t("join.error.onlyOne");
    showOops = false;
  }
  return (
    <Error
      error={{ name: error.name }}
      displayText={errorMessage}
      showOops={showOops}
    />
  );
}

export default JoinError;
