import Button from "react-bootstrap/Button";
import useCoworker from "../../hooks/useCoworker";
import { deleteCoworker } from "../../utilities/firestore";
import { useContext, useEffect, useState } from "react";
import { UserUnitContext } from "../../contexts/userUnitContext";
import Modal from "../Modal";
import { ModalContext } from "../../contexts/modalContext";
import MemberCheck from "../../components/MemberCheck";
import Error from "../../components/Error";
import { useTranslation } from "react-i18next";

// TODO: currently when you delete the coworker, this causes the CoworkerDetailPage
// to navigate to /coworkers, because the coworker document no longer exists,
// causing a NonexistentDocError.
// This works ok, but is a little smelly -- we'd rather gracefully show a deletion
// confirmation, close the modal, and then redirect
// However, we don't think coworker deletion should be a common workflow, so this is
// ok for now.
function DeleteCoworkerModal({ coworkerId, ...props }) {
  const { unit, user } = useContext(UserUnitContext);
  const { showModal, setShowModal } = useContext(ModalContext);
  const [coworker, loading] = useCoworker({ coworkerId, unitId: unit?.id });
  const [error, setError] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    setError(null);
  }, [showModal]);

  const deleteButton = (
    <Button
      variant="danger"
      data-cy="delete-coworker-submit"
      disabled={!!error}
      onClick={async () => {
        setError(null);
        try {
          await deleteCoworker({
            coworkerId,
            unitId: unit.id,
            deletedByUid: user.uid,
            memberUid: coworker.memberUid,
          });
          setShowModal(false);
        } catch (error) {
          setError(error);
        }
      }}
    >
      {t("deleteCoworker.confirm")}
    </Button>
  );

  const modalBody = (
    <>
      <p>
        {t("deleteCoworker.ask", {
          name: `${coworker?.firstName} ${coworker?.lastName}`,
        })}
      </p>
      {coworker?.memberUid ? (
        <p data-cy="delete-member-warning">
          <MemberCheck isMember={true} />
          {t("deleteCoworker.warning")} <MemberCheck isMember={true} />
        </p>
      ) : null}
    </>
  );

  return (
    <Modal
      data-cy="delete-coworker-modal"
      title="Delete coworker"
      loading={loading}
      body={
        error ? (
          <Error
            className="m-3"
            error={error}
            displayText={t("deleteCoworker.error")}
          />
        ) : (
          modalBody
        )
      }
      footerActions={deleteButton}
      {...props}
    />
  );
}

export default DeleteCoworkerModal;
