import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function CoworkerBreadcrumb({ coworker }) {
  const location = useLocation();
  const { t } = useTranslation();

  // either /coworkers or /signatures
  const backPage =
    location?.state?.from ||
    (coworker?.memberUid ? "/signatures" : "/coworkers");

  return (
    <Breadcrumb>
      {/* Link back to either Coworkers or Members */}
      <Breadcrumb.Item href={backPage} data-cy="back-breadcrumb">
        {backPage === "/signatures"
          ? t("pageName.signatures")
          : t("pageName.coworkers")}
      </Breadcrumb.Item>
      <Breadcrumb.Item active>
        {coworker?.firstName} {coworker?.lastName}
      </Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default CoworkerBreadcrumb;
