import useFirestoreDoc from "./useFirestoreDoc";

// Subscribe to the user's membership
export default function useMembership(uid, uidLoading) {
  const [membership, loading, error] = useFirestoreDoc(
    uid ? `memberships/${uid}` : null,
    uidLoading
  );

  return [membership, loading, error];
}
