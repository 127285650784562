import { useState, useEffect } from "react";

// Inspired by https://usehooks.com/useWindowSize/ with addition of bootstrap
// breakpoints and SO-approved method of getting height and width
// https://stackoverflow.com/questions/1248081/how-to-get-the-browser-viewport-dimensions/8876069#8876069

function useViewportSize() {
  const [size, setSize] = useState({
    height: undefined,
    width: undefined,
    breakpoint: undefined,
  });

  useEffect(() => {
    function handleResize() {
      const height = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );
      const width = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      const breakpoint = getBreakpoint(width);
      setSize({ height, width, breakpoint });
    }

    // Match the Bootstrap breakpoints https://getbootstrap.com/docs/5.0/layout/breakpoints/
    function getBreakpoint(width) {
      if (width <= 576) return "xs";
      if (width <= 768) return "sm";
      if (width <= 992) return "md";
      if (width <= 1200) return "lg";
      return "xl";
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return size;
}

export default useViewportSize;
