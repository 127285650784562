import useFirestoreQuery from "./useFirestoreQuery";
import { useState } from "react";

function useOcMembers({ unitId }) {
  const [queries] = useState([
    ["isDeleted", "==", false],
    ["isOcMember", "==", true],
  ]);
  const [data, loading, error] = useFirestoreQuery(
    `units/${unitId}/coworkers`,
    queries,
    !unitId
  );

  return [data, loading, error];
}

export default useOcMembers;
