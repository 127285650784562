import ContactUnitLink from "./buttonsAndLinks/ContactUnitLink";
import LogoutLink from "./buttonsAndLinks/LogoutLink";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// A message that the user is already a member of a union
// and directing them back to their home page
function AlreadyAMember({ firstName, unitName, isOcMember, ...props }) {
  const { t } = useTranslation();

  return (
    <div {...props} data-cy="already-a-member">
      <h2>{t("alreadyMember", { firstName })}</h2>
      <p className="text-secondary mb-4">
        {t("alreadyMemberExplanation", { unitName })}
      </p>
      <Link to={isOcMember ? "/ochome" : "/memberhome"}>
        <Button className="my-4" variant="primary">
          {t("backToUnit")}
        </Button>
      </Link>
      <LogoutLink text={firstName ? `Not ${firstName}?` : ""} />
      <ContactUnitLink className="" />
    </div>
  );
}

export default AlreadyAMember;
