import { showIntercom } from "../../utilities/intercom";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";

function ContactUnitButton({ children }) {
  const { t } = useTranslation();

  return (
    <Button data-cy="contact-unit-button" onClick={() => showIntercom()}>
      {children || t("contactUnit")}
    </Button>
  );
}

export default ContactUnitButton;
