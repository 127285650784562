import UnitNameLogo from "../UnitNameLogo";
import JoinError from "./JoinError";
import Spinner from "../Spinner";
import "./JoinLayout.scss";

function JoinLayout({
  unit,
  error,
  loading,
  isWrongPerson,
  children,
  ...props
}) {
  let contents;
  if (error) {
    contents = <JoinError error={error} />;
  } else if (isWrongPerson) {
    contents = <JoinError error={{ name: "InviteForWrongPerson" }} />;
  } else if (loading) {
    contents = <Spinner />;
  } else {
    contents = children;
  }

  return (
    <div className="join-layout-container" {...props}>
      <div className="join-header mt-2 mb-4 text-center" data-cy="join-header">
        <UnitNameLogo text={unit?.unitName} />
      </div>
      {contents}
    </div>
  );
}

export default JoinLayout;
