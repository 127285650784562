import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { useState, useContext, useEffect, useMemo } from "react";
import { UserUnitContext } from "../../contexts/userUnitContext";
import NameAndTitle from "../../components/NameAndTitle";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./ChooseCoworkerOc.scss";
import { ModalContext } from "../../contexts/modalContext";
import { useTranslation } from "react-i18next";
import PlusCircleIcon from "../../components/icons/PlusCircleIcon";
import useCoworkersOnce from "../../hooks/useCoworkersOnce";
import { SUPPORTIVE_OR_UNDECIDED } from "../../constants/supportRatings";

// like ChooseCoworker component but with enough differences to make code reuse difficult
function ChooseCoworkerOc({ defaultCoworkerId, ...props }) {
  const { t } = useTranslation();

  const { unit } = useContext(UserUnitContext);
  const { setModal } = useContext(ModalContext);

  // if we subscribe to the coworkers we're at the mercy of interesting firestore behavior
  // where it will first send us the complete list of OC members (cached from OcPage)
  // and then send us the rest, which complicates the decision of whether to automatically
  // select a member
  const [coworkers, loading] = useCoworkersOnce({ unitId: unit?.id });

  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const filterFields = useMemo(() => ["firstName", "lastName", "jobTitle"], []);

  // if we have no valid choices at all go straight to adding a new coworker
  useEffect(() => {
    // don't make any decision untils all coworkers are loaded
    // and only trigger exactly once, the first time the coworkers are loaded
    if (loading || !isFirstLoad) {
      return;
    }
    const anyChoices = coworkers.find(
      (c) => !c.isOcMember && c.supportRating <= 3
    );
    // automatically switch the modal to adding new coworker state if there are no valid choices
    // AND if we were not already passed a defaultCoworkerId to use
    if (!anyChoices && !defaultCoworkerId) {
      setModal({ name: "inviteToOc", props: { isAddingNewCoworker: true } });
    }
    const coworkerOptions = coworkers
      .map((coworker) => {
        const filterFieldData = {};
        filterFields.forEach((field) => {
          filterFieldData[field] = coworker[field] || "";
        });
        return {
          key: coworker.id,
          value: coworker.id,
          label: `${coworker.firstName} ${coworker.lastName}`,
          disabled:
            coworker?.isOcMember ||
            coworker.supportRating > SUPPORTIVE_OR_UNDECIDED, // enabled for 🤷, 👍 and 👍👍
          supportRating: coworker.supportRating,
          isOcMember: coworker?.isOcMember,
          id: coworker.id,
          memberUid: coworker.memberUid,
          ...filterFieldData,
        };
      })
      .sort((a, b) =>
        `${a.firstName} ${a.lastName}`.localeCompare(
          `${b.firstName} ${b.lastName}`
        )
      );
    setOptions(coworkerOptions);
    if (defaultCoworkerId) {
      setSelected(coworkerOptions.filter((c) => c.id === defaultCoworkerId));
    }
    setIsFirstLoad(false);
  }, [
    coworkers,
    loading,
    setModal,
    isFirstLoad,
    filterFields,
    defaultCoworkerId,
  ]);

  function handleSelect(s) {
    setModal({
      name: "inviteToOc",
      props: { coworkerId: s[0]?.id },
    });

    setSelected(s);
  }

  return (
    <div data-cy="choose-coworker">
      <Typeahead
        id="coworker-invite-select"
        placeholder={t("inviteOc.chooseCoworker.searchPlaceholder")}
        clearButton
        autoFocus={!defaultCoworkerId}
        isLoading={loading}
        defaultOpen={!defaultCoworkerId}
        onChange={handleSelect}
        renderMenu={(results, menuProps) => (
          <CoworkerSelectMenu
            results={results}
            menuProps={menuProps}
            t={t}
            setModal={setModal}
          />
        )}
        filterBy={filterFields}
        options={options}
        selected={selected}
        {...props}
      />
    </div>
  );
}

function CoworkerSelectMenu({ results, menuProps, t, setModal }) {
  return (
    <Menu {...menuProps}>
      {results.map((result, index) => {
        return (
          <MenuItem
            key={result.id}
            disabled={result.disabled}
            option={result}
            position={index}
          >
            <CoworkerSelectOption option={result} t={t} />
          </MenuItem>
        );
      })}

      <AddNewCoworker setModal={setModal} t={t} />
    </Menu>
  );
}

function CoworkerSelectOption({ option, t }) {
  return (
    <Row className="coworker-select-option">
      <Col>
        <NameAndTitle coworker={option} />
      </Col>
      <Col>
        <small data-cy="reason-not-selectable">
          {option?.isOcMember
            ? t("inviteOc.chooseCoworker.alreadyMember")
            : option?.supportRating > SUPPORTIVE_OR_UNDECIDED
            ? t("inviteOc.chooseCoworker.unsupportive")
            : ""}
        </small>
      </Col>
    </Row>
  );
}

function AddNewCoworker({ setModal, t }) {
  return (
    <button
      className="dropdown-button"
      data-cy="add-coworker-instead"
      onClick={() => {
        setModal({ name: "inviteToOc", props: { isAddingNewCoworker: true } });
      }}
    >
      <PlusCircleIcon id="plus-icon" className="inline-icon-big" />{" "}
      {t("inviteOc.chooseCoworker.createCoworker")}
    </button>
  );
}

export default ChooseCoworkerOc;
