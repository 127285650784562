import { useContext } from "react";
import Spinner from "./Spinner";
import { UserUnitContext } from "../contexts/userUnitContext";
import useIntercom from "../hooks/useIntercom";
import Footer from "./Footer";
import NavBar from "./NavBar";
import ModalPicker from "../modals/ModalPicker";
import "./PageLayout.scss";
import Toast from "./Toast";
import Unverified from "./Unverified";

function PageLayout({
  pageTitle,
  showNav = true,
  showLoginOut = true,
  showAccountDropdown = true,
  requireVerifiedEmail = false,
  children,
  ...props
}) {
  const { loading, user } = useContext(UserUnitContext);
  useIntercom();

  const title = pageTitle ? `${pageTitle} | Unit` : "Unit";
  const devTag = process.env.REACT_APP_development ? " (Dev)" : "";
  document.title = `${title}${devTag}`;

  let pageContents = loading ? <Spinner /> : children;
  if (requireVerifiedEmail && user?.emailVerified === false) {
    pageContents = <Unverified />;
  }

  return (
    <div id="wrapper">
      <NavBar
        showNav={showNav}
        showLoginOut={showLoginOut}
        showAccountDropdown={showAccountDropdown}
      />
      <ModalPicker />
      <Toast />
      <main {...props}>{pageContents}</main>
      <Footer />
    </div>
  );
}

export default PageLayout;
