import Button from "react-bootstrap/Button";
import { leaveUnit } from "../../utilities/firestore";
import { useContext, useEffect, useState } from "react";
import { UserUnitContext } from "../../contexts/userUnitContext";
import Modal from "../Modal";
import { ModalContext } from "../../contexts/modalContext";
import Error from "../../components/Error";
import { useTranslation } from "react-i18next";

// Usually this would be used for a user to rescind their own card
// and leave the unit. However, if we pass the memberUid and coworkerId
// an admin can use it to rescind a member's card for them.

function LeaveUnitModal({ memberUid, coworkerId, ...props }) {
  const { unit, user, loading } = useContext(UserUnitContext);
  const { showModal, setShowModal } = useContext(ModalContext);
  const [error, setError] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    setError(null);
  }, [showModal]);

  const submit = async () => {
    setError(null);
    try {
      await leaveUnit({
        unitId: unit.id,
        coworkerId: coworkerId || user.id,
        uid: memberUid || user.uid,
        updatedByUid: user.uid,
      });
      setShowModal(false);
    } catch (error) {
      setError(error);
    }
  };

  const submitButton = (
    <Button
      variant="danger"
      data-cy="leave-unit-submit"
      disabled={!!error}
      onClick={submit}
    >
      {t("leaveUnit.confirm")}
    </Button>
  );

  const modalBody = (
    <>
      <p>{t("leaveUnit.explain")}</p>
      <p>{t("leaveUnit.ask", { unitName: unit?.unitName })}</p>
      {memberUid && user?.isAdmin && (
        <p className="text-danger">
          ADMIN: You are rescinding the card on behalf of the user. They will
          recieve an email confirming their de-authorization.
        </p>
      )}
    </>
  );

  return (
    <Modal
      data-cy="leave-unit-modal"
      title={t("leaveUnit.title")}
      loading={loading}
      body={
        error ? (
          <Error
            className="m-3"
            error={error}
            displayText={t("leaveUnit.error")}
          />
        ) : (
          modalBody
        )
      }
      footerActions={submitButton}
      {...props}
    />
  );
}

export default LeaveUnitModal;
