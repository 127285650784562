import Tooltip from "../Tooltip";
import Button from "react-bootstrap/Button";
import EnvelopePencilIcon from "../icons/EnvelopePencilIcon";
import OutboundLink from "./OutboundLink";

/*
 * An email mailto: link with an explanatory tooltip
 * Can be displayed as a link or an icon or a button
 *
 * @param {Boolean} show
 * @param {Array} emails -- the email addresses, can be one or many
 * @param {Boolean} asIcon - can show an icon button instead of email link
 * @param {Boolean} asButton - wraps the email text in a button
 */
function EmailLink({
  children,
  show = true,
  emails,
  asIcon = false,
  asButton = false,
  disabled = false,
  "data-cy": dataCy = "email", // alias to allow hyphenated prop name
  ...props
}) {
  if (!show) return null;
  if (emails === undefined) return null; // still show disabled button if emails is set to empty array

  const tooltipText = `Email ${emails.join(", ")}`;
  const mailtoAddress = emails.join(","); // no spaces here is important for some email clients
  if (!children) {
    children = mailtoAddress;
  }

  return (
    <Tooltip contents={tooltipText}>
      <OutboundLink
        disabled={disabled}
        data-cy={`${dataCy}-link`}
        href={`mailto:${mailtoAddress}`}
        onClick={(e) => e.stopPropagation()}
      >
        {asIcon ? (
          <Button disabled={disabled} size="sm" {...props}>
            <EnvelopePencilIcon color="white" size="16" />
          </Button>
        ) : null}
        {asButton ? (
          <Button disabled={disabled} data-cy={`${dataCy}-button`} {...props}>
            {children}
          </Button>
        ) : null}
        {!asIcon && !asButton ? children : null}
      </OutboundLink>
    </Tooltip>
  );
}

export default EmailLink;
