import { useState, useContext } from "react";
import Modal from "../Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import firebase from "firebase/compat/app";
import FormFields from "../../components/FormFields";
import { validatePassword } from "../../utilities/auth";
import { ModalContext } from "../../contexts/modalContext";
import { ToastContext } from "../../contexts/toastContext";
import { useTranslation } from "react-i18next";

function ChangePasswordModal() {
  const { t } = useTranslation();

  const { setShowModal } = useContext(ModalContext);
  const { setShowToast, setToastProps } = useContext(ToastContext);
  const {
    handleSubmit,
    formState,
    control,
    errors,
    watch,
    register,
    // onBlur is important because we hit an external api to validate the old password
    // and without onBlur we hit that API every keystroke in any field after the first error
    // see discussion here: https://github.com/react-hook-form/react-hook-form/issues/283
  } = useForm({ mode: "onBlur", reValidateMode: "onBlur" });
  const [savingPassword, setSavingPassword] = useState(false);

  async function changePassword(data) {
    setSavingPassword(true);
    await firebase.auth().currentUser.updatePassword(data.newPassword);
    setSavingPassword(false);
    setToastProps({
      header: t("success"),
      body: t("account.password.changed"),
      "data-cy": "change-password-toast",
    });
    setShowToast(true);
    setShowModal(false);
  }

  const changePasswordButton = (
    <Button
      disabled={savingPassword || formState.isSubmitting}
      data-cy="change-password-submit"
      onClick={handleSubmit(changePassword)}
    >
      {t("account.password.submit")}
    </Button>
  );

  return (
    <Modal
      data-cy="change-password-modal"
      title={t("account.password.change")}
      body={ModalBody({
        control,
        errors,
        register,
        watch,
      })}
      footerActions={changePasswordButton}
    />
  );
}

function ModalBody({ control, errors, register, watch }) {
  const { t } = useTranslation();

  const fields = [
    {
      name: "oldPassword",
      label: t("fieldName.oldPassword"),
      type: "password",
      rules: {
        required: "Old password is required.",
        validate: {
          default: async (value) => {
            const validPassword = await validatePassword(value);
            if (!validPassword) {
              return t("fieldValidation.password.incorrect");
            }
          },
        },
      },
    },
    {
      name: "newPassword",
      label: t("fieldName.newPassword"),
      type: "password",
      register, // register here so watch("newPassword") will work later
      rules: {
        required: "New password is required.",
        minLength: {
          value: 8,
          message: t("fieldValidation.password.tooShort"),
        },
      },
    },
    {
      name: "retypeNewPassword",
      label: t("fieldName.retypeNewPassword"),
      type: "password",
      rules: {
        required: "Re-type new password is required.",
        validate: {
          default: (value) => {
            if (value !== watch("newPassword")) {
              return t("fieldValidation.password.noMatch");
            }
          },
        },
      },
    },
  ];

  return <FormFields fields={fields} control={control} errors={errors} />;
}

export default ChangePasswordModal;
