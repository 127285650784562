import React, { useMemo } from "react";
import { useSortBy, useTable } from "react-table";
import NameAndTitle from "../../../components/NameAndTitle";
import UnitTable from "../../../components/table/UnitTable";
import { useTranslation } from "react-i18next";
import "./OcMembersTable.scss";
import PlusCircleIcon from "../../../components/icons/PlusCircleIcon";

// Column headers and accessors for coworkers table
function getColumns(t) {
  return [
    {
      Header: (
        <NameAndTitle
          firstName={t("coworkers.table.name")}
          lastName=""
          jobTitle={t("fieldName.jobTitle")}
        />
      ),
      id: "nameTitle",
      accessor: (row) => `${row?.firstName} ${row?.lastName} ${row.jobTitle}`,
      Cell: ({ row }) => {
        return (
          <NameAndTitle
            coworker={row.original}
            pendingOcInvite={row.original.pendingOcInvite}
            linkTo={{
              pathname: `/coworker/${row.original.id}`,
              state: { from: "/oc" },
            }}
          />
        );
      },
    },
    {
      Header: t("fieldName.department"),
      accessor: "department",
    },
    {
      Header: t("fieldName.invitedBy"),
      accessor: "invitedBy",
    },
  ];
}

/**
 * A sortable, filterable, clickable table for displaying coworker info
 * @param {function} onClickCoworker called with coworker object when an entry is clicked
 * @param {function} onClickNewOcMember called when "Inivte new OC member is clicked"
 **/
function OcMembersTable({ onClickCoworker, onClickNewOcMember, coworkers }) {
  const { t } = useTranslation();

  const initialState = useMemo(() => {
    return {
      sortBy: [{ id: "nameTitle", desc: false }],
      filters: [],
    };
  }, []);

  const columns = useMemo(() => {
    return getColumns(t);
  }, [t]);

  const reactTable = useTable(
    {
      columns,
      data: coworkers,
      initialState,
      disableSortRemove: true,
    },
    useSortBy
  );

  return (
    <div className="oc-members-table">
      <UnitTable onClickEntry={onClickCoworker} reactTable={reactTable} />
      <button
        className="dropdown-button"
        data-cy="add-new-oc-member"
        onClick={onClickNewOcMember}
      >
        <PlusCircleIcon id="plus-icon" className="inline-icon-big" />{" "}
        {t("inviteOc.new")}
      </button>
    </div>
  );
}

export default OcMembersTable;
