import { useContext } from "react";
import { Redirect } from "react-router-dom";
import ContactUnitLink from "../../../components/buttonsAndLinks/ContactUnitLink";
import { useTranslation } from "react-i18next";
import useUnitByCreatedByUid from "../../../hooks/useUnitByCreatedByUid";
import { UserUnitContext } from "../../../contexts/userUnitContext";
import Spinner from "../../../components/Spinner";

function NonMemberHomePage() {
  const { t } = useTranslation();
  const { user, loading } = useContext(UserUnitContext);
  const [unitCreatedByMe, unitCreatedByMeLoading] = useUnitByCreatedByUid({
    createdByUid: user?.uid,
  });

  if (loading || unitCreatedByMeLoading) {
    return <Spinner />;
  }

  // If a user created a unit but never "joined" by signing
  // a card, send them back to their setup process
  if (unitCreatedByMe) {
    return <Redirect to="/aoe2345nsth" />;
  }

  return (
    <>
      <h2>{t("home.nonMember.header")}</h2>
      <p>
        <span className="semibold">{t("home.nonMember.noCard")}</span>
      </p>
      <p className="text-secondary">{t("home.nonMember.requestLink")}</p>
      <p className="text-secondary">{t("home.nonMember.stopAllowing")}</p>
      <ContactUnitLink />
    </>
  );
}

export default NonMemberHomePage;
