import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ChooseCoworker from "./ChooseCoworker";

function InviteHeader({
  inviteMethod,
  setInviteMethod,
  showChooseCoworker,
  showTabs = true,
  t,
}) {
  return (
    <div className="my-3">
      {showChooseCoworker && (
        <div className="mb-4 mx-3">
          <ChooseCoworker />
        </div>
      )}
      {showTabs && (
        <Tabs
          id="invite-method-tabs"
          activeKey={inviteMethod}
          onSelect={setInviteMethod}
        >
          <Tab
            eventKey="link"
            data-cy="invite-by-link"
            title={t("invite.method.link")}
          />

          <Tab eventKey="sms" title={t("invite.method.text")} />

          <Tab
            eventKey="email"
            data-cy="invite-by-email"
            title={t("invite.method.email")}
          />
        </Tabs>
      )}
    </div>
  );
}

export default InviteHeader;
