import { useContext, useMemo } from "react";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import "./CoworkerHistory.scss";
import LinkButton from "../../../components/buttonsAndLinks/LinkButton";
import { ModalContext } from "../../../contexts/modalContext";
import { useTranslation } from "react-i18next";

function CoworkerHistory({ coworker, user, invites }) {
  const { t } = useTranslation();
  const { setModal, setShowModal } = useContext(ModalContext);
  const timelineEvents = useMemo(() => {
    let createdBy = "";
    if (coworker?.createdByUid === user?.uid) {
      createdBy = t("coworkerDetail.history.byYou");
    } else if (
      coworker?.createdBy?.firstName &&
      coworker?.createdBy?.lastName
    ) {
      createdBy = t("coworkerDetail.history.bySomeone", {
        name: `${coworker?.createdBy.firstName} ${coworker?.createdBy.lastName}`,
      });
    }
    const timelineEvents = [
      // Always include a created event so the timeline is never empty
      {
        firestoreTimestamp: coworker?.created,
        description: t("coworkerDetail.history.created"),
        key: `created-${coworker?.id}`,
        by: createdBy,
      },
      ...invites.map((invite) => {
        let description = t("coworkerDetail.history.invited");
        let showViewEditLink = false;
        let invitedBy = "";
        if (invite.createdByUid === user?.uid) {
          invitedBy = t("coworkerDetail.history.byYou");
        } else if (invite?.inviter?.firstName && invite.inviter?.lastName) {
          invitedBy = t("coworkerDetail.history.bySomeone", {
            name: `${invite.inviter.firstName} ${invite.inviter.lastName}`,
          });
        }
        if (invite.inviteMethod === "sms") {
          description = t("coworkerDetail.history.invitedBySms");
        } else if (invite.inviteMethod === "email") {
          description = t("coworkerDetail.history.invitedByEmail");
        } else if (invite.inviteMethod === "link") {
          description = t("coworkerDetail.history.invitedByLink");
          if (invite.createdByUid === user?.uid) {
            showViewEditLink = true;
          }
        }
        return {
          firestoreTimestamp: invite.created,
          description,
          by: invitedBy,
          showViewEditLink,
          key: `invited-${invite.id}`,
        };
      }),
    ];
    return timelineEvents;
  }, [coworker, invites, user, t]);

  return (
    <>
      <div className="semibold mt-4">{t("coworkerDetail.history.title")}</div>
      <Timeline data-cy="coworker-history-timeline">
        {
          // Show most recent events on top
          timelineEvents
            .sort(
              (a, b) =>
                b?.firestoreTimestamp?.toMillis() -
                a?.firestoreTimestamp?.toMillis()
            )
            .map((e, i) =>
              Event({
                ...e,
                showConnector: i < timelineEvents.length - 1,
                coworkerId: coworker?.id,
                setModal,
                setShowModal,
                t,
              })
            )
        }
      </Timeline>
    </>
  );
}

function Event({
  firestoreTimestamp,
  description,
  showViewEditLink,
  showConnector = true,
  by,
  key,
  coworkerId,
  setModal,
  setShowModal,
  t, //translate
}) {
  return (
    <TimelineItem data-cy={key} key={key}>
      <TimelineSeparator>
        <TimelineDot />
        {showConnector && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <div>{description}</div>
        <small className="text-secondary">
          {firestoreTimestamp
            ? firestoreTimestamp.toDate().toLocaleDateString("en-US")
            : ""}{" "}
          {by}
        </small>
        {showViewEditLink ? (
          <>
            <small className="ml-2">
              <LinkButton
                data-cy="view-edit-link-invite"
                onClick={() => {
                  setModal({
                    name: "invite",
                    props: {
                      initInviteMethod: "link",
                      coworkerId,
                      showChooseCoworker: false,
                    },
                  });
                  setShowModal(true);
                }}
              >
                {t("coworkerDetail.history.viewEdit")}
              </LinkButton>
            </small>
          </>
        ) : null}
      </TimelineContent>
    </TimelineItem>
  );
}

export default CoworkerHistory;
