import { saveCoworker } from "./firestore";
import { serverTimestamp } from "firebase/firestore";
/*
  Utilities that expose the basic Intercom widget functions - hide and show.
*/

/* Show the Intercom widget
 */
function showIntercom() {
  window.Intercom("show");
}

/* Hide the Intercom widget
 */
function hideIntercom() {
  window.Intercom("hide");
}

function showIntercomLauncher() {
  window.Intercom("update", {
    hide_default_launcher: false,
  });
}

function hideIntercomLauncher() {
  window.Intercom("update", {
    hide_default_launcher: true,
  });
}

function trackIntercomEvent(event) {
  window.Intercom("trackEvent", event);
}

// shows the tour via intercom and marks it as viewed in firestore
// if `showOnlyIfUnviewed` is true then the tour has to be unseen (according to firestore) to be shown
function showTour({ tourId, user, unit, showOnlyIfUnviewed = false }) {
  tourId = "" + tourId; // defend against getting passed integer tourId
  const hasSeen = !!(user?.viewedTours && user.viewedTours[tourId]);
  if (showOnlyIfUnviewed && hasSeen) {
    return;
  }

  window.Intercom("startTour", tourId);

  // record the tour as viewed in firestore provided we have all the information
  // by filtering out firestore changes to tours we've seen the timestamp will be the first time the tour was seen
  // alternatively, we could track the last time it was seen by removing `!hasSeen`, but it'll use more firestore resources for not much gain
  if (user && unit && user.id && tourId && !hasSeen) {
    const coworkerData = { viewedTours: {} };
    coworkerData.viewedTours[tourId] = serverTimestamp();
    saveCoworker({
      coworkerData,
      coworkerId: user.id,
      updatedByUid: user.uid,
      unitId: unit.id,
    });
  }
}

export {
  showIntercom,
  hideIntercom,
  showIntercomLauncher,
  hideIntercomLauncher,
  trackIntercomEvent,
  showTour,
};
