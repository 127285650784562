import { useState } from "react";
import ArrowIcon from "../icons/ArrowIcon";
import { useTranslation } from "react-i18next";
import "./TableHeaders.scss";

function TableHeaders({ headerGroups }) {
  return (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => (
            <TableHeader column={column} key={`header-${column.id}`} />
          ))}
        </tr>
      ))}
    </thead>
  );
}

function TableHeader({ column, ...props }) {
  const { t } = useTranslation();

  const [isHovering, setIsHovering] = useState(false);
  return (
    <th
      {...column.getHeaderProps(column.getSortByToggleProps())}
      data-cy={"column-header-" + column.id}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      title={column.canSort ? t("coworkers.table.sortHover") : ""}
    >
      <div className="d-flex flex-row">
        <span className="header-label">{column.render("Header")}</span>

        {/* Icons to indicate sort. If the column is sorted, the first arrow shows the sort
        direction in blue and the second arrow is invisible (but still in the dom to keep the
        columns from resizing on sort). If it's not sorted, but is sortable, show both arrows on 
        hover */}
        <span>
          <ArrowIcon
            className="inline-icon ml-2"
            color={column.isSorted ? "blue" : "grey"}
            direction={column.isSortedDesc ? "down" : "up"}
            show={
              (isHovering && column.canSort && !column.isSorted) ||
              column.isSorted
            }
          />
          <ArrowIcon
            className="inline-icon"
            color="grey"
            direction="down"
            show={isHovering && column.canSort && !column.isSorted}
          />
        </span>
      </div>
    </th>
  );
}

export default TableHeaders;
