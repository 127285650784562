import { useHistory } from "react-router-dom";
import AuthCardForm from "../AuthCardForm";
import { joinUnitUsingInvite } from "../../utilities/firestore";
import firebase from "firebase/compat/app";
import { sendEmailVerificationIfNeeded } from "../../utilities/auth";

function AuthCardJoin({ unit, user, invite, setIsWrongPerson }) {
  const history = useHistory();
  async function onSubmit(data) {
    await joinUnitUsingInvite({
      uid: user.uid,
      invite,
      unitId: unit.id,
      coworkerData: {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        mobilePhone: data.mobilePhone,
        jobTitle: data.jobTitle,
        city: data.city,
        state: data.state,
      },
      authCardData: data,
    });
    sendEmailVerificationIfNeeded();
    firebase.analytics().logEvent("joined_unit");
    history.push("/memberhome");
  }

  return (
    <>
      <AuthCardForm
        unit={unit}
        user={user}
        invite={invite}
        setIsWrongPerson={setIsWrongPerson}
        onSubmit={onSubmit}
      />
    </>
  );
}

export default AuthCardJoin;
