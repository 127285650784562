import useFirestoreDoc from "./useFirestoreDoc";

/* Hook to subscribe to the /ocOnly subdocument of a coworker
 * @param coworkerId
 * @param unitId
 * @returns [coworker, loading, error]
 *   coworker: contains the contents of the document if one exists, or null
 *   loading: bool
 *   error: FirebaseError or NonexistentDocumentError
 */
export default function useCoworkerOcOnly({ coworkerId, unitId }) {
  const [coworker, loading, error] = useFirestoreDoc(
    coworkerId && unitId
      ? `units/${unitId}/coworkers/${coworkerId}/private/ocOnly`
      : null
  );

  return [coworker, loading, error];
}
