import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import Tooltip from "../../components/Tooltip";
import { ModalContext } from "../../contexts/modalContext";
import { useContext } from "react";

function InviteButton({
  show = true,
  disabled = false,
  coworkerId,
  showChooseCoworker = false,
  onClick, // By default the component opens the invite modal, but can be overridden
  children, // Component has default text but can be overwritten
  ...props
}) {
  const { setModal, setShowModal } = useContext(ModalContext);
  const { t } = useTranslation();

  if (!show) {
    return null;
  }

  if (!onClick) {
    onClick = () => {
      setModal({
        name: "invite",
        props: { coworkerId, showChooseCoworker },
      });
      setShowModal(true);
    };
  }

  return (
    <Tooltip show={disabled} contents={t("coworkerDetail.onlySupporters")}>
      {/* Tooltip won't fire on a disabled button so wrap in a span */}
      <span className="d-inline-block">
        <Button
          className="m-2"
          onClick={onClick}
          variant="primary"
          disabled={disabled}
          style={disabled ? { pointerEvents: "none" } : {}}
          data-cy="invite-coworker-button"
          {...props}
        >
          {children || t("inviteAction")}
        </Button>
      </span>
    </Tooltip>
  );
}

export default InviteButton;
