import useFirestoreQuery from "./useFirestoreQuery";
import { useState } from "react";

function useMemberCoworkers({ unitId }) {
  const [queries] = useState([
    ["memberUid", "!=", ""], // Firestore's != only returns documents where the key is present
    ["isDeleted", "==", false]
  ]);
  
  const [data, loading, error] = useFirestoreQuery(
    `units/${unitId}/coworkers`,
    queries,
    !unitId
  );

  return [data, loading, error];
}

export default useMemberCoworkers;
