import useLocale from "../hooks/useLocale";
import GlobeChatIcon from "./icons/GlobeChatIcon";
import NavDropdown from "react-bootstrap/NavDropdown";
import i18n from "i18next";

function LanguageSwitcher() {
  const setLang = useLocale();

  const languages = new Map([
    ["en", "English"],
    ["es", "Español"],
  ]);

  return (
    <NavDropdown
      alignRight
      title={
        <>
          <GlobeChatIcon className="inline-icon mb-1 mr-1" />
          {languages.get(i18n.language)}
        </>
      }
      id="language-dropdown"
    >
      {[...languages.keys()].map((key) => (
        <NavDropdown.Item
          className="dropdown-item"
          onClick={() => setLang(key)}
          key={key}
        >
          {languages.get(key)}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );
}

export default LanguageSwitcher;
