import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

// shows an invite url in a readonly text field
// - provides a Copy button to put the data on clipboard
// - conditionally provides a Share button with url and text from shareText
function InviteUrl({ inviteUrl, shareText }) {
  const { t } = useTranslation();

  return (
    <>
      <input
        type="text"
        id="link"
        data-cy="link-input"
        className="form-control mt-2"
        readOnly={true}
        onFocus={(e) => e.target.select()}
        value={inviteUrl}
      />
      <Button
        className="my-3 ml-3"
        data-cy="copy-to-clipboard"
        onClick={() => navigator.clipboard.writeText(inviteUrl)}
        variant={navigator.share ? "secondary" : "primary"}
      >
        {t("invite.copy")}
      </Button>
      {navigator.share ? (
        <Button
          className="m-3"
          data-cy="share-button"
          onClick={() =>
            navigator.share({
              text: shareText,
              url: inviteUrl,
            })
          }
        >
          {t("invite.share")}
        </Button>
      ) : null}
    </>
  );
}

export default InviteUrl;
