import Button from "react-bootstrap/Button";
import { ModalContext } from "../../../contexts/modalContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import InviteButton from "../../../components/buttonsAndLinks/InviteButton";

function CoworkerDetailButtons({
  showDelete = true,
  showInvite = true,
  showRescindCard = false,
  showUpdateOcMembership = false,
  coworker = {},
}) {
  const { setModal, setShowModal } = useContext(ModalContext);
  const { t } = useTranslation();

  return (
    <>
      <InviteButton show={showInvite} coworkerId={coworker.id} />

      {showDelete ? (
        <Button
          className="m-2"
          onClick={() => {
            setModal({ name: "delete", props: { coworkerId: coworker.id } });
            setShowModal(true);
          }}
          variant="outline-secondary"
          data-cy="delete-coworker-button"
        >
          {t("coworkerDetail.delete")}
        </Button>
      ) : null}

      {showRescindCard ? (
        <Button
          className="m-2"
          variant="danger"
          onClick={() => {
            setModal({
              name: "leaveUnit",
              props: { memberUid: coworker.memberUid, coworkerId: coworker.id },
            });
            setShowModal(true);
          }}
          data-cy="rescind-card-button"
        >
          Rescind card
        </Button>
      ) : null}

      {showUpdateOcMembership ? (
        <Button
          className="m-2"
          variant="warning"
          data-cy="update-oc-button"
          onClick={() => {
            setModal({
              name: "updateOcMembership",
              props: { coworker, addToOc: !coworker.isOcMember },
            });
            setShowModal(true);
          }}
        >
          {coworker.isOcMember ? "Remove from OC" : "Add to OC"}
        </Button>
      ) : null}
    </>
  );
}

export default CoworkerDetailButtons;
