import { useContext, useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { UserUnitContext } from "../../contexts/userUnitContext";
import useInvite from "../../hooks/useInvite";
import useUnit from "../../hooks/useUnit";
import PageLayout from "../../components/PageLayout";
import JoinLayout from "../../components/join/JoinLayout";
import JoinOcIntro from "./components/JoinOcIntro";
import Login from "../../components/Login";
import { useTranslation } from "react-i18next";
import JoinOcAuthCard from "./components/JoinOcAuthCard";
import Spinner from "../../components/Spinner";
import JoinOcWrongUnit from "./components/JoinOcWrongUnit";
import { joinOcAsExistingMember } from "../../utilities/firestore";
import firebase from "firebase/compat/app";

function JoinOcPage({ ready = false }) {
  const { t } = useTranslation();
  const [isWrongPerson, setIsWrongPerson] = useState(false);
  const [isProcessingJoin, setIsProcessingJoin] = useState(false);
  const { inviteId } = useParams();
  const history = useHistory();
  const [invite, inviteLoading, inviteError] = useInvite(inviteId, true);
  const [unit, unitLoading, unitError] = useUnit(invite?.unitId);
  const {
    user,
    unit: existingUnit,
    loading: userLoading,
  } = useContext(UserUnitContext);

  const error = inviteError || unitError;
  const loading =
    inviteLoading || unitLoading || userLoading || isProcessingJoin;

  const readyPath = `/jointheoc/${inviteId}/ready`;

  // Automatically join the OC for ready signers with a matching OC invite
  useEffect(() => {
    async function doEffect() {
      if (
        !ready ||
        !unit ||
        !existingUnit ||
        !invite ||
        !user ||
        unit.id !== existingUnit.id ||
        isProcessingJoin
      ) {
        return;
      }

      if (user.id !== invite?.invitee?.coworkerId) {
        setIsWrongPerson(true);
        return;
      }
      setIsProcessingJoin(true);
      // Once they get this far we can update isOcMember: true on coworker and membership
      await joinOcAsExistingMember({
        unitId: unit.id,
        coworkerId: user.id,
        ocInviteId: invite.id,
        memberUid: user.memberUid,
        updatedByUid: user.memberUid,
        usedByUid: user.memberUid,
      });
      // Note updatedByUid value here is tricky. for regular invite flow we use: invite.createdByUid
      // but here we have a logged in user clicking the Ready to Join OC button and updating their account
      firebase.analytics().logEvent("joined_oc_as_signer");
      history.push("/ochome");
    }

    doEffect();
  }, [unit, existingUnit, ready, user, invite, history, isProcessingJoin]);

  let contents;
  if (!ready) {
    contents = (
      <JoinOcIntro
        invite={invite}
        unit={unit}
        setIsWrongPerson={setIsWrongPerson}
        setReadyToJoin={() => {
          history.push(readyPath);
        }}
      />
    );
  } else if (!user) {
    contents = (
      <Login
        showLogo={false}
        welcomeHeader={t("joinOc.login.welcomeHeader")}
        welcomeText={t("joinOc.login.welcomeText")}
        loginRedirectPath={readyPath}
      />
    );
  } else if (existingUnit && unit) {
    if (unit.id !== existingUnit.id) {
      contents = (
        <JoinOcWrongUnit firstName={user.firstName} unitName={unit.unitName} />
      );
    } else {
      contents = <Spinner />; // join in process, should resolve to error or redirect
    }
  } else if (invite && user && unit) {
    contents = (
      <JoinOcAuthCard
        invite={invite}
        user={user}
        unit={unit}
        setIsWrongPerson={setIsWrongPerson}
      />
    );
  } else {
    contents = <Spinner />; // unitLoading cannot be trusted atm TODO figure out why
  }

  return (
    <PageLayout showNav={false} pageTitle={"Join the OC"}>
      <JoinLayout
        unit={unit}
        error={error}
        loading={loading}
        isWrongPerson={isWrongPerson}
      >
        {contents}
      </JoinLayout>
    </PageLayout>
  );
}

export default JoinOcPage;
