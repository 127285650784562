import { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import { UserUnitContext } from "../../../contexts/userUnitContext";

function ActAsUid({ onClickActAs }) {
  const { user, error } = useContext(UserUnitContext);

  const [newUid, setNewUid] = useState(null);

  const actAs = (uid) => {
    onClickActAs(uid);
    // rather than deal with tricky reloading of controlled users
    // just force a page refresh, only admins see this anyway
    window.location.reload(false);
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>Act as UID</Card.Title>
        <Card.Text data-cy="current-uid">
          <span className="semibold">Current UID:</span> {user.uid}
          <br />
          <span className="semibold">CoworkerId:</span> {user.id}
          <br />
          <span className="semibold">Name:</span> {user.firstName}{" "}
          {user.lastName}
        </Card.Text>
        <Form.Group>
          <Form.Label>UID</Form.Label>
          <Form.Control
            data-cy="act-as-uid-input"
            onChange={(event) => setNewUid(event.target.value)}
          />
        </Form.Group>
        <Button
          data-cy="act-as-uid-button"
          className="mr-2"
          onClick={() => actAs(newUid)}
        >
          Act as!
        </Button>
        <Button
          data-cy="clear-uid-button"
          className="mr-2"
          onClick={() => actAs(undefined)}
        >
          Clear!
        </Button>
      </Card.Body>

      <Alert className="mx-3" show={!!error} variant="danger">
        ERROR: {JSON.stringify(error)}
      </Alert>
    </Card>
  );
}

export default ActAsUid;
