import useFirestoreQuery from "./useFirestoreQuery";
import { useEffect, useState } from "react";

// OC Members can see each other's OC invites (no filter on createdByUid)
function useOcInviteByCoworkerId({ unitId, coworkerId }) {
  const [queries, setQueries] = useState([
    ["unitId", "==", unitId],
    ["invitee.coworkerId", "==", coworkerId],
  ]);
  const [queryLoading, setQueryLoading] = useState(true);

  useEffect(() => {
    setQueries([
      ["unitId", "==", unitId],
      ["invitee.coworkerId", "==", coworkerId],
    ]);
    setQueryLoading(!unitId || !coworkerId);
  }, [unitId, coworkerId]);

  const [data, loading, error] = useFirestoreQuery(
    "ocInvites",
    queries,
    queryLoading
  );

  // We do not expect to get more than one unused unexpired link here
  const usable = data.filter((invite) => !invite.used && !invite.expired);
  const invite = usable.length ? usable[0] : null;
  return [invite, loading, error];
}

export default useOcInviteByCoworkerId;
