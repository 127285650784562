import { useContext, useState } from "react";
import PageLayout from "../../components/PageLayout";
import { UserUnitContext } from "../../contexts/userUnitContext";
import SignaturesTable from "./components/SignaturesTable";
import { useHistory } from "react-router-dom";
import Error from "../../components/Error";
import Spinner from "../../components/Spinner";
import useMemberCoworkers from "../../hooks/useMemberCoworkers";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import SearchInput from "../../components/SearchInput";
import EmailLink from "../../components/buttonsAndLinks/EmailLink";
import InviteButton from "../../components/buttonsAndLinks/InviteButton";
import NewMemberAlert from "./components/NewSignerAlert";
import { useTranslation } from "react-i18next";
import PrivacyDetail from "../../components/PrivacyDetail";

function SignaturesPage() {
  const { user, unit } = useContext(UserUnitContext);
  const { t } = useTranslation();

  return (
    <PageLayout pageTitle={t("pageName.signatures")} requireVerifiedEmail>
      <SignaturesPageContents unit={unit} user={user} />
    </PageLayout>
  );
}

function SignaturesPageContents({ unit, user }) {
  const { t } = useTranslation();
  const [members, loading, error] = useMemberCoworkers({ unitId: unit?.id });
  const history = useHistory();
  const [filterText, setFilterText] = useState("");
  const [emails, setEmails] = useState([]);

  const onClickMember = (member) => {
    if (member.id) {
      history.push("/coworker/" + member.id, { from: "/signatures" });
    }
  };

  if (loading) return <Spinner />;
  if (error) return <Error error={error} />;
  if (!members || members.length === 0) {
    return (
      <Error
        error={{ name: "NoMemberError" }}
        displayText={t("signatures.error")}
      />
    );
  }

  return (
    <>
      <h3>
        {t("signatures.title")}{" "}
        <span className={`text-secondary${!members?.length ? " d-none" : ""}`}>
          ({members.length})
        </span>
      </h3>

      <PrivacyDetail
        contents={t("privacy.signatures")}
        placement="bottom"
        triggerText={t("privacy.whoCanSee.signatures")}
      />

      <NewMemberAlert
        className="m-4"
        data-cy="new-member-alert"
        members={members}
        currentUserUid={user.uid}
      />

      <Row className="my-4">
        <Col xs={12} md={6}>
          <SearchInput
            className="my-3"
            placeholder={t("signatures.search")}
            setFilterText={setFilterText}
          />
        </Col>
        <Col xs={12} md={6} className="align-self-center">
          <EmailLink
            disabled={emails.length === 0}
            emails={emails}
            asButton
            data-cy="email-members"
          >
            {t("signatures.email")}
          </EmailLink>

          <InviteButton showChooseCoworker />
        </Col>
      </Row>
      <Row>
        <SignaturesTable
          members={members}
          onClickMember={onClickMember}
          setEmails={setEmails}
          filterText={filterText}
        />
      </Row>
    </>
  );
}

export default SignaturesPage;
