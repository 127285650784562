import BsToast from "react-bootstrap/Toast";
import { useContext } from "react";
import { ToastContext } from "../contexts/toastContext";

function Toast() {
  const { showToast, setShowToast, toastProps } = useContext(ToastContext);
  const { header, body, ...props } = toastProps;

  if (!body && !header) return null;

  return (
    <BsToast
      onClose={() => setShowToast(false)}
      show={showToast}
      delay={3000}
      autohide
      {...props}
    >
      {header ? <BsToast.Header>{header}</BsToast.Header> : null}
      {body ? <BsToast.Body>{body}</BsToast.Body> : null}
    </BsToast>
  );
}

export default Toast;
