import { useEffect, useState } from "react";

/**
 * Behaves like useState, except it takes a localStorageKey
 * which is used to persist the state in local storage
 * and JSON stringifies and decodes so that objects can be stored in local storage
 *
 * Also listens to changes to local storage that may occur in other windows or tabs
 * @param localStorageKey - unique key for persistence in localStorage
 * @param initValue - string value to return if there is nothing yet persisted in localStorage
 * @returns {String} resolves to the value stored in localStorage(localStorageKey). Otherwise resolves to initValue
 */
function useStateWithLocalStorage(localStorageKey, initValue) {
  let existingValue;
  try {
    existingValue = JSON.parse(localStorage.getItem(localStorageKey));
  } catch {
    existingValue = undefined;
  }
  const [value, setValue] = useState(existingValue || initValue);

  // Save the stringified value to local storage when it changes
  useEffect(() => {
    const strValue = JSON.stringify(value);
    localStorage.setItem(localStorageKey, strValue);
  }, [value, localStorageKey]);

  // Listen for changes in the value from a different tab
  useEffect(() => {
    function handleStorageChange(event) {
      if (event.key === localStorageKey) {
        let newValue;
        try {
          newValue = JSON.parse(event.newValue);
        } catch {
          newValue = undefined;
        }
        if (newValue !== value) {
          setValue(newValue);
        }
      }
    }
    window.addEventListener("storage", handleStorageChange);
    // stop listening on remove
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [localStorageKey, value]);

  return [value, setValue];
}

export default useStateWithLocalStorage;
