import UnitNameLogo from "./UnitNameLogo";
import "./Error.scss";
import ContactUnitLink from "./buttonsAndLinks/ContactUnitLink";
import firebase from "firebase/compat/app";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

function Error({ showOops = true, error, displayText, ...props }) {
  const { t } = useTranslation();

  useEffect(() => {
    firebase.analytics().logEvent("app_error", {
      error_message: error.message ?? displayText,
      error_name: error.name ?? "UnspecifiedErrorName",
    });
  }, [error, displayText]);

  return (
    <div className="error-container" data-cy="error" {...props}>
      {showOops ? (
        <h1>
          <UnitNameLogo text={t("oops")}></UnitNameLogo>
        </h1>
      ) : null}
      <div className="my-4">{displayText || t("defaultError")}</div>
      <ContactUnitLink />
    </div>
  );
}

export default Error;
