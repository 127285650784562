import FormFields from "../../components/FormFields";
import InviteOnlyOnce from "./InviteOnlyOnce";
import { useTranslation } from "react-i18next";

function InviteByLink({ control, errors, firstName, unitName, oc = false }) {
  const { t } = useTranslation();

  const fields = [
    {
      name: "customMessage",
      type: "textarea",
      rows: 4,
      placeholder: t("invite.addCustomMessage"),
    },
  ];
  return (
    <>
      <p>{t(`${oc ? "inviteOc" : "invite"}.createLink`, { firstName })}</p>
      <p>
        {t(`${oc ? "inviteOc" : "invite"}.smsDescription2`, {
          firstName,
          unitName,
        })}
      </p>
      <FormFields fields={fields} control={control} errors={errors} />
      <InviteOnlyOnce firstName={firstName} />
    </>
  );
}

export default InviteByLink;
