import UnitNameLogo from "../../../components/UnitNameLogo";

function CreateLayout({ unit, children, ...props }) {
  return (
    <div className="unit-creation-container" {...props}>
      {unit?.unitName ? (
        <div className="unit-creation-header mt-2 mb-4">
          <UnitNameLogo text={unit?.unitName} />
        </div>
      ) : null}
      {children}
    </div>
  );
}

export default CreateLayout;
