import firebase from "firebase/compat/app";
import "firebase/compat/auth";

function logOut() {
  firebase.auth().signOut();
}

// returns true if password is valid for the currently logged in user
// https://stackoverflow.com/a/41111268
async function validatePassword(password) {
  const authUser = firebase.auth().currentUser;
  var credential = firebase.auth.EmailAuthProvider.credential(
    authUser.email,
    password
  );

  try {
    await authUser.reauthenticateWithCredential(credential);
    return true;
  } catch (err) {
    return false;
  }
}

// sends verification email to the current user unless their email address ends in "@fake.email"
function sendEmailVerification() {
  // defend against sending real verification emails to our test users
  const email = firebase.auth().currentUser.email;
  if (!email) return;
  if (email.endsWith("@fake.email")) {
    console.log(`abort verifying ${email}`);
    return;
  }
  firebase.auth().currentUser.sendEmailVerification();
}

// sends verification email to the current user but only if they're currently unverified
function sendEmailVerificationIfNeeded() {
  if (!firebase.auth().currentUser.emailVerified) {
    sendEmailVerification();
    firebase.analytics().logEvent("sent_email_verification_automatic");
  }
}

export {
  logOut,
  sendEmailVerification,
  sendEmailVerificationIfNeeded,
  validatePassword,
};
