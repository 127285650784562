import React from "react";
import ContactUnitLink from "../../../components/buttonsAndLinks/ContactUnitLink";
import "./WizardStep.scss";
import firebase from "firebase/compat/app";
import { useEffect } from "react";
import OutboundLink from "../../../components/buttonsAndLinks/OutboundLink";
import WizardStep from "./WizardStep";
import AcEligibilityFailureForm from "./AcEligibilityFailureForm";
import { useTranslation } from "react-i18next";

const WizardError = ({
  headerText,
  subText,
  backButton,
  failedStepName,
  showForm = true,
  showLinks = true,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    firebase
      .analytics()
      .logEvent("eligibility_failure", { failed_step: failedStepName });
  }, [failedStepName]);

  const contactText = t("getStarted.error.contact");

  const links = (
    <>
      <div className="my-3 p-3 d-flex flex-column">
        <span className="semibold">{t("getStarted.learnMore")}</span>
        <OutboundLink
          href={`${process.env.REACT_APP_knowledgeBaseHostname}/union-eligibility/`}
        >
          {t("getStarted.error.eligibilityLink")}
        </OutboundLink>
        <OutboundLink
          href={`${process.env.REACT_APP_knowledgeBaseHostname}/understanding-eligibility-history-why-certain-workers-were-excluded-from-the-nlra/`}
        >
          {t("getStarted.error.historyLink")}
        </OutboundLink>
      </div>
    </>
  );

  return (
    <WizardStep
      headerText={headerText || t("getStarted.error.header")}
      subText={
        <>
          {subText}

          {showForm && (
            <>
              {" "}
              {contactText}
              <AcEligibilityFailureForm />
            </>
          )}

          {showLinks && links}
        </>
      }
      backButton={backButton}
      post={<ContactUnitLink />}
    ></WizardStep>
  );
};

export default WizardError;
