import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import useAuthUser from "../../../hooks/useAuthUser";
import { createUnit } from "../../../utilities/firestore";
import firebase from "firebase/compat/app";
import { useEffect } from "react";
import UnitFormFields from "../../../components/UnitFormFields";
import { useTranslation } from "react-i18next";
import PrivacyDetail from "../../../components/PrivacyDetail";

function NewUnionSetup({ localStorageUnitInfo }) {
  const { t } = useTranslation();
  const [user] = useAuthUser();
  const { handleSubmit, formState, control, errors } = useForm();
  const [isSavingUnit, setIsSavingUnit] = useState(false);

  useEffect(() => {
    firebase.analytics().logEvent("new_union_setup");
  }, []);

  async function onSubmit(data) {
    setIsSavingUnit(true);
    const {
      industry,
      state,
      isRemote,
      numNonManagerEmployees,
      companyName,
      hearAboutUs,
      ...everythingElse
    } = data;
    const unitData = {
      companyName,
      industry: industry[0], // typeahead component always returns array
      numNonManagerEmployees: parseInt(numNonManagerEmployees),
      createdByUid: user.uid,
      hearAboutUs,
      workSite: {
        state: state,
        isRemote: !!isRemote, // otherwise we write "" instead of false to firestore
        ...everythingElse, // address, address2, city, zip
      },
    };
    await createUnit(unitData);
    firebase.analytics().logEvent("unit_created");
    setIsSavingUnit(false);
  }

  return (
    <>
      <h1>{t("pageName.createUnit")}</h1>
      <UnitFormFields
        control={control}
        errors={errors}
        defaults={{
          numNonManagerEmployees: localStorageUnitInfo?.numNonManagerEmployees,
        }}
      />

      <div className="d-flex align-items-center mt-4">
        <Button
          disabled={formState.isSubmitting || isSavingUnit}
          data-cy="create-your-union-button"
          onClick={handleSubmit(onSubmit)}
        >
          {t("createUnit.submit")}
        </Button>
        <PrivacyDetail className="ml-3" contents={t("privacy.newUnionSetup")} />
      </div>
    </>
  );
}

export default NewUnionSetup;
