import { useTranslation } from "react-i18next";
import FormFields from "../../components/FormFields";

// the FormField for a coworkers contact info, shared between OC and non-OC
function AddCoworkerContactFields({ control, errors }) {
  const { t } = useTranslation();

  const contactInfoFields = [
    {
      name: "firstName",
      label: t("fieldName.firstName"),
      type: "text",
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.firstName"),
        }),
      },
    },
    {
      name: "lastName",
      label: t("fieldName.lastName"),
      type: "text",
    },
    // TODO: Ideally dept and job title would be more standard than free text
    // https://www.notion.so/Department-and-job-title-fields-should-be-standardized-1de3afeb9e7a43c69476a32b33a779fe
    {
      name: "department",
      label: t("fieldName.department"),
      type: "text",
    },
    {
      name: "jobTitle",
      label: t("fieldName.jobTitle"),
      type: "text",
    },
    {
      name: "email",
      label: t("fieldName.email"),
      type: "email",
    },
    {
      name: "mobilePhone",
      label: t("fieldName.mobilePhone"),
      type: "phone",
    },
  ];

  return (
    <>
      <div className="semibold">{t("contactInfoTitle")}</div>
      <FormFields
        className="m-2"
        fields={contactInfoFields}
        control={control}
        errors={errors}
      />
    </>
  );
}

export default AddCoworkerContactFields;
