import { useHistory } from "react-router-dom";
import firebase from "firebase/compat/app";
import AuthCardForm from "../../../components/AuthCardForm";
import { sendEmailVerificationIfNeeded } from "../../../utilities/auth";
import { joinUnitUsingInvite } from "../../../utilities/firestore";

function JoinOcAuthCard({ invite, user, unit, setIsWrongPerson }) {
  const history = useHistory();
  async function onSubmit(data) {
    await joinUnitUsingInvite({
      uid: user.uid,
      invite,
      unitId: unit.id,
      coworkerData: {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        mobilePhone: data.mobilePhone,
        jobTitle: data.jobTitle,
        city: data.city,
        state: data.state,
      },
      authCardData: data,
      oc: true,
    });
    sendEmailVerificationIfNeeded();
    firebase.analytics().logEvent("joined_unit_oc");
    history.push("/ochome");
  }

  return (
    <AuthCardForm
      oc={true}
      unit={unit}
      user={user}
      invite={invite}
      setIsWrongPerson={setIsWrongPerson}
      onSubmit={onSubmit}
    />
  );
}

export default JoinOcAuthCard;
