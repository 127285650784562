import useFirestoreDoc from "./useFirestoreDoc";

/* Hook to subscribe to an advisor document
 * @param advisorId
 * @returns [advisor, loading, error]
 *   advisor: contains the contents of the document if one exists, or null
 *   loading: bool
 *   error: FirebaseError or NonexistentDocumentError
 */
export default function useAdvisor(advisorId) {
  const [advisor, loading, error] = useFirestoreDoc(
    advisorId ? `advisors/${advisorId}` : null
  );

  return [advisor, loading, error];
}
