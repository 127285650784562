import colors from "../../styles/colors.module.scss";

function LockIcon({ size = 24, color, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      style={{
        color: color ? colors[color] : "currentColor",
        fill: "none",
        stroke: "currentColor",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "1.5px",
      }}
      {...props}
    >
      <g transform="matrix(1,0,0,1,0,0)">
        <title>Lock icon</title>
        <rect
          className="a"
          x="3.75"
          y="9.75"
          width="16.5"
          height="13.5"
          rx="1.5"
          ry="1.5"
        ></rect>
        <path className="a" d="M6.75,9.75V6a5.25,5.25,0,0,1,10.5,0V9.75"></path>
        <path
          className="a"
          d="M12,15.75a.375.375,0,1,0,.375.375A.374.374,0,0,0,12,15.75h0"
        ></path>
      </g>
    </svg>
  );
}

export default LockIcon;
