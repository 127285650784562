import { useContext } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { FirebaseContext } from "../contexts/firebaseContext";
import colors from "../styles/colors.module.scss";
import { useTranslation } from "react-i18next";
import "./AuthWidget.scss";
import "../styles/firebaseui-styling.global.css";

function AuthWidget({ loginRedirectPath = "/", className, ...props }) {
  const firebase = useContext(FirebaseContext);
  const { t } = useTranslation();

  // Configure FirebaseUI.
  const firebaseLoginUiConfig = {
    // Display Google and Facebook as auth providers.
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        fullLabel: t("account.loginGoogle"),
      },
      {
        provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        fullLabel: t("account.loginFb"),
      },
      {
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        buttonColor: colors.grey,
        fullLabel: t("account.loginEmail"),
        requireDisplayName: false,
      },
    ],
    tosUrl: process.env.REACT_APP_staticHostname + "/terms",
    privacyPolicyUrl: process.env.REACT_APP_staticHostname + "/privacy",
    signInSuccessUrl: `${window.location.origin}${loginRedirectPath}`,
    callbacks: {
      // Event looks like login_createunit, login_home, etc
      signInSuccessWithAuthResult: () => {
        firebase
          .analytics()
          .logEvent(`login${loginRedirectPath.replace("/", "_")}`);
        return true;
      },
    },
  };

  return (
    <div className={className}>
      <p className="privacy-warning">{t("account.privacyWarning")}</p>
      <StyledFirebaseAuth
        {...props}
        uiConfig={firebaseLoginUiConfig}
        firebaseAuth={firebase.auth()}
        uiCallback={(ui) => ui.disableAutoSignIn()}
      />
    </div>
  );
}

export default AuthWidget;
