// for each question which answer and above will trigger abort
const wizardAbortAt = {
  supportRating: 3,
  understand: 2,
  warned: 3,
};

const wizardOptions = {
  supportRating: ["1", "2", "3", "4", "5"],
  understand: ["1", "2"],
  warned: ["1", "2", "3", "4"],
};

// prompt the inviter to get more OC members unless they have more than this number
const UPSELL_OC_AT = 1;

export { wizardAbortAt, wizardOptions, UPSELL_OC_AT };
