import EmailLink from "../../../components/buttonsAndLinks/EmailLink";
import PhoneLink from "../../../components/buttonsAndLinks/PhoneLink";
import ContactUnitLink from "../../../components/buttonsAndLinks/ContactUnitLink";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import OutboundLink from "../../../components/buttonsAndLinks/OutboundLink";
import { useTranslation, Trans } from "react-i18next";

function AdvisorCard({ advisor }) {
  const { t } = useTranslation();

  // Card className is used by Intercom tour -- careful if changing!
  return (
    <Card data-cy="advisor" className="home-page-advisor">
      <Card.Body>
        <Card.Title>{t("home.advisor.titleAnon")}</Card.Title>

        <p>
          {advisor ? (
            <Trans
              i18nKey="home.advisor.explanatoryText"
              components={[<span className="semibold"></span>]}
              values={{ name: `${advisor.firstName} ${advisor.lastName}` }}
            />
          ) : (
            t("home.advisor.explanatoryTextAnon")
          )}
        </p>

        {advisor?.mobilePhone ? (
          <>
            <dt>{t("fieldName.mobilePhone")}:</dt>
            <dd>
              <PhoneLink
                data-cy="advisor-phone"
                phoneNumber={advisor.mobilePhone}
              />
            </dd>
          </>
        ) : (
          <>
            <dt>{t("home.advisor.chat")}:</dt>
            <dd>
              <ContactUnitLink inline>Click to chat</ContactUnitLink>
            </dd>
          </>
        )}

        <dt>{t("fieldName.email")}:</dt>
        <dd>
          <EmailLink
            data-cy="advisor-email"
            emails={advisor?.email ? [advisor.email] : ["info@unitworkers.com"]}
          />
        </dd>

        {advisor?.schedulingLink && (
          <>
            <OutboundLink
              data-cy="advisor-scheduling-link"
              href={advisor.schedulingLink}
            >
              <Button size="sm" className="mt-2">
                {t("home.advisor.schedule")}
              </Button>
            </OutboundLink>
          </>
        )}
      </Card.Body>
    </Card>
  );
}

export default AdvisorCard;
