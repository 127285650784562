import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Typeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import "./TagSelect.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  getNextTagId,
  getNextTagColor,
  MAX_TAG_LENGTH,
} from "../../utilities/tags";
import { saveTagsOnUnit, saveTagsOnCoworker } from "../../utilities/firestore";

// this component lets the user:
// - search through tags
// - toggle whether a tag is active by clicking it
// - create a new tag with a random color from the current input
// - or signal to the parent modal to edit all tags for the unit
function TagSelect({
  unitTags,
  coworkerTags,
  coworker,
  unit,
  user,
  setShowEditTags,
  setShowSpinner,
}) {
  const { t } = useTranslation();
  const [textInput, setTextInput] = useState("");
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);
  const ref = useRef();

  async function handleSelect(s) {
    setShowSpinner(true);
    // toggle active flag on selected tag
    s[0].active = !s[0].active;
    setSelected([]);
    await saveTagsOnCoworker({
      coworkerId: coworker.id,
      unitId: unit.id,
      updatedByUid: user.uid,
      tagIds: options.filter((tag) => tag.active).map((tag) => tag.id),
    });
    setShowSpinner(false);
  }

  async function handleCreate() {
    setShowSpinner(true);

    options.push({
      id: getNextTagId(options),
      name: textInput,
      color: getNextTagColor(options),
      active: true,
    });

    // sort on the client so the tags are in order even before firestore sends the update
    const sorted = options.sort((a, b) => {
      // sort active tags to the top, then alphabetically
      if (b.active && !a.active) return 1;
      if (!b.active && a.active) return -1;
      return a.name.localeCompare(b.name);
    });
    setOptions(sorted);

    setSelected([]);
    // clear the text input both from the component and the Create tag label
    setTextInput("");
    ref.current.clear();

    // save the new tag both on the unit and on the cowoker that created it
    // TODO it might be nice to batch these in the future but the underlying methods make that a bit messy atm
    await saveTagsOnUnit({
      newTags: options,
      unitId: unit.id,
      updatedByUid: user.uid,
    });
    await saveTagsOnCoworker({
      tagIds: options.filter((tag) => tag.active).map((tag) => tag.id),
      coworkerId: coworker.id,
      unitId: unit.id,
      updatedByUid: user.uid,
    });
    setShowSpinner(false);
  }

  // pass up to parent that the modal should switch to Edit tags mode
  function handleEditTags() {
    setShowEditTags(true);
  }

  useEffect(() => {
    const coworkerTagsSet = new Set(coworkerTags[coworker.id] ?? []);
    const sorted = unitTags
      .map((tag) => {
        return { ...tag, active: coworkerTagsSet.has(tag.id) };
      })
      .sort((a, b) => {
        // sort active tags to the top, then alphabetically
        if (b.active && !a.active) return 1;
        if (!b.active && a.active) return -1;
        return a.name.localeCompare(b.name);
      });
    setOptions(sorted);
  }, [coworker, unitTags, coworkerTags, setOptions]);

  return (
    <>
      <Typeahead
        id="tags-input"
        className="tags-input"
        labelKey="name"
        placeholder={t("tags.placeholder")}
        clearButton
        autoFocus
        isLoading={false}
        defaultOpen={true}
        options={options}
        open={true}
        ref={ref}
        onInputChange={(val) => {
          // offer to make a new tag up based on input up to our max tag size
          val = val.substring(0, MAX_TAG_LENGTH);
          setTextInput(val);
        }}
        onChange={handleSelect}
        renderMenu={(results, menuProps) => (
          <TagSelectMenu
            results={results}
            menuProps={menuProps}
            t={t}
            textInput={textInput}
            handleCreate={handleCreate}
            options={options}
          />
        )}
        selected={selected}
      />
      <EditTags t={t} handleEditTags={handleEditTags} />
    </>
  );
}

function TagSelectMenu({
  results,
  menuProps,
  t,
  textInput,
  handleCreate,
  options,
}) {
  return (
    <Menu {...menuProps}>
      {results.map((result, index) => {
        return (
          <MenuItem
            key={result.id}
            disabled={result.disabled}
            option={result}
            position={index}
          >
            <TagSelectOption option={result} t={t} />
          </MenuItem>
        );
      })}

      <CreateNewTag
        t={t}
        textInput={textInput}
        handleCreate={handleCreate}
        options={options}
      />
    </Menu>
  );
}

function TagSelectOption({ option, t }) {
  return (
    <Row className="tag-option">
      <Col xs={1}>{option.active && "✓"}</Col>
      <Col xs={10}>
        <Color color={option.color} />
        {option.name}
      </Col>
      <Col xs={1}>{option.active && "×"}</Col>
    </Row>
  );
}

function Color({ color }) {
  return <div className="color mr-1" style={{ backgroundColor: color }}></div>;
}

function CreateNewTag({ textInput, t, handleCreate, options }) {
  // don't show create tag if there's nothing in the textbox
  // or if the value is already a perfect match for an existing tag
  if (!textInput) return null;
  if (options.some((tag) => tag.name === textInput)) return null;
  return (
    <button
      className="dropdown-button"
      data-cy="create-new-tag"
      onClick={handleCreate}
    >
      {t("tags.createTagFromInput", { textInput })}
    </button>
  );
}

function EditTags({ t, handleEditTags }) {
  return (
    <button
      className="dropdown-button"
      data-cy="edit-tags"
      onClick={handleEditTags}
    >
      {t("tags.editTags")}
    </button>
  );
}

export default TagSelect;
