import colors from "../../styles/colors.module.scss";

function InfoIcon({ color = "blue", ...props }) {
  return (
    <svg color={colors[color]} viewBox="0 0 24 24" {...props}>
      <title>Information icon</title>
      <g>
        <path
          fill="currentColor"
          d="M13.5,17.25c-1.241,0-2.25-1.009-2.25-2.25v-3.75H10.5c-0.414,0-0.75-0.336-0.75-0.75s0.336-0.75,0.75-0.75h0.75
		c0.827,0,1.5,0.673,1.5,1.5V15c0,0.414,0.336,0.75,0.75,0.75h0.75c0.414,0,0.75,0.336,0.75,0.75s-0.336,0.75-0.75,0.75H13.5z"
        />
        <circle fill="currentColor" cx="11.625" cy="7.125" r="1.125" />
        <path
          fill="currentColor"
          d="M12,24C5.383,24,0,18.617,0,12C0,5.383,5.383,0,12,0c6.617,0,12,5.383,12,12C24,18.617,18.617,24,12,24z M12,1.5
		C6.21,1.5,1.5,6.21,1.5,12c0,5.79,4.71,10.5,10.5,10.5c5.79,0,10.5-4.71,10.5-10.5C22.5,6.21,17.79,1.5,12,1.5z"
        />
      </g>
    </svg>
  );
}

export default InfoIcon;
