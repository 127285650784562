import useFirestoreDoc from "./useFirestoreDoc";
import { DEFAULT_TAGS } from "../utilities/tags";
import { useState, useEffect } from "react";

// Uses unitTags and coworkerTags from the /ocOnly subdocument of user's unit
// unitTags defaults to DEFAULT_TAGS if none are defined or the document doesn't exist
// https://www.youtube.com/watch?v=o7d5Zeic63s - video on art of organizing data in firestore
export default function useTags({ unitId, unitIdLoading }) {
  const [ocOnly, loading, error] = useFirestoreDoc(
    unitId ? `units/${unitId}/private/ocOnly` : null,
    unitIdLoading
  );
  const [unitTags, setUnitTags] = useState(DEFAULT_TAGS);
  const [coworkerTags, setCoworkerTags] = useState({});

  useEffect(() => {
    if (ocOnly?.unitTags && Array.isArray(ocOnly.unitTags)) {
      setUnitTags(ocOnly.unitTags);
    }
    if (ocOnly?.coworkerTags) {
      setCoworkerTags(ocOnly.coworkerTags);
    }
  }, [ocOnly]);

  return [unitTags, coworkerTags, loading, error];
}
