import Modal from "../Modal";
import Spinner from "../../components/Spinner";
import { useTranslation } from "react-i18next";
import SupportSelect from "../../components/SupportSelect";
import AddCoworkerContactFields from "./AddCoworkerContactFields";
import FormFields from "../../components/FormFields";
import OcOnlyTitle from "../../components/OcOnlyTitle";
import { wizardOptions } from "../../constants/inviteWizard";

function AddEditCoworkerModalLayout({
  showCancel,
  loading = false,
  supportRatingExplanation,
  modalTitle,
  control,
  errors,
  footerActions,
  showSupportField = false,
  showOcInfoFields = false,
  showContactInfoFields = false,
  isMember, // some fields are not shown for members
  ...props
}) {
  const { t } = useTranslation();

  return (
    <Modal
      showCancel={showCancel}
      title={modalTitle}
      body={
        loading ? (
          <Spinner />
        ) : (
          ModalBody({
            control,
            errors,
            supportRatingExplanation,
            showSupportField,
            showOcInfoFields,
            showContactInfoFields,
            isMember,
            t,
          })
        )
      }
      footerActions={footerActions}
      data-cy="add-edit-coworker-modal"
      {...props}
    />
  );
}

function ModalBody({
  control,
  errors,
  showContactInfoFields,
  showOcInfoFields,
  showSupportField,
  isMember,
  supportRatingExplanation,
  t, // translation
}) {
  function toSelect(name) {
    return {
      name,
      label: t(`inviteWizard.${name}.title`),
      type: "select",
      selectOptions: wizardOptions[name].map((key) => {
        return {
          label: t(`inviteWizard.${name}.${key}`),
          key,
          value: key,
        };
      }),
    };
  }

  const ocOnlyFields = [
    toSelect("supportRating"),
    {
      name: "note",
      label: t("fieldName.note"),
      type: "textarea",
      rows: 3,
    },
    ...(isMember
      ? []
      : [
          {
            name: "reason",
            label: t("inviteWizard.reason.title"),
            type: "textarea",
            rows: 3,
          },
          toSelect("understand"),
          toSelect("warned"),
        ]),
  ];

  return (
    <>
      {showContactInfoFields && (
        <AddCoworkerContactFields control={control} errors={errors} />
      )}
      {showSupportField && (
        <>
          <div className="semibold">{t("supportInfoTitle")}</div>
          <div className="m-2 text-muted">{supportRatingExplanation}</div>
          <label
            className="ml-2 form-label"
            htmlFor="supportRating"
            data-cy="supportRating-label"
          >
            {t("fieldName.supportRating")}
            <small className="font-italic text-secondary">
              {" - "}
              {t("fieldValidation.optional")}
            </small>
          </label>
          <SupportSelect control={control} errors={errors} />
        </>
      )}
      {showOcInfoFields && (
        <>
          <OcOnlyTitle />
          <FormFields
            className="m-2"
            fields={ocOnlyFields}
            control={control}
            errors={errors}
          />
        </>
      )}
    </>
  );
}

export default AddEditCoworkerModalLayout;
