import { useContext } from "react";
import PageLayout from "../../components/PageLayout";
import "./GetStartedPage.scss";
import { UserUnitContext } from "../../contexts/userUnitContext";
import AlreadyAMember from "../../components/AlreadyAMember";
import GetStartedWizard from "./components/GetStartedWizard";
import EligibilitySuccess from "./components/EligibilitySuccess";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

function GetStartedPage({ success = false }) {
  const { t } = useTranslation();
  return (
    <PageLayout pageTitle={t("pageName.getStarted")} showNav={false}>
      <div className="get-started-container">
        <GetStartedPageContents success={success} />
      </div>
    </PageLayout>
  );
}

function GetStartedPageContents({ success }) {
  const { unit, user } = useContext(UserUnitContext);
  const history = useHistory();

  if (unit) {
    return (
      <>
        <AlreadyAMember
          unitName={unit.unitName}
          firstName={user?.firstName}
          isOcMember={user?.isOcMember}
        />
      </>
    );
  } else if (success) {
    return <EligibilitySuccess loggedIn={!!user} />;
  } else {
    return (
      <GetStartedWizard
        loggedIn={!!user}
        setEligible={() => {
          history.push("/xorenlrk23348lker/success");
        }}
      />
    );
  }
}

export default GetStartedPage;
