import { useEffect } from "react";

// An embedded Active Campaign form

function AcEligibilityFailureForm() {
  // The active campaign script for the form must be loaded after
  // the initial render so it can find the div to put itself in
  // otherwise it will append itself below the bottom bar
  useEffect(() => {
    const scriptId = "ac-eligibility-script";
    // Don't add the script multiple times
    if (document.getElementById(scriptId)) {
      return;
    }

    const script = document.createElement("script");
    script.id = scriptId;
    script.src = process.env.REACT_APP_acEligibilityFailureFormSrc;
    script.type = "text/javascript";
    document.body.appendChild(script);
    // Clean up by removing the script
    return () => {
      script.parentNode.removeChild(script);
    };
  }, []);

  return (
    <div
      className={`${process.env.REACT_APP_acEligibilityFailureFormClass} my-5`}
    />
  );
}

export default AcEligibilityFailureForm;
