// A button that is styled to look like a link
// It's important to use a button when clicking performs a non-navigation action
// for accessibility reasons. But sometimes we want the button to look like a link.
import "./LinkButton.scss";

function LinkButton({
  onClick,
  children,
  variant = "",
  className = "",
  ...props
}) {
  return (
    <button
      className={`link-button ${variant} ${className}`}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
}

export default LinkButton;
