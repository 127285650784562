import colors from "../styles/colors.module.scss";

const SUPPORTIVE = 2;
const SUPPORTIVE_OR_UNDECIDED = 3;

// Possible support ratings and and symbols we use to describe them
const supportRatings = {
  0: {
    textKey: "supportRatings.0",
    emoji: "❓",
    color: colors.lightGrey,
  },
  1: {
    textKey: "inviteWizard.supportRating.1",
    emoji: "👍👍",
    color: colors.green,
  },
  2: {
    textKey: "inviteWizard.supportRating.2",
    emoji: "👍",
    color: colors.lightGreen,
  },
  3: {
    textKey: "inviteWizard.supportRating.3",
    emoji: "🤷",
    color: colors.yellow,
  },
  4: {
    textKey: "inviteWizard.supportRating.4",
    emoji: "👎",
    color: colors.lightRed,
  },
  5: {
    textKey: "inviteWizard.supportRating.5",
    emoji: "👎👎",
    color: colors.red,
  },
};

// Takes a coworker and returns a string like
// "👍👍 Will vote 'Yes'"
function formatCoworkerSupportRating({ t, coworker }) {
  if (!coworker) return "";

  if (coworker.isOcMember) {
    return `✊ ${t("ocMember")}`;
  } else {
    const rating = supportRatings[coworker.supportRating] ?? supportRatings[0];
    return `${rating.emoji} ${t(rating.textKey)}`;
  }
}

export {
  supportRatings,
  SUPPORTIVE,
  SUPPORTIVE_OR_UNDECIDED,
  formatCoworkerSupportRating,
};
