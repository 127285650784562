import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { FirebaseContextProvider } from "./contexts/firebaseContext";
import { ModalContextProvider } from "./contexts/modalContext";
import { ToastContextProvider } from "./contexts/toastContext";
import { UserUnitContextProvider } from "./contexts/userUnitContext";
import "./locales/i18nConfig";

// Using a function for outside-app navigation allows us to
// stub it for testing.
function setExternalHref(url) {
  window.location.href = url;
}
window.setExternalHref = setExternalHref;

ReactDOM.render(
  <React.StrictMode>
    <FirebaseContextProvider>
      <UserUnitContextProvider>
        <ModalContextProvider>
          <ToastContextProvider>
            <App />
          </ToastContextProvider>
        </ModalContextProvider>
      </UserUnitContextProvider>
    </FirebaseContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
