import { formatPhoneNumber } from "react-phone-number-input";
import Card from "react-bootstrap/Card";
import InviteOnlyOnce from "./InviteOnlyOnce";
import FormFields from "../../components/FormFields";
import { useTranslation } from "react-i18next";

function InviteByText({
  messageBody,
  mobilePhone,
  firstName,
  unitName,
  control,
  errors,
  oc = false,
}) {
  const { t } = useTranslation();

  const fields = [
    {
      name: "customMessage",
      type: "textarea",
      rows: 5,
      placeholder: t("invite.addCustomMessage"),
    },
  ];
  return (
    <>
      <p>
        {t("invite.smsDescription1", {
          firstName,
          mobilePhone: formatPhoneNumber(mobilePhone),
        })}
      </p>
      <Card bg="light" className="my-3">
        <Card.Body className="text-secondary">{messageBody} [link]</Card.Body>
      </Card>
      <p>
        {t(`${oc ? "inviteOc" : "invite"}.smsDescription2`, {
          firstName,
          unitName,
        })}
      </p>
      <FormFields fields={fields} control={control} errors={errors} />
      <InviteOnlyOnce firstName={firstName} />
    </>
  );
}

export default InviteByText;
