import FormFields from "../../components/FormFields";
import InviteOnlyOnce from "./InviteOnlyOnce";
import { useTranslation } from "react-i18next";

function InviteByEmail({
  control,
  errors,
  inviteeEmail,
  inviterEmail,
  firstName,
  unitName,
  oc = false,
}) {
  const { t } = useTranslation();

  const fields = [
    {
      name: "customMessage",
      type: "textarea",
      rows: 5,
      placeholder: t("invite.addCustomMessage"),
    },
  ];
  return (
    <>
      <p>
        {inviterEmail
          ? t(`${oc ? "inviteOc" : "invite"}.emailDescription`, {
              firstName,
              inviteeEmail,
              inviterEmail,
              unitName,
            })
          : t(`${oc ? "inviteOc" : "invite"}.emailDescriptionNoInviter`, {
              firstName,
              inviteeEmail,
              unitName,
            })}
      </p>
      <FormFields fields={fields} control={control} errors={errors} />
      <InviteOnlyOnce firstName={firstName} />
    </>
  );
}

export default InviteByEmail;
