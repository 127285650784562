import useFirestoreQuery from "./useFirestoreQuery";
import { useState, useEffect } from "react";

function useUnitByCreatedByUid({ createdByUid }) {
  const [queries, setQueries] = useState([
    ["createdByUid", "==", createdByUid],
  ]);
  const [data, loading, error] = useFirestoreQuery(
    `units`,
    queries,
    // we need to wait until there's a defined uid AND it's in the query
    !queries?.[0]?.[2]
  );

  useEffect(() => {
    setQueries([["createdByUid", "==", createdByUid]]);
  }, [createdByUid]);

  // We do not expect to get more than one result here
  const unit = data.length ? data[0] : null;
  return [unit, loading, error];
}

export default useUnitByCreatedByUid;
