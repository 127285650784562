import { useContext, useEffect } from "react";
import { UserUnitContext } from "../contexts/userUnitContext";
const intercomId = process.env.REACT_APP_intercomId;

// Launch intercom
function useIntercom() {
  const { user, unit } = useContext(UserUnitContext);

  useEffect(() => {
    if (user && user.email) {
      const config = {
        app_id: intercomId,
        email: user.email,
        user_id: user.uid,
        emailVerified: user.emailVerified,
        user_hash: user.intercomUserHash,
      };
      if (unit && unit.unitName) config.unitName = unit.unitName;
      window.Intercom("boot", config);
    } else {
      // Make sure the existing user gets logged out of intercom if they log out of Unit
      window.Intercom("shutdown");
      window.Intercom("boot", {
        app_id: intercomId,
      });
    }
  }, [user, unit]);
}

export default useIntercom;
