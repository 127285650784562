import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/images/unit-logo.svg";
import "./Footer.scss";
import OutboundLink from "./buttonsAndLinks/OutboundLink";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();

  return (
    <Navbar id="footer" sticky="bottom" collapseOnSelect expand="md">
      <OutboundLink href={process.env.REACT_APP_staticHostname}>
        <Navbar.Brand id="footer-brand">
          <img id="footer-logo" alt="unit logo" src={logo} />
        </Navbar.Brand>
      </OutboundLink>

      <Navbar.Text>
        © {new Date().getFullYear()}, Unit of Work, Inc.
      </Navbar.Text>

      <Nav className="ml-auto intercom-margin">
        <OutboundLink href={process.env.REACT_APP_staticHostname + "/terms"}>
          <Nav.Link as="span" eventKey="terms">
            {t("pageName.terms")}
          </Nav.Link>
        </OutboundLink>

        <OutboundLink href={process.env.REACT_APP_staticHostname + "/privacy"}>
          <Nav.Link as="span" eventKey="privacy">
            {t("pageName.privacy")}
          </Nav.Link>
        </OutboundLink>

        <OutboundLink href={process.env.REACT_APP_staticHostname + "/about"}>
          <Nav.Link as="span" eventKey="about">
            {t("pageName.about")}
          </Nav.Link>
        </OutboundLink>
      </Nav>
    </Navbar>
  );
}

export default Footer;
