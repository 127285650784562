import JoinGreeting from "../../../components/join/JoinGreeting";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import ContactUnitLink from "../../../components/buttonsAndLinks/ContactUnitLink";
import OutboundLink from "../../../components/buttonsAndLinks/OutboundLink";
import useAdvisor from "../../../hooks/useAdvisor";
import Card from "react-bootstrap/Card";

function JoinOcIntro({ invite, unit, setIsWrongPerson, setReadyToJoin }) {
  const { t } = useTranslation();
  const [advisor] = useAdvisor(unit?.advisorId);

  const firstName = invite?.invitee?.firstName;
  const inviterFirstName = invite?.inviter?.firstName;
  const unitName = unit?.unitName;

  return (
    <>
      <JoinGreeting firstName={firstName} setIsWrongPerson={setIsWrongPerson} />

      <p className="my-4">
        {firstName
          ? t("joinOc.youreInvited", { inviterFirstName, unitName })
          : t("joinOc.youreInvitedAnon", { unitName })}
      </p>
      {invite?.customMessage && (
        <Card className="my-3">
          <Card.Body>{invite.customMessage}</Card.Body>
        </Card>
      )}

      <h4>
        <OutboundLink
          showIcon
          href={`${process.env.REACT_APP_knowledgeBaseHostname}/whats-an-organizing-committee/`}
        >
          {t("joinOc.whatIsOc.q")}
        </OutboundLink>
      </h4>
      <p>{t("joinOc.whatIsOc.a")}</p>

      <h4>{t("joinOc.whoShouldJoin.q")}</h4>
      <p>{t("joinOc.whoShouldJoin.a")}</p>

      <div className="my-5 text-center">
        <p className="semibold">{t("joinOc.readyToJoin.q")}</p>
        <Button data-cy="oc-ready-button" onClick={() => setReadyToJoin()}>
          {t("joinOc.readyToJoin.yes")}
        </Button>
        <div className="m-3">
          {advisor?.schedulingLink ? (
            <OutboundLink
              data-cy="advisor-scheduling-link"
              href={advisor.schedulingLink}
            >
              {t("joinOc.readyToJoin.needMoreInfo")}
            </OutboundLink>
          ) : (
            <ContactUnitLink inline>
              {t("joinOc.readyToJoin.needMoreInfo")}
            </ContactUnitLink>
          )}
        </div>
      </div>
    </>
  );
}

export default JoinOcIntro;
