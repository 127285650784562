import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/auth";
import { createContext } from "react";

const config = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  databaseURL: process.env.REACT_APP_databaseURL,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
  measurementId: process.env.REACT_APP_measurementId,
};

const FirebaseContext = createContext(null);

const FirebaseContextProvider = (props) => {
  // The first and main app will be named "[DEFAULT]"
  // There must be a default app, we cannot alias it
  if (!firebase.apps.length) {
    firebase.initializeApp(config);
    firebase.analytics();
  }

  // Set up emulators if needed
  if (
    process.env.REACT_APP_useFirebaseEmulators &&
    process.env.NODE_ENV !== "production"
  ) {
    // FIRESTORE
    const firestoreHost = process.env.REACT_APP_firestoreEmulatorHost;
    console.log(`Using firestore emulators on ${firestoreHost}.`);

    // Needed for Firestore support in Cypress (see https://github.com/cypress-io/cypress/issues/6350)
    if (window.Cypress) {
      firebase.firestore().settings({ experimentalForceLongPolling: true });
    }
    const [firestoreDomain, firestorePort] = firestoreHost.split(":");
    firebase.firestore().useEmulator(firestoreDomain, firestorePort);

    // FUNCTIONS
    const funcHost = process.env.REACT_APP_functionsEmulatorHost;
    console.log(`Using function emulators on ${funcHost}.`);
    const [funcDomain, funcPort] = funcHost.split(":");
    firebase.functions().useEmulator(funcDomain, funcPort);
  }

  return <FirebaseContext.Provider value={firebase} {...props} />;
};

export { FirebaseContext, FirebaseContextProvider };
