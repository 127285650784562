function LinkOutIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      style={{
        fill: "none",
        stroke: "currentColor",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "1.5px",
      }}
      {...props}
    >
      <g transform="matrix(1,0,0,1,0,0)">
        <title>external-link</title>
        <line className="a" x1="9.25" y1="14.523" x2="23.25" y2="0.75"></line>
        <polyline
          className="a"
          points="23.25 8.621 23.25 0.75 15.25 0.75"
        ></polyline>
        <path
          className="a"
          d="M12.125,5.75H1.625a.875.875,0,0,0-.875.875v15.75a.875.875,0,0,0,.875.875h15.75a.875.875,0,0,0,.875-.875v-10.5"
        ></path>
      </g>
    </svg>
  );
}

export default LinkOutIcon;
