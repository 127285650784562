import PageLayout from "../../components/PageLayout";
import { useContext, useState, useEffect } from "react";
import { UserUnitContext } from "../../contexts/userUnitContext";
import { useTranslation } from "react-i18next";
import AdvisorCard from "./components/AdvisorCard";
import useAdvisor from "../../hooks/useAdvisor";
import ContactOcCard from "./components/ContactOcCard";
import MemberProfile from "../account/components/MemberProfile";
import AccountSettings from "../account/components/AccountSettings";
import UnionEducationCard from "./components/UnionEducationCard";
import CardDeck from "react-bootstrap/CardDeck";
import "./HomePage.scss";
import NonMemberHomePage from "./components/NonMemberHomePage";
import Spinner from "../../components/Spinner";
import useCoworker from "../../hooks/useCoworker";

// For /memberhome
function CardSignerHomePage() {
  const { user, unit, loading: userUnitLoading } = useContext(UserUnitContext);
  const [advisor] = useAdvisor(unit?.advisorId);
  const [inviter] = useCoworker({
    unitId: unit?.id,
    coworkerId: user?.ocContactCoworkerId,
  });

  const { t } = useTranslation();
  const [canEditPassword, setCanEditPassword] = useState(false);

  useEffect(() => {
    // a single user can technically have multiple providers but we specifically care if they
    // have the provider for logging in with email/password
    const canEditPassword = user?.providerData?.some(
      (provider) => provider.providerId === "password"
    );
    setCanEditPassword(canEditPassword);
  }, [user]);

  return (
    <PageLayout
      pageTitle={t("pageName.home")}
      showNav={false}
      showAccountDropdown={false}
    >
      <CardSignerHomePageContents
        unit={unit}
        user={user}
        inviter={inviter}
        advisor={advisor}
        loading={userUnitLoading} // okay to render page if advisor or inviter still loading
        canEditPassword={canEditPassword}
      />
    </PageLayout>
  );
}

function CardSignerHomePageContents({
  unit,
  user,
  loading,
  inviter,
  advisor,
  canEditPassword,
}) {
  if (loading) return <Spinner />;
  if (!unit) return <NonMemberHomePage />;

  return (
    <div className="home-page-container">
      <CardDeck className="my-3">
        <ContactOcCard inviter={inviter} showExplanatoryText={true} />
        <AdvisorCard advisor={advisor} />
      </CardDeck>

      <UnionEducationCard className="my-3" />

      <MemberProfile />

      <AccountSettings
        showEditPassword={canEditPassword}
        showLeaveUnit={!!unit}
      />
    </div>
  );
}

export default CardSignerHomePage;
