import { CSVLink } from "react-csv";
import { formatFullDateTime, getTimeZone } from "../../../utilities/dateTime";
import { formatPhoneNumber } from "react-phone-number-input";
import { useTranslation } from "react-i18next";
import { formatCoworkerSupportRating } from "../../../constants/supportRatings";

const CoworkersDownload = ({ coworkers }) => {
  const { t } = useTranslation();

  const timeZone = getTimeZone();

  return (
    <CSVLink
      role="button"
      data-cy="download-coworkers-csv"
      data={coworkers.map((coworker) => ({
        ...coworker,
        mobilePhone: formatPhoneNumber(coworker.mobilePhone),
        created: formatFullDateTime(coworker.created),
        updated: formatFullDateTime(coworker.updated),
        signed: coworker.memberUid ? "yes" : "no",
        invited: !!coworker.memberUid
          ? "--" // like the table: show "--" if signed, otherwise invite date
          : formatFullDateTime(coworker.lastInvited),
        support: formatCoworkerSupportRating({ t, coworker }),
        timeZone,
      }))}
      headers={[
        { label: "First Name", key: "firstName" },
        { label: "Last Name", key: "lastName" },
        { label: "Job Title", key: "jobTitle" },
        { label: "Department", key: "department" },
        { label: "Mobile Phone", key: "mobilePhone" },
        { label: "Email", key: "email" },
        { label: "Has Signed", key: "signed" },
        { label: "Support", key: "support" },
        { label: "Asked to Sign", key: "invited" },
        { label: "Created Date", key: "created" },
        { label: "Updated Date", key: "updated" },
        { label: "Time Zone", key: "timeZone" },
      ]}
      filename={`coworkers.csv`}
      className="btn btn-primary text-light float-right"
    >
      {t("coworkers.downloadCsv")}
    </CSVLink>
  );
};

export default CoworkersDownload;
