import Button from "react-bootstrap/Button";
import { useContext, useEffect, useState } from "react";
import { UserUnitContext } from "../../contexts/userUnitContext";
import Modal from "../Modal";
import { ModalContext } from "../../contexts/modalContext";
import Error from "../../components/Error";
import { useTranslation } from "react-i18next";
import useCoworker from "../../hooks/useCoworker";
import { useForm } from "react-hook-form";
import useOcInviteByCoworkerId from "../../hooks/useOcInviteByCoworkerId";
import { createInvite, saveCoworker } from "../../utilities/firestore";
import InviteUrl from "../../components/InviteUrl";
import firebase from "firebase/compat/app";
import { createCoworker } from "../../utilities/firestore";
import ChooseCoworkerOc from "./ChooseCoworkerOc";
import { trackIntercomEvent } from "../../utilities/intercom";
import AddCoworkerContactFields from "../addEdit/AddCoworkerContactFields";
import Spinner from "../../components/Spinner";
import InviteHeader from "../invite/InviteHeader";
import InviteBody from "../invite/InviteBody";
import InviteFooter from "../invite/InviteFooter";
import Tooltip from "../../components/Tooltip";
import LinkButton from "../../components/buttonsAndLinks/LinkButton";
import QuestionCircleIcon from "../../components/icons/QuestionCircleIcon";

// An OC member can use this to invite a new member to the OC
// select existing coworker path:
// 1) select coworker from dropdown (populates invitee via coworkerId getting set)
// 2) create Link/Email/SMS (creates invite, causes inviteUrl to be populated because invite exists)
// add new coworker path (will be auto chosen if nothing to select):
// 1) click Add New from dropdown (sets isAddingNewCoworker: true)
// 2) fill out form and submit (creates coworker, creates invite, sets isLoadingCreatedData: true)
// 3) spinner is shown until invite data loads
const defaultValues = {
  firstName: "",
  lastName: "",
  department: "",
  jobTitle: "",
  email: "",
  mobilePhone: "",
};

function InviteToOcModal({
  coworkerId,
  isAddingNewCoworker,
  isLoadingCreatedData,
  ...props
}) {
  const { unit, user, loading: userUnitLoading } = useContext(UserUnitContext);
  const [inviteMethod, setInviteMethod] = useState("link");
  const [invite] = useOcInviteByCoworkerId({
    unitId: unit?.id,
    coworkerId,
  });
  const [inviteUrl, setInviteUrl] = useState(undefined);
  const [isSaving, setIsSaving] = useState(false);

  const { showModal, setShowModal, setModal } = useContext(ModalContext);
  const [error, setError] = useState();
  const { t } = useTranslation();
  const [invitee, inviteeLoading] = useCoworker({
    coworkerId,
    unitId: unit?.id,
  });
  const { handleSubmit, formState, control, errors } = useForm({
    defaultValues,
  });
  const [defaultMessage, setDefaultMessage] = useState(
    t("inviteOc.defaultMessageAnon")
  );
  useEffect(() => {
    let defaultMessage =
      user && unit && invitee
        ? t("inviteOc.defaultMessage", {
            firstName: invitee.firstName,
            inviterFirstName: user.firstName,
            unitName: unit.unitName,
          })
        : t("inviteOc.defaultMessageAnon");
    setDefaultMessage(defaultMessage);
  }, [inviteeLoading, userUnitLoading, user, unit, invitee, t]);

  useEffect(() => {
    setError(null);
  }, [showModal]);

  useEffect(() => {
    setInviteUrl(
      invite
        ? `${process.env.REACT_APP_appHostname}/jointheoc/${invite.id}`
        : undefined // unsetting inviteUrl is important to reset the modal state following cancel button or close
    );
  }, [invite]);

  const close = () => {
    setShowModal(false);
  };

  const handleCreateCoworker = async (data) => {
    setIsSaving(true);

    // similar to AddCoworkerModal.js
    firebase.analytics().logEvent("oc_coworker_create");
    const newCoworkerId = await createCoworker({
      ...data,
      createdByUid: user.uid,
      unitId: unit.id,
      // populate for invite history
      createdBy: {
        firstName: user.firstName || "",
        lastName: user.lastName || "",
      },
    });
    firebase.analytics().logEvent("oc_coworker_created");

    setIsSaving(false);

    // it's important for the coworkerId to change in the props of the modal
    // or the state doesn't reset correctly when hitting cancel/close/x button
    setModal({
      name: "inviteToOc",
      props: { coworkerId: newCoworkerId, isLoadingCreatedData: false },
    });
  };

  const inviteToOc = async (data) => {
    setIsSaving(true);
    firebase.analytics().logEvent("oc_invite_create");
    const inviteData = {
      unitId: unit.id,
      createdByUid: user.uid,
      invitee: {
        coworkerId: invitee.id,
        firstName: invitee.firstName,
        lastName: invitee.lastName,
        jobTitle: invitee.jobTitle,
      },
      // always populate these for invite history
      inviter: {
        admin: user.isAdmin,
        uid: user.uid,
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        ...(user?.id && { coworkerId: user.id }), // we might not have a coworkerId for admin inviters
      },
      inviteMethod,
      customMessage: data.customMessage,
      oc: true,
    };
    if (inviteMethod === "sms") {
      inviteData.invitee.mobilePhone = invitee.mobilePhone;
      inviteData.messageBody = defaultMessage;
    } else if (inviteMethod === "email") {
      inviteData.invitee.email = invitee.email;
      if (user.email) {
        inviteData.inviter["email"] = user.email;
      }
    }
    await createInvite(inviteData);

    trackIntercomEvent("created-oc-invite");
    firebase.analytics().logEvent("oc_invite_created");
    setIsSaving(false);
  };

  async function save(data) {
    setIsSaving(true);
    await saveCoworker({
      unitId: unit.id,
      coworkerId: invitee.id,
      updatedByUid: user.uid,
      coworkerData: data,
    });
    setIsSaving(false);
  }

  const inviteDateString = invite?.created
    ?.toDate()
    ?.toLocaleDateString("en-US");
  const inviteBy =
    invite?.createdByUid === user?.uid
      ? t("inviteOc.you")
      : `${invite?.inviter?.firstName} ${invite?.inviter?.lastName}`;
  const inviteeFirstName =
    invite?.invitee?.firstName ?? t("inviteOc.unknownCoworker");

  const modalBody = inviteUrl ? (
    <>
      {invite?.inviteMethod === "link" && (
        <>
          <p>
            {t("inviteOc.linkCreated", {
              by: inviteBy,
              date: inviteDateString,
              inviteeFirstName,
            })}
          </p>
          <p>
            {invitee?.firstName
              ? t("inviteOc.linkExplanation1", {
                  firstName: invitee.firstName,
                })
              : t("inviteOc.linkExplanation1anon")}
          </p>
          <p>{t("inviteOc.linkExplanation2")}</p>
        </>
      )}
      {invite?.inviteMethod === "sms" && (
        <>
          <p>
            {t("inviteOc.textSent", {
              by: inviteBy,
              date: inviteDateString,
              inviteeFirstName,
            })}
          </p>
          <p>{t("inviteOc.textEmailLinkExplanation")}</p>
        </>
      )}
      {invite?.inviteMethod === "email" && (
        <>
          <p>
            {t("inviteOc.emailSent", {
              by: inviteBy,
              date: inviteDateString,
              inviteeFirstName,
            })}
          </p>
          <p>{t("inviteOc.textEmailLinkExplanation")}</p>
        </>
      )}
      <InviteUrl
        inviteUrl={inviteUrl}
        shareText={
          user && unit && invitee
            ? t("inviteOc.shareText", {
                firstName: invitee.firstName,
                inviterFirstName: user.firstName,
                unitName: unit.unitName,
              })
            : t("inviteOc.shareTextAnon")
        }
      />
    </>
  ) : isAddingNewCoworker ? (
    <>
      <Tooltip
        contents={t("oc.whoInviteContents")}
        placement="bottom"
        trigger="click"
      >
        <LinkButton className="text-nowrap mb-2">
          <QuestionCircleIcon className="inline-icon" />
          {t("oc.whoInvite")}
        </LinkButton>
      </Tooltip>
      <AddCoworkerContactFields control={control} errors={errors} />
    </>
  ) : isLoadingCreatedData ? (
    <Spinner />
  ) : (
    <div className="mb-4 mx-3">
      <Tooltip
        contents={t("oc.whoInviteContents")}
        placement="bottom"
        trigger="click"
      >
        <LinkButton className="text-nowrap mb-3">
          <QuestionCircleIcon className="inline-icon" />
          {t("oc.whoInvite")}
        </LinkButton>
      </Tooltip>
      <ChooseCoworkerOc defaultCoworkerId={coworkerId} />
      {InviteHeader({
        inviteMethod,
        setInviteMethod,
        showChooseCoworker: false,
        showTabs: !!invitee,
        t,
        oc: true,
      })}
      {InviteBody({
        inviteMethod,
        invitee,
        inviter: user,
        unit,
        defaultMessage,
        showUpdateSupport: false,
        control,
        errors,
        inviteUrl,
        inviteByLink: null,
        oc: true,
      })}
    </div>
  );

  const footerBody = inviteUrl ? (
    <Button
      data-cy="close-button"
      disabled={!!error}
      onClick={close}
      variant="secondary"
    >
      {t("invite.close")}
    </Button>
  ) : isAddingNewCoworker ? (
    <Button
      data-cy="create-coworker-button"
      disabled={!!error || isSaving}
      onClick={handleSubmit(handleCreateCoworker)}
    >
      {t("inviteOc.createCoworkerAndContinue")}
    </Button>
  ) : (
    <InviteFooter
      disabled={isSaving || formState.isSubmitting}
      inviteMethod={inviteMethod}
      invitee={invitee}
      invite={handleSubmit(inviteToOc)}
      save={handleSubmit(save)}
      close={close}
      inviteUrl={inviteUrl}
      showUpdateSupport={false}
      oc={true}
    />
  );
  const errorMessage = <Error className="m-3" error={error} />;

  return (
    <Modal
      data-cy="invite-to-oc-modal"
      title={
        invitee
          ? t("inviteOc.modalTitleSelected", {
              name: `${invitee.firstName} ${invitee.lastName || ""}`,
            })
          : t("inviteOc.modalTitle")
      }
      loading={userUnitLoading || isSaving}
      body={error ? errorMessage : modalBody}
      footerActions={footerBody}
      showCancel={!inviteUrl} // hide the Cancel button on last screen
      {...props}
    />
  );
}

export default InviteToOcModal;
