import FormFields from "./FormFields";
import { useTranslation } from "react-i18next";

function UnitFormFields({
  showOptional = true,
  control,
  errors,
  defaults = {},
}) {
  const { t } = useTranslation();

  const stateOptions = t("constants:states", { returnObjects: true }).map(
    (state) => {
      return {
        key: state.abbreviation,
        value: state.abbreviation,
        label: state.name,
      };
    }
  );

  const companyFields = [
    {
      name: "companyName",
      label: t("fieldName.companyName"),
      type: "text",
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.companyName"),
        }),
      },
      defaultValue: defaults.companyName,
    },
    {
      name: "industry",
      label: t("fieldName.industry"),
      type: "typeahead",
      placeholder: "Type to Search Industries",
      options: t("constants:industries", { returnObjects: true }).sort(),
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.industry"),
        }),
        validate: {
          default: (value) => {
            if (Array.isArray(value) && value.length === 0) {
              return t("fieldValidation.isRequired", {
                fieldName: t("fieldName.industry"),
              });
            }
          },
        },
      },
      defaultValue: defaults.industry || [],
      multiple: false,
    },
    {
      name: "numNonManagerEmployees",
      label: t("fieldName.numNonManagerEmployees"),
      type: "number",
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.numNonManagerEmployees"),
        }),
        validate: {
          default: (value) => {
            if (value < 2) {
              return t("fieldValidation.minCompanySize", { min: 2 });
            }
            if (value > 10000) {
              return t("fieldValidation.maxCompanySize", { max: "10,000" });
            }
          },
        },
      },
      defaultValue: defaults.numNonManagerEmployees,
    },
  ];

  const workSiteFields = [
    {
      name: "address",
      label: t("fieldName.workSite.address"),
      placeholder: t("fieldName.workSite.address"),
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.workSite.address"),
        }),
      },
      type: "text",
      defaultValue: defaults?.workSite?.address,
    },
    {
      name: "address2",
      label: t("fieldName.workSite.address2"),
      placeholder: t("createUnit.form.address2Placeholder"),
      type: "text",
      defaultValue: defaults?.workSite?.address2,
    },
    {
      name: "city",
      label: t("fieldName.workSite.city"),
      placeholder: t("fieldName.workSite.city"),
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.workSite.city"),
        }),
      },
      type: "text",
      defaultValue: defaults?.workSite?.city,
    },
    {
      name: "state",
      label: t("fieldName.workSite.state"),
      type: "select",
      placeholder: t("fieldName.workSite.state"),
      selectOptions: stateOptions,
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.workSite.state"),
        }),
      },
      className: "w-50",
      defaultValue: defaults?.workSite?.state,
    },
    {
      name: "zip",
      label: t("fieldName.workSite.zip"),
      placeholder: t("fieldName.workSite.zip"),
      type: "text",
      inputMode: "numeric",
      className: "w-50",
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.workSite.zip"),
        }),
        validate: {
          default: (zip) => {
            const regex = /^\d{5}(?:[-\s]\d{4})?$/; // https://stackoverflow.com/questions/2577236/regex-for-zip-code
            if (!regex.test(zip)) {
              return t("fieldValidation.invalidZip");
            }
          },
        },
      },
      defaultValue: defaults?.workSite?.zip,
    },
    {
      name: "isRemote",
      checkboxLabel: t("fieldName.workSite.isRemote"),
      type: "checkbox",
      defaultValue: defaults?.workSite?.isRemote,
    },
  ];

  const optionalFields = [
    {
      name: "hearAboutUs",
      label: t("fieldName.hearAboutUs"),
      type: "textarea",
      rows: 4,
    },
  ];

  return (
    <>
      <h4 className="mt-3">{t("createUnit.form.employerTitle")}</h4>
      <FormFields fields={companyFields} control={control} errors={errors} />
      <h4 className="mt-4">{t("createUnit.form.workSiteTitle")}</h4>
      <p className="text-secondary">
        {t("createUnit.form.workSiteExplanation")}
      </p>
      <FormFields fields={workSiteFields} control={control} errors={errors} />
      {showOptional && (
        <>
          <h4 className="mt-3">{t("createUnit.form.optionalTitle")}</h4>
          <FormFields
            fields={optionalFields}
            control={control}
            errors={errors}
          />
        </>
      )}
    </>
  );
}

export default UnitFormFields;
