import React from "react";

const ImageCard = ({ imgSrc, imgAlt, title, titleHiglightColor, text }) => (
  <>
    <img src={imgSrc} alt={imgAlt} className="my-3" />
    <div className="semibold">
      <span
        className={titleHiglightColor ? `${titleHiglightColor}-highlight` : ""}
      >
        {title}
      </span>
    </div>
    <p className="text-secondary">{text}</p>
  </>
);

export default ImageCard;
