import { useContext } from "react";
import AddEditCoworkerModalLayout from "./AddEditCoworkerModalLayout";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import { UserUnitContext } from "../../contexts/userUnitContext";
import { ModalContext } from "../../contexts/modalContext";
import { createCoworker, saveCoworkerOcOnly } from "../../utilities/firestore";
import firebase from "firebase/compat/app";
import { useHistory } from "react-router-dom";
import { ToastContext } from "../../contexts/toastContext";
import { useTranslation } from "react-i18next";
import InviteButton from "../../components/buttonsAndLinks/InviteButton";

const defaultValues = {
  firstName: "",
  lastName: "",
  department: "",
  jobTitle: "",
  email: "",
  mobilePhone: "",
  supportRating: 0,
};

function AddCoworkerModal(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { unit, user } = useContext(UserUnitContext);
  const { setShowModal } = useContext(ModalContext);
  const { setToastProps, setShowToast } = useContext(ToastContext);
  const { handleSubmit, formState, control, errors, reset } = useForm({
    defaultValues,
  });

  const supportRatingExplanation = t(
    "addEditCoworker.addSupportRatingExplanation"
  );

  async function saveCoworker(data) {
    // form data might include a note that is stored in a separate document
    const { note, ...publicCoworkerData } = data;
    firebase.analytics().logEvent("coworker_create");
    const newCoworkerId = await createCoworker({
      ...publicCoworkerData,
      createdByUid: user.uid,
      unitId: unit.id,
      // populate for invite history
      createdBy: {
        firstName: user.firstName || "",
        lastName: user.lastName || "",
      },
    });
    if (note) {
      // TODO batch this with above?
      await saveCoworkerOcOnly({
        coworkerData: { note },
        updatedByUid: user.uid,
        unitId: unit.id,
        coworkerId: newCoworkerId,
      });
    }
    firebase.analytics().logEvent("coworker_created");
    setToastProps({
      header: t("success"),
      body: t("addEditCoworker.addSuccess", {
        name: `${data.firstName} ${data.lastName}`,
      }),
    });
    setShowToast(true);
    return newCoworkerId;
  }

  async function saveAndClose(data) {
    await saveCoworker(data);
    setShowModal(false);
  }

  async function saveAndAddAnother(data) {
    await saveCoworker(data);
    reset();
  }

  async function saveAndInvite(data) {
    const newCoworkerId = await saveCoworker(data);
    firebase.analytics().logEvent("add_coworker_and_invite");
    history.push(`coworker/${newCoworkerId}/invite`);
  }

  return (
    <AddEditCoworkerModalLayout
      {...props}
      showCancel={false}
      footerActions={FooterActions({
        disabled: formState.isSubmitting,
        saveAndClose: handleSubmit(saveAndClose),
        saveAndAddAnother: handleSubmit(saveAndAddAnother),
        saveAndInvite: handleSubmit(saveAndInvite),
      })}
      control={control}
      errors={errors}
      modalTitle={t("addEditCoworker.addModalTitle")}
      supportRatingExplanation={supportRatingExplanation}
      showContactInfoFields={true}
      showSupportField={true}
    />
  );
}

function FooterActions({
  disabled,
  saveAndClose,
  saveAndAddAnother,
  saveAndInvite,
}) {
  const { t } = useTranslation();

  return (
    <>
      <InviteButton disabled={disabled} onClick={saveAndInvite} variant="info">
        {t("addEditCoworker.saveAndInvite")}
      </InviteButton>

      <Button
        disabled={disabled}
        data-cy="save-and-add-another"
        onClick={saveAndAddAnother}
        variant="info"
      >
        {t("addEditCoworker.saveAndAddAnother")}
      </Button>

      <Button
        disabled={disabled}
        data-cy="save-and-close"
        onClick={saveAndClose}
      >
        {t("addEditCoworker.saveAndClose")}
      </Button>
    </>
  );
}

export default AddCoworkerModal;
