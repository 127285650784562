import { useContext, useState, useEffect } from "react";
import { UserUnitContext } from "../../contexts/userUnitContext";
import { ModalContext } from "../../contexts/modalContext";
import { ToastContext } from "../../contexts/toastContext";
import useCoworker from "../../hooks/useCoworker";
import useCoworkerOcOnly from "../../hooks/useCoworkerOcOnly";
import { useForm } from "react-hook-form";
import Modal from "../Modal";
import {
  createInvite,
  saveCoworker,
  saveCoworkerOcOnly,
} from "../../utilities/firestore";
import useInviteLinkByCoworkerId from "../../hooks/useInviteLinkByCoworkerId";
import firebase from "firebase/compat/app";
import { trackIntercomEvent } from "../../utilities/intercom";
import { useTranslation } from "react-i18next";
import { SUPPORTIVE } from "../../constants/supportRatings";
import InviteHeader from "./InviteHeader";
import InviteBody from "./InviteBody";
import InviteFooter from "./InviteFooter";
import useCoworkers from "../../hooks/useCoworkers";
import { UPSELL_OC_AT, wizardAbortAt } from "../../constants/inviteWizard";
import Confetti from "react-confetti";

function InviteCoworkerModal({
  coworkerId,
  initInviteMethod,
  showChooseCoworker = false,
  ...props
}) {
  const { t } = useTranslation();

  const { user, unit, loading: userUnitLoading } = useContext(UserUnitContext);
  const { setModal, setShowModal } = useContext(ModalContext);
  const { setShowToast, setToastProps } = useContext(ToastContext);
  const [inviteMethod, setInviteMethod] = useState(initInviteMethod || "link");
  const [invitee, inviteeLoading] = useCoworker({
    coworkerId,
    unitId: unit?.id,
  });
  const [inviteeOcOnly] = useCoworkerOcOnly({
    coworkerId,
    unitId: unit?.id,
  });
  const [wizardStep, setWizardStep] = useState(0);
  const [wizardSteps, setWizardSteps] = useState(["intro"]);
  // needed to decide if upsell to OC invite
  const [coworkers] = useCoworkers({
    unitId: unit?.id,
  });
  const [wizardAbort, setWizardAbort] = useState(false);

  const { handleSubmit, formState, control, errors, reset } = useForm({
    defaultValues: invitee || {},
  });
  const [disableUpdateSupport, setDisableUpdateSupport] = useState(true);
  const [disableInviteWizardContinue, setDisableInviteWizardContinue] =
    useState(false);
  const [savingInvite, setSavingInvite] = useState(false);
  const [inviteUrl, setInviteUrl] = useState(undefined);
  const [inviteByLink] = useInviteLinkByCoworkerId({
    unitId: unit?.id,
    coworkerId,
    createdByUid: user?.uid,
  });
  const [defaultMessage, setDefaultMessage] = useState(
    t("invite.defaultMessageAnon")
  );
  const [showUpdateSupport, setShowUpdateSupport] = useState(false);

  useEffect(() => {
    setShowUpdateSupport(
      !invitee?.supportRating || invitee?.supportRating > SUPPORTIVE
    );
  }, [invitee?.supportRating]);

  useEffect(() => {
    if (inviteByLink) {
      // any changes to join url need to happen here and onInviteCreate.js
      setInviteUrl(
        `${process.env.REACT_APP_appHostname}/join/${inviteByLink.id}`
      );
    } else {
      // it's important to unset the invite url if the invite changes otherwise
      // the ChooseCoworker component can lead to bugs
      setInviteUrl(undefined);
    }
  }, [inviteByLink]);

  useEffect(() => {
    let defaultMessage =
      user && unit && invitee
        ? t("invite.defaultMessage", {
            firstName: invitee.firstName,
            inviterFirstName: user.firstName,
            unitName: unit.unitName,
          })
        : t("invite.defaultMessageAnon");
    setDefaultMessage(defaultMessage);
  }, [inviteeLoading, userUnitLoading, user, unit, invitee, t]);

  // Reset default form values if invitee changes
  useEffect(() => {
    reset({ ...invitee, ...inviteeOcOnly });
  }, [invitee, inviteeOcOnly, reset]);

  // Decide which wizard steps to skip
  useEffect(() => {
    const wizardSteps = [
      "intro",
      "ocUpsell",
      "supportRating",
      "reason",
      "understand",
      "warned",
    ];
    // we skip any completed steps, which are those that we already have acceptable answers to
    const completed = new Set();
    const steps = [
      { name: "intro", value: inviteByLink }, // if they view/edit an existing link invite
      { name: "supportRating", value: invitee?.supportRating },
      { name: "reason", value: inviteeOcOnly?.reason },
      { name: "understand", value: inviteeOcOnly?.understand },
      { name: "warned", value: inviteeOcOnly?.warned },
    ];
    // only complete the step if there's a value and it's better than the abort threshold
    steps.forEach((step) => {
      if (!step.value) return;
      if (step.name in wizardAbortAt && step.value >= wizardAbortAt[step.name])
        return;
      completed.add(step.name);
    });
    // we can skip ocUpsell if there's at least two members
    if (coworkers.filter((c) => c.isOcMember).length > UPSELL_OC_AT) {
      completed.add("ocUpsell");
    }
    setWizardSteps(wizardSteps.filter((step) => !completed.has(step)));
  }, [invitee, inviteeOcOnly, inviteByLink, setWizardSteps, coworkers]);

  // regardless of how the modal is closed, reset the state
  function handleExited() {
    setWizardStep(0);
    setDisableInviteWizardContinue(false);
    setWizardAbort(false);
  }

  async function incrementWizardStep(data) {
    const step = wizardSteps[wizardStep];
    if (["intro", "ocUpsell"].includes(step)) {
      setWizardStep(wizardStep + 1); // these we increment on client, the rest we wait for backend
    }

    if (step in wizardAbortAt) {
      // data for these steps looks like: { understand: "1" }
      if (data[step] >= wizardAbortAt[step]) {
        // in the abort case we still want to save the data but change how it's rendered later
        setWizardAbort(true);
      }
    }

    if (Object.keys(data).length > 0) {
      if ("supportRating" in data) {
        // special case for support still being on coworker
        await saveCoworker({
          unitId: unit.id,
          coworkerId: invitee.id,
          updatedByUid: user.uid,
          coworkerData: data,
        });
      } else {
        await saveCoworkerOcOnly({
          unitId: unit.id,
          coworkerId: invitee.id,
          updatedByUid: user.uid,
          coworkerData: data,
        });
      }
    }

    // aside from the intro screen, Continue starts disabled until the user enters a value
    setDisableInviteWizardContinue(true);
  }

  async function invite(data) {
    setSavingInvite(true);

    firebase.analytics().logEvent("invite_create");

    const inviteData = {
      unitId: unit.id,
      createdByUid: user.uid,
      invitee: {
        coworkerId: invitee.id,
        firstName: invitee.firstName,
        lastName: invitee.lastName,
        jobTitle: invitee.jobTitle,
        mobilePhone: invitee.mobilePhone,
      },
      // always populate these for invite history
      inviter: {
        admin: user.isAdmin,
        uid: user.uid,
        firstName: user.firstName || "",
        lastName: user.lastName || "",
        ...(user?.id && { coworkerId: user.id }), // we might not have a coworkerId for admin inviters
      },
      inviteMethod,
      customMessage: data.customMessage,
    };

    if (inviteMethod === "sms") {
      inviteData.messageBody = defaultMessage;
    } else if (inviteMethod === "email") {
      inviteData.invitee.email = invitee.email;
      if (user.email) {
        inviteData.inviter["email"] = user.email;
      }
    }

    await createInvite(inviteData);
    firebase
      .analytics()
      .logEvent("invite_created", { invite_method: inviteMethod });
    trackIntercomEvent("created-invite", { invite_method: inviteMethod });
    setSavingInvite(false);

    if (inviteMethod === "link") {
      // deliberate decision to skip toast on link flow, looks weird with modal still open
      setModal({
        name: "invite",
        props: {
          coworkerId,
          initInviteMethod: "link",
          showChooseCoworker: false,
        },
      });

      return;
    } else {
      setToastProps({
        header: "Success!",
        body: "Message sent.",
        "data-cy": "invite-sent-toast",
      });
      setShowToast(true);
      setShowModal(false);
    }
  }

  function save(data) {
    saveCoworker({
      unitId: unit.id,
      coworkerId: invitee.id,
      updatedByUid: user.uid,
      coworkerData: data,
    });
  }

  function close() {
    setShowModal(false);
  }

  return (
    <>
      <Modal
        data-cy="invite-coworker-modal"
        onExited={handleExited}
        title={
          invitee?.firstName
            ? t(
                wizardSteps?.[wizardStep]
                  ? "inviteWizard.title"
                  : "invite.modalTitle",
                {
                  name: `${invitee.firstName} ${invitee.lastName || ""}`,
                }
              )
            : t(
                wizardSteps?.[wizardStep]
                  ? "inviteWizard.titleAnon"
                  : "invite.modalTitleAnon"
              )
        }
        loading={inviteeLoading || userUnitLoading}
        body={
          <>
            {/* if we finished the wizard then render confetti, 
              and have to explicitly specific modal width or it looks weird */}
            {!wizardSteps[wizardStep] && (
              <Confetti data-cy="invite-confetti" recycle={false} width={500} />
            )}
            {InviteHeader({
              inviteMethod,
              setInviteMethod,
              showChooseCoworker,
              showTabs:
                !!invitee && !showUpdateSupport && !wizardSteps[wizardStep],
              t,
            })}
            {InviteBody({
              inviteMethod,
              invitee,
              inviter: user,
              unit,
              defaultMessage,
              setDisableUpdateSupport,
              setDisableInviteWizardContinue,
              showUpdateSupport,
              control,
              errors,
              inviteUrl,
              inviteByLink,
              wizardStep: wizardSteps?.[wizardStep],
              wizardAbort,
            })}
          </>
        }
        footerActions={InviteFooter({
          disabled: savingInvite || formState.isSubmitting,
          disableUpdateSupport,
          disableInviteWizardContinue,
          inviteMethod,
          invitee,
          invite: handleSubmit(invite),
          save: handleSubmit(save),
          incrementWizardStep: handleSubmit(incrementWizardStep),
          close,
          inviteUrl,
          showUpdateSupport,
          wizardStep: wizardSteps?.[wizardStep],
          wizardAbort,
          setWizardAbort,
        })}
        {...props}
      />
    </>
  );
}

export default InviteCoworkerModal;
