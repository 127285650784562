import LinkButton from "./buttonsAndLinks/LinkButton";
import { formatPhoneNumber } from "react-phone-number-input";
import { useContext } from "react";
import { ModalContext } from "../contexts/modalContext";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";

function CoworkerDisplay({
  coworker,
  canEdit,
  showEditButton = true,
  showMembershipStatus = false,
  isMember,
  isInvited,
}) {
  const { t } = useTranslation();
  const { setModal, setShowModal } = useContext(ModalContext);

  const ClickToAdd = canEdit ? (
    <LinkButton
      variant="secondary"
      onClick={() => {
        setModal({
          name: "edit",
          props: { coworkerId: coworker?.id, showContactInfoFields: true },
        });
        setShowModal(true);
      }}
    >
      {t("coworkerDisplay.clickToAdd")}
    </LinkButton>
  ) : (
    <span className="text-secondary">{t("unknown")}</span>
  );

  const memberStatusText = isMember
    ? t("isMember")
    : isInvited
    ? t("isInvited")
    : t("notYetInvited");

  return (
    <>
      {showMembershipStatus && (
        <>
          <dt>{t("coworkerDisplay.memberStatus")}</dt>
          <dd data-cy="membership-status">{memberStatusText}</dd>
        </>
      )}
      <div className="semibold mt-3">{t("Contact Info")}</div>
      {showEditButton && canEdit && (
        <Button
          className="m-2"
          onClick={() => {
            setModal({
              name: "edit",
              props: { coworkerId: coworker.id, showContactInfoFields: true },
            });
            setShowModal(true);
          }}
          variant="outline-primary"
          data-cy="edit-contact-info-button"
        >
          {t("coworkerDetail.edit")}
        </Button>
      )}
      <dl className="m-2">
        <dt>{t("fieldName.department")}</dt>
        <dd data-cy="coworker-department">
          {coworker?.department || ClickToAdd}
        </dd>
        <dt>{t("fieldName.jobTitle")}</dt>
        <dd data-cy="coworker-job-title">{coworker?.jobTitle || ClickToAdd}</dd>
        <dt>{t("fieldName.email")}</dt>
        <dd data-cy="coworker-email">{coworker?.email || ClickToAdd}</dd>
        <dt>{t("fieldName.mobilePhone")}</dt>
        <dd data-cy="coworker-mobile-phone">
          {formatPhoneNumber(coworker?.mobilePhone) || ClickToAdd}
        </dd>
      </dl>
    </>
  );
}

export default CoworkerDisplay;
