// returns a number with the minimum recommended OC size like 2 or 3
function ocMemberGoal(numNonManagerEmployees) {
  if (!numNonManagerEmployees) {
    return null;
  }
  const goalLow = Math.round(Math.max(3, numNonManagerEmployees * 0.1));
  return goalLow;
}

// returns a string like "3-4" or "2" for goal OC size
function ocMemberGoalRange(numNonManagerEmployees) {
  if (!numNonManagerEmployees) {
    return null;
  }
  const goalLow = Math.round(Math.max(3, numNonManagerEmployees * 0.1));
  const goalHigh = Math.round(Math.min(15, numNonManagerEmployees * 0.2));

  return goalLow < goalHigh ? `${goalLow}-${goalHigh}` : `${goalLow}`;
}

export { ocMemberGoal, ocMemberGoalRange };
