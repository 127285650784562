import useFirestoreDoc from "./useFirestoreDoc";

/* Hook to subscribe to a coworker document
 * @param coworkerId
 * @param unitId
 * @returns [coworker, loading, error]
 *   coworker: contains the contents of the document if one exists, or null
 *   loading: bool
 *   error: FirebaseError or NonexistentDocumentError
 */
export default function useCoworker({ coworkerId, unitId }) {
  const [coworker, loading, error] = useFirestoreDoc(
    coworkerId && unitId ? `units/${unitId}/coworkers/${coworkerId}` : null
  );

  return [coworker, loading, error];
}
