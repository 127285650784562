import { supportRatings } from "../constants/supportRatings";
import { useTranslation } from "react-i18next";
import FormFields from "./FormFields";

function SupportSelect({ control, errors, onChange }) {
  const { t } = useTranslation();

  const fields = [
    {
      name: "supportRating",
      type: "select",
      selectOptions: Object.keys(supportRatings)
        .filter((key) => key > 0)
        .map((key) => ({
          key,
          label: `${supportRatings[key].emoji} ${t(
            supportRatings[key].textKey
          )}`,
          value: key,
        })),
      onChange,
    },
  ];

  return (
    <FormFields
      className="mx-2 mb-2"
      fields={fields}
      control={control}
      errors={errors}
    />
  );
}

export default SupportSelect;
