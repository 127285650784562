import Button from "react-bootstrap/Button";
import { updateOcMembership } from "../../utilities/firestore";
import { useContext, useEffect, useState } from "react";
import { UserUnitContext } from "../../contexts/userUnitContext";
import Modal from "../Modal";
import { ModalContext } from "../../contexts/modalContext";
import Error from "../../components/Error";

// An admin can use this to add or remove a member from the OC
function UpdateOcMembershipModal({ coworker, addToOc, ...props }) {
  const { unit, user, loading } = useContext(UserUnitContext);
  const { showModal, setShowModal } = useContext(ModalContext);
  const [error, setError] = useState();

  useEffect(() => {
    setError(null);
  }, [showModal]);

  const submit = async (isOcMember) => {
    setError(null);
    try {
      await updateOcMembership({
        unitId: unit.id,
        coworkerId: coworker.id,
        memberUid: coworker.memberUid,
        isOcMember,
        updatedByUid: user.uid,
      });
      setShowModal(false);
    } catch (error) {
      setError(error);
    }
  };

  const addToOcButton = (
    <Button
      variant="warning"
      data-cy="add-to-oc-button"
      disabled={!!error}
      onClick={() => submit(true)}
    >
      Add to OC
    </Button>
  );

  const removeFromOcButton = (
    <Button
      variant="danger"
      data-cy="remove-from-oc-button"
      disabled={!!error}
      onClick={() => submit(false)}
    >
      Remove from OC
    </Button>
  );

  const modalBody = (
    <p>
      Are you sure you want to update {coworker?.firstName} {coworker?.lastName}
      's OC membership status? This will affect their permissions in the app.
    </p>
  );

  const errorMessage = <Error className="m-3" error={error} />;

  return (
    <Modal
      data-cy="update-oc-membership-modal"
      title={addToOc ? "Add to OC" : "Remove from OC"}
      loading={loading}
      body={error ? errorMessage : modalBody}
      footerActions={addToOc ? addToOcButton : removeFromOcButton}
      {...props}
    />
  );
}

export default UpdateOcMembershipModal;
