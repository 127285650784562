import UnitProgressBar from "../../../components/UnitProgressBar";
import OutboundLink from "../../../components/buttonsAndLinks/OutboundLink";
import UnitNameLogo from "../../../components/UnitNameLogo";
import AuthWidget from "../../../components/AuthWidget";
import Card from "react-bootstrap/Card";
import { useTranslation, Trans } from "react-i18next";
import PrivacyInfo from "../../../components/PrivacyInfo";
import JoinGreeting from "../../../components/join/JoinGreeting";

// TODO: Add a flow for what happens if they are not ready
// eg. contact an existing unit member or contact Unit.
function JoinLogin({ invite, unit, setIsWrongPerson }) {
  const { t } = useTranslation();

  const firstName = invite?.invitee?.firstName;
  const inviterFirstName = invite?.inviter?.firstName;
  const unitName = unit.unitName;
  return (
    <>
      <JoinGreeting firstName={firstName} setIsWrongPerson={setIsWrongPerson} />

      <p className="my-4">
        {firstName
          ? t("join.login.youreInvited", { inviterFirstName, unitName })
          : t("join.login.youreInvitedAnon", { unitName })}
      </p>
      {invite.customMessage ? (
        <Card className="my-3">
          <Card.Body>{invite.customMessage}</Card.Body>
        </Card>
      ) : null}
      <p className="my-4">
        <Trans
          i18nKey="join.login.aboutUnit"
          components={[
            <UnitNameLogo text="unit" />,
            <OutboundLink
              href={process.env.REACT_APP_staticHostname + "/about"}
            ></OutboundLink>,
          ]}
        />
      </p>
      {unit.numMembers > 2 && (
        <UnitProgressBar
          showMembersLink={false}
          className="join-intro-progress-bar mx-auto my-"
          unit={unit}
        />
      )}
      <div className="my-5 text-center">
        <p className="semibold">{t("join.login.continue")}</p>
        <AuthWidget className="my-3" loginRedirectPath={`/join/${invite.id}`} />
      </div>

      <PrivacyInfo className="my-3" />
    </>
  );
}

export default JoinLogin;
