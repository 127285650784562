import useFirestoreQuery from "./useFirestoreQuery";
import { useEffect, useState } from "react";

function useInviteLinkByCoworkerId({
  unitId,
  coworkerId,
  createdByUid,
  oc = false,
}) {
  const [queries, setQueries] = useState([
    ["unitId", "==", unitId],
    ["invitee.coworkerId", "==", coworkerId],
    ["inviteMethod", "==", "link"],
    ["createdByUid", "==", createdByUid],
  ]);
  const [queryLoading, setQueryLoading] = useState(true);

  useEffect(() => {
    setQueries([
      ["unitId", "==", unitId],
      ["invitee.coworkerId", "==", coworkerId],
      ["inviteMethod", "==", "link"],
      ["createdByUid", "==", createdByUid],
    ]);
    setQueryLoading(!unitId || !coworkerId || !createdByUid);
  }, [unitId, coworkerId, createdByUid]);

  const [data, loading, error] = useFirestoreQuery(
    oc ? "ocInvites" : "invites",
    queries,
    queryLoading
  );

  // We do not expect to get more than one unused unexpired link here
  const usable = data.filter((invite) => !invite.used && !invite.expired);
  const invite = usable.length ? usable[0] : null;
  return [invite, loading, error];
}

export default useInviteLinkByCoworkerId;
