import ContactUnitLink from "./buttonsAndLinks/ContactUnitLink";
import Button from "react-bootstrap/Button";
import { sendEmailVerification } from "../utilities/auth";
import { useState, useContext } from "react";
import firebase from "firebase/compat/app";
import { ToastContext } from "../contexts/toastContext";
import { useTranslation } from "react-i18next";

function Unverified({ viewWhat = "this page." }) {
  const { t } = useTranslation();

  const [sentEmail, setSentEmail] = useState(false);
  const { setToastProps, setShowToast } = useContext(ToastContext);

  function sendEmail() {
    setSentEmail(true);
    sendEmailVerification();
    firebase.analytics().logEvent("sent_email_verification_manual");
    setToastProps({
      header: t("success"),
      body: t("unverified.sent"),
      "data-cy": "verify-email-toast",
    });
    setShowToast(true);
  }

  return (
    <>
      <h3>{t("unverified.pleaseVerify")}</h3>
      <p>{t("unverified.explanation", { viewWhat })}</p>
      <Button
        data-cy="send-verification-email-button"
        disabled={sentEmail}
        onClick={sendEmail}
      >
        {t("unverified.send")}
      </Button>

      <p className="mt-3">{t("unverified.pleaseRefresh")}</p>
      <Button data-cy="refresh-button" onClick={() => window.location.reload()}>
        {t("unverified.refresh")}
      </Button>

      <ContactUnitLink />
    </>
  );
}

export default Unverified;
