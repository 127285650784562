import { useState } from "react";
import { useTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tag from "../../components/Tag";
import LinkButton from "../../components/buttonsAndLinks/LinkButton";
import EditTag from "./EditTag";
import DeleteTag from "./DeleteTag";
import { getNextTagId, getNextTagColor } from "../../utilities/tags";

// this component lets the user:
// - create, edit, and delete tags for the entire unit
// - new tags start with a color from our palette but the color can be edited with a widget
function EditTagsForUnitBody({
  control,
  errors,
  handleSubmit,
  deleteTag,
  editTag,
  unitTags,
}) {
  const { t } = useTranslation();
  const [showCreate, setShowCreate] = useState(false);

  function handleCreate() {
    setShowCreate(true);
  }

  const tagForCreate = {
    name: "",
    color: getNextTagColor(unitTags),
    id: getNextTagId(unitTags),
  };

  return (
    <>
      {unitTags.map((tag) => (
        <TagRow
          t={t}
          key={`tag-${tag.id}`}
          tag={tag}
          control={control}
          errors={errors}
          handleSubmit={handleSubmit}
          deleteTag={deleteTag}
          editTag={editTag}
        />
      ))}
      {showCreate ? (
        <EditTag
          control={control}
          errors={errors}
          handleSubmit={handleSubmit}
          tag={tagForCreate}
          setShowEdit={setShowCreate}
          editTag={editTag}
        />
      ) : (
        <button
          className="dropdown-button"
          data-cy="create-new-tag"
          onClick={handleCreate}
        >
          {t("tags.createTag")}
        </button>
      )}
    </>
  );
}

function TagRow({ t, control, errors, tag, deleteTag, editTag, handleSubmit }) {
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  // edit/delete links just toggle themselves open/closed
  // and force close the other if they happen to still be open
  function handleEdit() {
    setShowEdit(!showEdit);
    setShowDelete(false);
  }
  function handleDelete() {
    setShowDelete(!showDelete);
    setShowEdit(false);
  }

  return (
    <>
      <Row className="my-1" data-cy="edit-tag-row">
        <Col xs={8}>
          <Tag name={tag.name} backgroundColor={tag.color} />
        </Col>
        <Col xs={2}>
          <LinkButton data-cy="edit-tag" onClick={handleEdit}>
            {t("tags.edit")}
          </LinkButton>
        </Col>
        <Col onClick={handleDelete} xs={2}>
          <LinkButton data-cy="delete-tag">{t("tags.delete")}</LinkButton>
        </Col>
      </Row>
      {showEdit && (
        <EditTag
          control={control}
          errors={errors}
          handleSubmit={handleSubmit}
          tag={tag}
          setShowEdit={setShowEdit}
          editTag={editTag}
        />
      )}
      {showDelete && (
        <DeleteTag
          tag={tag}
          setShowDelete={setShowDelete}
          deleteTag={deleteTag}
        />
      )}
    </>
  );
}

export default EditTagsForUnitBody;
