import { useTranslation } from "react-i18next";
import Tooltip from "../../components/Tooltip";
import QuestionCircleIcon from "../../components/icons/QuestionCircleIcon";
import TransLinks from "../../components/TransLinks";
import GenericSelect from "../../components/GenericSelect";
import FormFields from "../../components/FormFields";
import { wizardOptions } from "../../constants/inviteWizard";

function InviteWizardStep({
  wizardStep,
  wizardAbort,
  invitee,
  control,
  errors,
  setDisableInviteWizardContinue,
}) {
  const { t } = useTranslation();

  const pre = `inviteWizard.${wizardStep}`;

  if (wizardAbort) {
    const abortUrls = {
      supportRating: ["/how-to-talk-to-your-coworkers-about-unionizing/"],
      understand: ["/independent-and-national/", "START-INTERCOM-CHAT"],
      warned: ["/7-articlesr-anti-union-pushback/"],
    };
    return (
      <p>
        <TransLinks
          i18nKey={`inviteWizard.${wizardStep}.abort`}
          guideUrls={abortUrls[wizardStep] ?? []}
        />
      </p>
    );
  }

  // all steps have a standardized intro with a sentence and a tooltip
  let introText;
  if (wizardStep === "understand") {
    introText = (
      <TransLinks
        i18nKey={`${pre}.i`}
        values={{ firstName: invitee?.firstName }}
        guide="/independent-and-national/"
      />
    );
  } else if (wizardStep === "ocUpsell") {
    introText = (
      <TransLinks
        i18nKey={`${pre}.i`}
        values={{ firstName: invitee?.firstName }}
        guide="/whats-an-organizing-committee/"
      />
    );
  } else {
    introText = t(`${pre}.i`, { firstName: invitee?.firstName });
  }
  const intro = (
    <p>
      {introText}
      {/* every step besides ocUpsell has a tooltip */}
      {wizardStep === "ocUpsell" ? null : (
        <Tooltip placement="bottom" contents={t(`${pre}.tooltip`)}>
          <QuestionCircleIcon className="inline-icon" />
        </Tooltip>
      )}
    </p>
  );

  let body = null;
  if (wizardStep === "intro") {
    body = (
      <>
        <ul>
          <li>
            <TransLinks
              i18nKey={`${pre}.1`}
              guide="/how-to-talk-to-your-coworkers-about-unionizing/"
            />
          </li>
          <li>{t(`${pre}.2`)}</li>
          <li>{t(`${pre}.3`)}</li>
          <li>
            <TransLinks
              i18nKey={`${pre}.4`}
              guide="/independent-and-national/"
            />
          </li>
          <li>
            <TransLinks
              i18nKey={`${pre}.5`}
              guide="/7-articlesr-anti-union-pushback/"
            />
          </li>
        </ul>
        <p>{t(`${pre}.c`)}</p>
      </>
    );
  } else if (wizardStep in wizardOptions) {
    body = (
      <GenericSelect
        control={control}
        errors={errors}
        fieldName={wizardStep}
        selectOptions={wizardOptions[wizardStep].map((key) => {
          return {
            label: t(`inviteWizard.${wizardStep}.${key}`),
            key,
            value: key,
          };
        })}
        onChange={(selection) => {
          setDisableInviteWizardContinue(!selection);
        }}
      />
    );
  } else if (wizardStep === "reason") {
    body = (
      <FormFields
        className="mx-2 mb-2"
        fields={[
          {
            name: "reason",
            type: "textarea",
            rows: 3,
            onChange: (text) => {
              setDisableInviteWizardContinue(!text?.length);
            },
          },
        ]}
        control={control}
        errors={errors}
      />
    );
  } else if (wizardStep === "ocUpsell") {
    body = (
      <p>{t("inviteWizard.ocUpsell.1", { firstName: invitee?.firstName })}</p>
    );
  }

  return (
    <>
      {intro}
      {body}
    </>
  );
}

export default InviteWizardStep;
