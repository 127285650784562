import { useContext } from "react";
import LinkButton from "../../../components/buttonsAndLinks/LinkButton";
import Card from "react-bootstrap/Card";
import { ModalContext } from "../../../contexts/modalContext";
import { useTranslation } from "react-i18next";

function AccountSettings({ showEditPassword, showLeaveUnit }) {
  const { t } = useTranslation();
  const { setModal, setShowModal } = useContext(ModalContext);

  return (
    <>
      <Card className="mt-3">
        <Card.Header className="semibold">
          {t("account.settingsTitle")}
        </Card.Header>
        <Card.Body>
          {showEditPassword ? (
            <>
              <LinkButton
                data-cy="change-password-link"
                onClick={() => {
                  setModal({ name: "password", props: {} });
                  setShowModal(true);
                }}
              >
                {t("account.password.changeTitle")}
              </LinkButton>
              <p>{t("account.password.instructions")}</p>
            </>
          ) : null}

          {showLeaveUnit ? (
            <>
              <LinkButton
                data-cy="leave-unit-link"
                variant="danger"
                onClick={() => {
                  setModal({ name: "leaveUnit", props: {} });
                  setShowModal(true);
                }}
              >
                {t("leaveUnit.button")}
              </LinkButton>
              <p>{t("leaveUnit.buttonExplain")}</p>
            </>
          ) : null}
        </Card.Body>
      </Card>
    </>
  );
}

export default AccountSettings;
