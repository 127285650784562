import useCoworker from "../../hooks/useCoworker";
import AddEditCoworkerModalLayout from "./AddEditCoworkerModalLayout";
import { saveCoworker, saveCoworkerOcOnly } from "../../utilities/firestore";
import { useContext, useEffect } from "react";
import { UserUnitContext } from "../../contexts/userUnitContext";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import { ModalContext } from "../../contexts/modalContext";
import { useTranslation } from "react-i18next";
import InviteButton from "../../components/buttonsAndLinks/InviteButton";
import useCoworkerOcOnly from "../../hooks/useCoworkerOcOnly";

function EditCoworkerModal({ coworkerId, ...props }) {
  const { t } = useTranslation();
  const { unit, user } = useContext(UserUnitContext);
  const { setModal, setShowModal } = useContext(ModalContext);
  const [coworker] = useCoworker({ coworkerId, unitId: unit?.id });
  const [coworkerOcOnly] = useCoworkerOcOnly({ coworkerId, unitId: unit?.id });
  const { handleSubmit, formState, control, errors, reset } = useForm({
    defaultValues: { ...coworker, ...coworkerOcOnly },
  });

  // Need to make sure the default values reset we change which
  // coworker we are editing
  useEffect(() => {
    reset({ ...coworker, ...coworkerOcOnly });
  }, [coworker, coworkerOcOnly, reset]);

  async function save(coworkerData) {
    // there might be ocOnly data or public data mixed in, separate them and store in different documents
    const ocOnly = ["note", "reason", "understand", "warned"];
    const publicCoworkerData = Object.fromEntries(
      Object.entries(coworkerData).filter(([x]) => !ocOnly.includes(x))
    );
    const privateCoworkerData = Object.fromEntries(
      Object.entries(coworkerData).filter(([x]) => ocOnly.includes(x))
    );
    if (Object.keys(publicCoworkerData).length > 0) {
      await saveCoworker({
        coworkerData: publicCoworkerData,
        updatedByUid: user.uid,
        unitId: unit.id,
        coworkerId: coworkerId,
      });
    }
    if (Object.keys(privateCoworkerData).length > 0) {
      // TODO batch with above?
      await saveCoworkerOcOnly({
        coworkerData: privateCoworkerData,
        updatedByUid: user.uid,
        unitId: unit.id,
        coworkerId: coworkerId,
      });
    }
  }

  async function saveAndClose(coworkerData) {
    await save(coworkerData);
    setShowModal(false);
  }

  async function saveAndInvite(coworkerData) {
    await save(coworkerData);
    setModal({
      name: "invite",
      props: { coworkerId, showChooseCoworker: false },
    });
  }

  const supportRatingExplanation = t(
    "addEditCoworker.editSupportRatingExplanation"
  );

  return (
    <AddEditCoworkerModalLayout
      {...props}
      footerActions={FooterActions({
        disabled: formState.isSubmitting,
        saveAndClose: handleSubmit(saveAndClose),
        saveAndInvite: handleSubmit(saveAndInvite),
        showSaveAndInvite: !coworker?.memberUid,
      })}
      modalTitle={t("addEditCoworker.editModalTitle")}
      control={control}
      errors={errors}
      supportRatingExplanation={supportRatingExplanation}
      loading={!coworker}
      showSupportField={false} // edit in Oc Info instead
    />
  );
}

function FooterActions({
  disabled,
  saveAndClose,
  saveAndInvite,
  showSaveAndInvite,
}) {
  const { t } = useTranslation();

  return (
    <>
      <InviteButton
        disabled={disabled}
        show={showSaveAndInvite}
        onClick={saveAndInvite}
        variant="info"
      >
        {t("addEditCoworker.saveAndInvite")}
      </InviteButton>

      <Button
        disabled={disabled}
        data-cy="edit-coworker-submit"
        onClick={saveAndClose}
      >
        {t("addEditCoworker.saveAndClose")}
      </Button>
    </>
  );
}

export default EditCoworkerModal;
