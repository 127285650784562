import { useState } from "react";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import WizardError from "./WizardError";
import WizardStep from "./WizardStep";
import ContactUnitLink from "../../../components/buttonsAndLinks/ContactUnitLink";
import LoginLink from "../../../components/buttonsAndLinks/LoginLink";
import OutboundLink from "../../../components/buttonsAndLinks/OutboundLink";
import FormControl from "react-bootstrap/FormControl";
import EnvelopeIcon from "../../../components/icons/EnvelopeIcon";
import { useTranslation, Trans } from "react-i18next";
import PrivacyInfo from "../../../components/PrivacyInfo";
import PrivacyDetail from "../../../components/PrivacyDetail";

function GetStartedWizard({ loggedIn, setEligible }) {
  const [error, setError] = useState(null);
  const [responses, setResponses] = useState([]);
  const { t } = useTranslation();

  const wizardSteps = [
    {
      name: "start",
      headerText: t("getStarted.steps.start.header"),
      subText: (
        <>
          <p>{t("getStarted.steps.start.description")}</p>
          <p>
            {t("getStarted.steps.start.notReady")}{" "}
            <OutboundLink
              href={`${process.env.REACT_APP_staticHostname}/signup/`}
            >
              {t("getStarted.steps.start.signupLink")}{" "}
              <EnvelopeIcon color="blue" size=".8rem" />
            </OutboundLink>
          </p>
          <div className="my-3 p-3 d-flex flex-column">
            <span className="semibold text-secondary">
              {t("getStarted.learnMore")}
            </span>
            <OutboundLink
              href={`${process.env.REACT_APP_staticHostname}/about/`}
            >
              {t("getStarted.steps.start.aboutLink")}
            </OutboundLink>
            <OutboundLink
              href={`${process.env.REACT_APP_knowledgeBaseHostname}/tag/getting-started/`}
            >
              {t("getStarted.steps.start.guideLink")}
            </OutboundLink>
          </div>
        </>
      ),
      action: (
        <>
          <ContinueAction
            setResponse={verifyEligibilityAndAdvanceWizard}
            t={t}
          />
        </>
      ),
      checkIsEligible: (dismissed) => dismissed === true,
      post: (
        <div className="mt-4">
          {loggedIn ? null : <LoginLink className="text-right" />}
          <ContactUnitLink className="my-0 text-right" />
          <PrivacyInfo className="my-3" />
        </div>
      ),
    },
    {
      name: "in_US",
      headerText: t("getStarted.steps.in_US.header"),
      subText: t("getStarted.steps.in_US.subText"),
      action: (
        <YesNoAction setResponse={verifyEligibilityAndAdvanceWizard} t={t} />
      ),
      checkIsEligible: (inUS) => inUS === true,
      notEligibleError: t("getStarted.steps.in_US.error"),
      showNotEligibleForm: false,
      showNotEligibleLinks: false,
    },
    {
      name: "is_contractor",
      headerText: t("getStarted.steps.is_contractor.header"),
      subText: t("getStarted.steps.is_contractor.subText"),
      action: (
        <YesNoAction setResponse={verifyEligibilityAndAdvanceWizard} t={t} />
      ),
      checkIsEligible: (isIndependentContractor) =>
        isIndependentContractor === false,
      notEligibleError: t("getStarted.steps.is_contractor.error"),
      showNotEligibleForm: true,
      showNotEligibleLinks: true,
    },
    {
      name: "public_sector",
      headerText: t("getStarted.steps.public_sector.header"),
      subText: t("getStarted.steps.public_sector.subText"),
      action: (
        <YesNoAction setResponse={verifyEligibilityAndAdvanceWizard} t={t} />
      ),
      checkIsEligible: (inPublicSector) => inPublicSector === false,
      notEligibleError: (
        <>
          <Trans
            i18nKey="getStarted.steps.public_sector.error"
            components={[
              <OutboundLink
                href={`${process.env.REACT_APP_knowledgeBaseHostname}/private-vs-public-unions/`}
              ></OutboundLink>,
            ]}
          />
        </>
      ),
      showNotEligibleForm: true,
      showNotEligibleLinks: false,
    },
    {
      name: "is_manager",
      headerText: t("getStarted.steps.is_manager.header"),
      subText: (
        <Trans
          i18nKey="getStarted.steps.is_manager.subText"
          components={[
            <OutboundLink
              href={`${process.env.REACT_APP_knowledgeBaseHostname}/is-my-coworker-a-supervisor/`}
            ></OutboundLink>,
          ]}
        />
      ),
      action: (
        <YesNoAction setResponse={verifyEligibilityAndAdvanceWizard} t={t} />
      ),
      checkIsEligible: (isManager) => isManager === false,
      notEligibleError: t("getStarted.steps.is_manager.error"),
      showNotEligibleForm: true,
      showNotEligibleLinks: true,
    },
    {
      name: "num_non_managers",
      headerText: t("getStarted.steps.num_non_managers.header"),
      subText: t("getStarted.steps.num_non_managers.subText"),
      action: (
        <NumberAction
          t={t}
          setResponse={(response) => {
            localStorage.setItem(
              "getStartedUnitInfo",
              JSON.stringify({ numNonManagerEmployees: response })
            );
            verifyEligibilityAndAdvanceWizard(response);
          }}
        />
      ),
      checkIsEligible: (numNonManagers) =>
        numNonManagers <= 200 && numNonManagers > 1,
      notEligibleError: t("getStarted.steps.num_non_managers.error"),
      showNotEligibleForm: true,
      showNotEligibleLinks: false,
    },
  ];

  function goBack() {
    // if they're navigating backwards from an error, just clear the error
    if (error) {
      setError(null);
      return;
    }
    // otherwise they want to remove the last response
    const backResponses = responses.slice(0, responses.length - 1);
    setError(null);
    setResponses(backResponses);
  }

  function verifyEligibilityAndAdvanceWizard(response) {
    const currentStep = wizardSteps[responses.length];
    if (responses.length > 0 && !currentStep.checkIsEligible(response)) {
      // ineligible. we have to be careful here not to show them the next step of the wizard or it
      // will record erroneous data to analytics. instead, we'll set an error and avoid advancing
      setError({
        subText: currentStep.notEligibleError,
        failedStepName: currentStep.name,
        showForm: currentStep.showNotEligibleForm,
        showLinks: currentStep.showNotEligibleLinks,
      });
      return;
    }
    if (responses.length === wizardSteps.length - 1) {
      setEligible();
    }
    setResponses([...responses, response]);
  }

  function CurrentStep() {
    const step = wizardSteps[responses.length];
    return (
      <WizardStep
        name={step.name}
        headerText={step.headerText}
        subText={step.subText}
        action={step.action}
        backButton={BackButton({
          goBack,
          show: responses.length > 0,
          t,
        })}
        post={
          step.post || (
            <div className="my-4">
              <PrivacyDetail
                className="small text-right"
                contents={t("privacy.eligibility")}
                placement="bottom"
              />
              <ContactUnitLink className="my-0 text-right" />
            </div>
          )
        }
      />
    );
  }

  return (
    <>
      <ProgressBar
        className="mb-4"
        variant={error ? "secondary" : "primary"}
        now={(responses.length * 100) / wizardSteps.length}
      />

      {error ? (
        <WizardError
          {...error}
          backButton={BackButton({ goBack, show: responses.length > 0, t })}
        />
      ) : (
        <CurrentStep />
      )}
    </>
  );
}

function BackButton({ show, goBack, t }) {
  if (!show) return null;
  return (
    <Button data-cy="back-button" onClick={goBack} variant="secondary">
      {t("getStarted.back")}
    </Button>
  );
}

function YesNoAction({ setResponse, t }) {
  return (
    <>
      <Button
        data-cy="yes-button"
        variant="primary"
        className="ml-auto"
        onClick={() => setResponse(true)}
      >
        {t("getStarted.yes")}
      </Button>
      <Button
        data-cy="no-button"
        className="ml-3"
        variant="primary"
        onClick={() => setResponse(false)}
      >
        {t("getStarted.no")}
      </Button>
    </>
  );
}

function NumberAction({ setResponse, t }) {
  const [tempResponse, setTempResponse] = useState(null);
  return (
    <div className="ml-auto d-flex flex-column justify-content-end">
      <FormControl
        type="number"
        as="input"
        data-cy="number-form"
        onChange={(event) => {
          setTempResponse(event.target.value);
        }}
        size="lg"
        style={{ width: "6em" }}
      />
      <Button
        className="mt-3 ml-auto"
        variant="primary"
        data-cy="continue-button"
        onClick={() => {
          if (tempResponse) setResponse(Number.parseInt(tempResponse));
        }}
      >
        {t("getStarted.continue")}
      </Button>
    </div>
  );
}

function ContinueAction({ setResponse, buttonText, t }) {
  return (
    <>
      <Button
        data-cy="continue-button"
        variant="primary"
        className="ml-auto"
        onClick={() => setResponse(true)}
      >
        {buttonText || t("getStarted.continue")}
      </Button>
    </>
  );
}

export default GetStartedWizard;
