import PageLayout from "../../components/PageLayout";
import SwitchUnit from "./components/SwitchUnit";
import { useParams, useHistory } from "react-router-dom";
import { useContext, useEffect } from "react";
import { UserUnitContext } from "../../contexts/userUnitContext";
import AuthCardDownload from "./components/AuthCardDownload";
import Spinner from "../../components/Spinner";
import CardDeck from "react-bootstrap/CardDeck";
import ActAsUid from "./components/ActAsUid";

function AdminPage() {
  const params = useParams();
  const history = useHistory();
  const { unit, loading, setAdminUnitId, setAdminActAsUid } =
    useContext(UserUnitContext);

  useEffect(() => {
    if (params.unitId) {
      setAdminUnitId(params.unitId);
      history.push("/coworkers");
    }
  }, [params, setAdminUnitId, history]);

  return (
    <PageLayout pageTitle="Admin">
      {loading ? (
        <Spinner />
      ) : (
        <CardDeck>
          <SwitchUnit onClickSwitch={setAdminUnitId} />
          <ActAsUid onClickActAs={setAdminActAsUid} />
          <AuthCardDownload unit={unit} />
        </CardDeck>
      )}
    </PageLayout>
  );
}

export default AdminPage;
