import { useTranslation } from "react-i18next";
import QuestionCircleIcon from "./icons/QuestionCircleIcon";
import Tooltip from "./Tooltip";

function OcOnlyTitle({ className }) {
  const { t } = useTranslation();

  return (
    <div className={`semibold ${className || ""}`}>
      {t("ocOnlyTitle")}
      <Tooltip contents={t("ocOnlyTooltip")}>
        <QuestionCircleIcon className="inline-icon" />
      </Tooltip>
    </div>
  );
}

export default OcOnlyTitle;
