import BootstrapSpinner from "react-bootstrap/Spinner";

function Spinner({ text = "" }) {
  return (
    <div className="m-auto d-flex flex-column">
      <div className="mx-auto my-2 text-secondary">{text}</div>
      <BootstrapSpinner
        data-cy="spinner"
        animation="border"
        variant="secondary"
        className="m-auto"
      />
    </div>
  );
}

export default Spinner;
