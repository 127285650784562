import CaptureMobilePhone from "./CaptureMobilePhone";
import CaptureEmail from "./CaptureEmail";
import InviteByText from "./InviteByText";
import InviteByLink from "./InviteByLink";
import InviteByEmail from "./InviteByEmail";
import DisplayInviteLink from "./DisplayInviteLink";
import UpdateSupport from "./UpdateSupport";
import { SUPPORTIVE } from "../../constants/supportRatings";
import InviteWizardStep from "./InviteWizardStep";

function InviteBody({
  inviteMethod,
  invitee,
  inviter,
  unit,
  defaultMessage,
  control,
  errors,
  inviteUrl,
  inviteByLink,
  setDisableUpdateSupport,
  setDisableInviteWizardContinue,
  showUpdateSupport,
  oc = false,
  wizardStep,
  wizardAbort,
}) {
  if (!invitee) {
    return null;
  }
  if (wizardStep) {
    return (
      <InviteWizardStep
        wizardStep={wizardStep}
        wizardAbort={wizardAbort}
        invitee={invitee}
        control={control}
        errors={errors}
        setDisableInviteWizardContinue={setDisableInviteWizardContinue}
      />
    );
  }
  if (showUpdateSupport) {
    return (
      <UpdateSupport
        control={control}
        errors={errors}
        firstName={invitee?.firstName}
        supportRating={invitee?.supportRating}
        onChange={(rating) => {
          // only enabled for 👍 and 👍👍
          setDisableUpdateSupport(!rating || rating > SUPPORTIVE);
        }}
      />
    );
  }

  if (inviteMethod === "sms") {
    return invitee?.mobilePhone ? (
      <InviteByText
        firstName={invitee?.firstName}
        control={control}
        errors={errors}
        messageBody={defaultMessage}
        mobilePhone={invitee?.mobilePhone}
        unitName={unit?.unitName}
        oc={oc}
      />
    ) : (
      <CaptureMobilePhone
        control={control}
        errors={errors}
        firstName={invitee?.firstName}
      />
    );
  } else if (inviteMethod === "email") {
    return invitee?.email ? (
      <InviteByEmail
        firstName={invitee?.firstName}
        control={control}
        errors={errors}
        inviteeEmail={invitee?.email}
        inviterEmail={inviter?.email}
        unitName={unit?.unitName}
        oc={oc}
      />
    ) : (
      <CaptureEmail
        control={control}
        errors={errors}
        firstName={invitee?.firstName}
      />
    );
  } else if (inviteMethod === "link") {
    return !inviteUrl ? (
      <InviteByLink
        firstName={invitee?.firstName}
        unitName={unit?.unitName}
        control={control}
        errors={errors}
        oc={oc}
      />
    ) : (
      <DisplayInviteLink
        invite={inviteByLink}
        firstName={invitee?.firstName}
        inviteUrl={inviteUrl}
        inviter={inviter}
        message={inviteByLink?.customMessage || defaultMessage}
      />
    );
  } else {
    return null;
  }
}

export default InviteBody;
