import "./UnitNameLogo.scss";

function UnitNameLogo({ text, ...props }) {
  if (!text) {
    return null;
  }
  return (
    <span className="unit-name" {...props}>
      {text}
      <span className="unit-name-dot">.</span>
    </span>
  );
}

export default UnitNameLogo;
