import { Redirect, Route } from "react-router-dom";
import { useContext } from "react";
import { UserUnitContext } from "../contexts/userUnitContext";

// TODO: what do we do about auth errors?
function PrivateRoute({
  children,
  redirectToMemberHomeOrOcHome = false,
  adminOnly = false,
  ogAdminOnly = false,
  ocOnly = false,
  ...props
}) {
  const { user, loading } = useContext(UserUnitContext);

  function renderChildrenOrRedirect({ location }) {
    // The child page currently manages the display of loading state
    // we shoudn't redirect unless loading is complete
    if (loading) {
      return children;
    } else if (!user) {
      return LoginRedirect(location);
    } else if (redirectToMemberHomeOrOcHome) {
      return HomeRedirect(location, user);
    } else if (ogAdminOnly) {
      return user?.isOgAdmin ? children : HomeRedirect(location, user);
    } else if (adminOnly) {
      return user?.isAdmin ? children : HomeRedirect(location, user);
    } else if (ocOnly) {
      return user?.isOcMember || user?.isAdmin
        ? children
        : HomeRedirect(location, user);
    } else {
      return children;
    }
  }

  return <Route {...props} render={renderChildrenOrRedirect} />;
}

function LoginRedirect(from) {
  return <Redirect to={{ pathname: "/login", state: { from } }} />;
}

function HomeRedirect(from, user) {
  if (user?.isOcMember || user?.isAdmin) {
    return OcHomeRedirect(from);
  } else {
    return MemberHomeRedirect(from);
  }
}

function MemberHomeRedirect(from) {
  return <Redirect to={{ pathname: "/memberhome", state: { from } }} />;
}

function OcHomeRedirect(from) {
  return <Redirect to={{ pathname: "/ochome", state: { from } }} />;
}

export default PrivateRoute;
