import { Trans } from "react-i18next";

function InviteOnlyOnce({ firstName = "this coworker" }) {
  return (
    <small data-cy="invite-only-once">
      <Trans
        i18nKey="invite.onlyOnce"
        components={[<span className="semibold"></span>, <br></br>]}
        values={{ firstName }}
      />
    </small>
  );
}

export default InviteOnlyOnce;
