import { useEffect } from "react";
import useStateWithLocalStorage from "./useStateWithLocalStorage";
import i18next from "i18next";

// Store the user's language preference in local storage
// Values should be "en", "es". If undefined, i18n will use "en"

function useLocale() {
  const [lang, setLang] = useStateWithLocalStorage("language");

  useEffect(() => {
    if (lang) {
      i18next.changeLanguage(lang);
    }
  }, [lang]);

  return setLang;
}

export default useLocale;
