import { useEffect } from "react";

/*
 * A hook to show/hide table columns on small or large screens
 *
 * @param {Function} setHiddenColumns - the setHiddenColumns function from the react-table useTable hook
 * @param {String} breakpoint - current size (xs, sm, md, lg, xl)
 * @param {Array} hiddenOnSmallScreens - array of column selectors that should be hidden on small screens
 * @param {Array} hiddenOnLargeScreens - array of column selectors that should be hidden on large screens
 */

function useBreakpointColumns({
  setHiddenColumns,
  breakpoint,
  hiddenOnSmallScreens = [],
  hiddenOnLargeScreens = [],
}) {
  useEffect(() => {
    if (!breakpoint) return;
    if (["xs", "sm", "md"].includes(breakpoint)) {
      setHiddenColumns((hiddenColumns) => {
        const stillHidden = hiddenColumns.filter(
          (col) => !hiddenOnLargeScreens.includes(col)
        );
        return [...stillHidden, ...hiddenOnSmallScreens];
      });
    } else {
      setHiddenColumns((hiddenColumns) => {
        const stillHidden = hiddenColumns.filter(
          (col) => !hiddenOnSmallScreens.includes(col)
        );
        return [...stillHidden, ...hiddenOnLargeScreens];
      });
    }
  }, [
    breakpoint,
    setHiddenColumns,
    hiddenOnLargeScreens,
    hiddenOnSmallScreens,
  ]);
}

export default useBreakpointColumns;
