import useFirestoreQuery from "./useFirestoreQuery";
import { useState, useEffect } from "react";

function useCoworkerInvites({ coworkerId, unitId }) {
  const [queries, setQueries] = useState([
    ["invitee.coworkerId", "==", coworkerId],
    ["unitId", "==", unitId],
  ]);
  const [queryLoading, setQueryLoading] = useState(true);

  useEffect(() => {
    setQueries([
      ["invitee.coworkerId", "==", coworkerId],
      ["unitId", "==", unitId],
    ]);
    setQueryLoading(!unitId || !coworkerId);
  }, [coworkerId, unitId]);

  const [data, loading, error] = useFirestoreQuery(
    `invites`,
    queries,
    queryLoading
  );

  return [data, loading, error];
}

export default useCoworkerInvites;
