import useFirestoreQuery from "./useFirestoreQuery";
import { useState } from "react";

function useCoworkers({ unitId }) {
  const [queries] = useState([["isDeleted", "==", false]]);
  const [data, loading, error] = useFirestoreQuery(
    `units/${unitId}/coworkers`,
    queries,
    !unitId
  );

  return [data, loading, error];
}

export default useCoworkers;
