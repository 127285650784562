import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Tooltip from "../../../components/Tooltip";
import { ModalContext } from "../../../contexts/modalContext";
import { useContext } from "react";
import { UserUnitContext } from "../../../contexts/userUnitContext";
import { useTranslation } from "react-i18next";

function UnitProfile() {
  const { t } = useTranslation();
  const { setModal, setShowModal } = useContext(ModalContext);
  const { user, unit } = useContext(UserUnitContext);

  // Unit data can be edited by an admin or the worker who
  // created the unit
  const canEdit = user?.isAdmin || user?.uid === unit.createdByUid;

  return (
    <>
      <Card className="mt-3" data-cy="unit-profile-card">
        <Card.Header className="d-flex align-items-center">
          <span>{t("account.unitTitle")}</span>
          <div className="ml-auto">
            <Tooltip
              show={!canEdit}
              placement="bottom"
              contents={t("account.cantEdit")}
            >
              <Button
                size="sm"
                variant="link"
                disabled={!canEdit}
                data-cy="edit-unit-button"
                onClick={() => {
                  setModal({ name: "editUnit" });
                  setShowModal(true);
                }}
              >
                {t("account.edit")}
              </Button>
            </Tooltip>
          </div>
        </Card.Header>
        <Card.Body>
          <h4 className="semibold" data-cy="unit-name">
            {unit.unitName}
          </h4>

          <div className="semibold mt-3">Employer</div>
          <dl className="m-2">
            <dt>{t("fieldName.companyName")}</dt>
            <dd data-cy="company-name">{unit.companyName}</dd>

            <dt>{t("fieldName.industry")}</dt>
            <dd data-cy="industry">{unit.industry || "Missing"}</dd>

            <dt>{t("fieldName.numNonManagerEmployees")}</dt>
            <dd data-cy="num-expected">
              {unit.numNonManagerEmployees || "Missing"}
            </dd>

            {user.isAdmin ? (
              <>
                <dt>{t("fieldName.voteThreshold")}</dt>
                <dd data-cy="vote-threshold">
                  {unit.voteThresholdPercent || "Missing"}
                </dd>
              </>
            ) : null}
          </dl>

          <div className="semibold mt-3">{t("account.workSiteTitle")}</div>
          <dl className="m-2">
            <dt>{t("fieldName.workSite.address")}</dt>
            <dd data-cy="work-site">
              {unit.workSite ? (
                <>
                  {unit.workSite.address}
                  {unit.workSite.address2 ? (
                    <>
                      <br />
                      {unit.workSite.address2}
                    </>
                  ) : null}
                  <br />
                  {unit.workSite.city}, {unit.workSite.state}{" "}
                  {unit.workSite.zip}
                </>
              ) : (
                "Missing"
              )}
            </dd>
            <dt>{t("account.mostlyRemote")}</dt>
            <dd>{unit?.workSite?.isRemote ? "Yes" : "No"}</dd>
          </dl>
        </Card.Body>
      </Card>
    </>
  );
}

export default UnitProfile;
