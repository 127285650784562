import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import {
  getFirestore,
  writeBatch,
  serverTimestamp,
  doc,
  setDoc,
  collection,
  deleteField,
} from "firebase/firestore";

/** Join a Unit using an invite
 * @param {String} uid -- user's uid
 * @param {Object} invite -- the invite being used
 * @param {String} unitId -- the unit being joined
 * @param {Object} coworkerData -- properties of the post-join coworker object associated with this new member (merged)
 * @param {Object} authCardData -- properties of the post-join auth card object associated with this new member
 * @return success
 */
async function joinUnitUsingInvite({
  uid,
  invite,
  unitId,
  coworkerData = {},
  authCardData,
  oc = false,
}) {
  // Setup database batch operation
  const db = firebase.firestore();
  const batch = db.batch();

  addJoinUnitTransactionsToBatch({
    batch,
    db,
    uid,
    unitId,
    invite,
    coworkerData,
    authCardData,
    oc,
  });
  markInviteUsedToBatch({ batch, db, uid, invite, oc });

  await batch.commit();
}

/** For existing members (ie card signers) with an ocInvite:
 * Uses an ocInvite to set isOcMember: true
 * @param {String} unitId
 * @param {String} coworkerId
 * @param {String} ocInviteId
 * @param {String} memberUid
 * @param {String} updatedByUid
 * @param {String} usedByUid
 * @return {Undefined}
 */
async function joinOcAsExistingMember({
  unitId,
  coworkerId,
  ocInviteId,
  memberUid,
  updatedByUid,
  usedByUid,
}) {
  const db = getFirestore();
  const batch = writeBatch(db);
  const data = {
    isOcMember: true,
    updatedByUid,
    updated: serverTimestamp(),
    ocInviteId,
  };

  const coworkerRef = doc(db, `units/${unitId}/coworkers/${coworkerId}`);
  batch.update(coworkerRef, data);

  const membershipRef = doc(db, `memberships/${memberUid}`);
  batch.update(membershipRef, data);

  const ocInviteData = {
    used: serverTimestamp(),
    usedByUid,
  };
  const ocInviteRef = doc(db, `ocInvites/${ocInviteId}`);
  batch.update(ocInviteRef, ocInviteData);

  return batch.commit();
}

/** Join a Unit as the first member
 * @param {String} uid -- user's uid
 * @param {String} unitId -- the unit being joined
 * @param {Object} coworkerData -- properties of the post-join coworker object associated with this new member (merged)
 * @param {Object} authCardData -- properties of the post-join auth card object associated with this new member
 * @return success
 */
async function joinUnitAsFirstMember({
  uid,
  unitId,
  authCardData,
  coworkerData,
}) {
  // Setup database batch operation
  const db = firebase.firestore();
  const batch = db.batch();

  addJoinUnitTransactionsToBatch({
    batch,
    db,
    uid,
    unitId,
    coworkerData,
    authCardData,
    oc: true, // the first member is automatically on the OC
  });

  await batch.commit();
}

function markInviteUsedToBatch({ batch, db, uid, invite, oc }) {
  const timestamp = serverTimestamp();

  const inviteRef = db.collection(oc ? "ocInvites" : "invites").doc(invite.id);
  const inviteData = { used: timestamp, usedByUid: uid };
  batch.update(inviteRef, inviteData, { merge: true });
}

// Note that in Active Campaign, we have an automation that has 60%
// hard-coded. If changing the default voteThresholdPercent, please
// review this automation as well: https://unit85374.activehosted.com/series/19
async function createUnit({
  companyName,
  numNonManagerEmployees,
  industry,
  workSite,
  voteThresholdPercent = 0.6,
  createdByUid,
  hearAboutUs,
}) {
  const data = {
    companyName,
    unitName: `${companyName} United`,
    numNonManagerEmployees,
    industry,
    workSite,
    voteThresholdPercent,
    createdByUid,
    created: serverTimestamp(),
    hearAboutUs,
  };
  await firebase.firestore().collection(`units`).doc().set(data);
}

async function updateUnit({ unitId, unitData, updatedByUid }) {
  const data = {
    unitId,
    updatedByUid,
    updated: serverTimestamp(),
    ...unitData,
  };
  await firebase.firestore().collection(`units`).doc(unitId).update(data);
}

function addJoinUnitTransactionsToBatch({
  batch,
  db,
  uid,
  unitId,
  invite = null,
  coworkerData = {},
  authCardData,
  oc = false,
}) {
  const coworkerRef = matchCoworkerForUserToBatch({
    batch,
    db,
    uid,
    unitId,
    invite,
    coworkerData,
    oc,
  });

  createMembershipToBatch({
    batch,
    db,
    uid,
    unitId,
    invite,
    oc,
    coworkerRef,
  });

  setAuthCardToBatch({
    batch,
    db,
    uid,
    unitId,
    authCardData,
    updatedByUid: uid,
  });
}

function createMembershipToBatch({
  batch,
  db,
  uid,
  unitId,
  invite = null,
  coworkerRef,
  oc = false,
}) {
  const timestamp = serverTimestamp();
  const newMemberRef = db.doc(`memberships/${uid}`);

  const inviteData = {};
  if (invite) {
    inviteData.inviterUid = invite.createdByUid;
    if (invite?.inviter?.coworkerId) {
      inviteData.ocContactCoworkerId = invite.inviter.coworkerId;
    }
    if (oc) {
      inviteData.ocInviteId = invite.id;
    } else {
      inviteData.inviteId = invite.id;
    }
  }
  const newMemberData = {
    unitId,
    created: timestamp,
    coworkerId: coworkerRef.id,
    isOcMember: oc,
    ...inviteData,
  };
  batch.set(newMemberRef, newMemberData);
}

function setAuthCardToBatch({
  batch,
  db,
  uid,
  unitId,
  authCardData,
  merge = false,
  updatedByUid,
}) {
  const timestamp = serverTimestamp();
  authCardData.updated = timestamp;
  if (!merge) {
    authCardData.created = timestamp;
  }
  authCardData.updatedByUid = updatedByUid;
  const authCardRef = db.doc(`units/${unitId}/authCards/${uid}`);

  batch.set(authCardRef, authCardData, { merge });
}

function matchCoworkerForUserToBatch({
  batch,
  db,
  uid,
  unitId,
  invite = null,
  coworkerData = {},
  oc = false,
}) {
  const timestamp = serverTimestamp();
  const coworkerRef = invite
    ? db.doc(`units/${unitId}/coworkers/${invite.invitee.coworkerId}`)
    : db.collection(`units/${unitId}/coworkers`).doc();
  const coworkerDbData = {
    ...coworkerData,
    ...(!invite && { created: timestamp, createdByUid: uid, isDeleted: false }), // Add created if not from invite (new)
    updated: timestamp,
    updatedByUid: uid,
    matchedToMember: timestamp,
    memberUid: uid,
    isOcMember: oc,
  };
  batch.set(coworkerRef, coworkerDbData, { merge: true });
  return coworkerRef;
}

/** Create a new coworker entry in the unit
 * @param {String} unitId
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} mobilePhone
 * @param {String} department
 * @param {String} jobTitle
 * @param {String} email
 * @param {Number} supportRating - integer 1-5 with 1 being most supportive
 * @param {String} createdByUid - the uid of the user creating the entry
 * @return {Undefined}
 */
async function createCoworker({
  unitId,
  firstName,
  lastName,
  mobilePhone,
  department,
  jobTitle,
  email,
  supportRating,
  createdByUid,
  createdBy,
}) {
  const data = {
    firstName,
    lastName,
    mobilePhone,
    department,
    jobTitle,
    email,
    supportRating,
    createdBy, // object with firstName and lastName for history component
    createdByUid,
    created: serverTimestamp(),
    updatedByUid: createdByUid,
    updated: serverTimestamp(),
    isDeleted: false,
  };
  // Don't attempt to store a field if we don't have values for it
  Object.keys(data).forEach((key) => {
    if (data[key] === undefined) {
      delete data[key];
    } else if (key === "supportRating") {
      data[key] = parseInt(data[key]);
    }
  });
  const newCoworkerRef = firebase
    .firestore()
    .collection(`units/${unitId}/coworkers`)
    .doc();
  await newCoworkerRef.set(data);
  return newCoworkerRef.id;
}

/** Save a coworker entry in the unit
 * Data is merged with existing
 * @param {String} unitId
 * @param {String} coworkerId
 * @param {String} updatedByUid -- the user who is updating this entry
 * @param {Object} coworkerData -- fields and values to be saved on the coworker
 * @return {Undefined}
 */
async function saveCoworker({
  unitId,
  coworkerId,
  updatedByUid,
  coworkerData,
}) {
  const db = getFirestore();
  const data = {
    updatedByUid,
    updated: serverTimestamp(),
  };
  // Firestore will not accept a key with an undefined value
  // However if we are passed the key with an undefined value,
  // the user intention may be to remove an existing piece of data
  // Therefore we pass an empty string
  for (let key in coworkerData) {
    if (key === "supportRating" && coworkerData[key]) {
      data[key] = parseInt(coworkerData[key]);
    } else {
      data[key] = coworkerData[key] || "";
    }
  }

  const docRef = doc(db, `units/${unitId}/coworkers/${coworkerId}`);

  await setDoc(docRef, data, { merge: true });
}

/** Save a ocOnly document under coworker entry in the unit
 * Data is merged with existing
 * @param {String} unitId
 * @param {String} coworkerId
 * @param {String} updatedByUid -- the user who is updating this entry
 * @param {Object} coworkerData -- fields and values to be saved on ocOnly document of the coworker
 * @return {Undefined}
 */
async function saveCoworkerOcOnly({
  unitId,
  coworkerId,
  updatedByUid,
  coworkerData,
}) {
  const db = getFirestore();
  const data = {
    updatedByUid,
    updated: serverTimestamp(),
  };
  // Firestore will not accept a key with an undefined value
  // However if we are passed the key with an undefined value,
  // the user intention may be to remove an existing piece of data
  // Therefore we pass an empty string
  for (let key in coworkerData) {
    data[key] = coworkerData[key] || "";
  }

  const docRef = doc(
    db,
    `units/${unitId}/coworkers/${coworkerId}/private/ocOnly`
  );

  await setDoc(docRef, data, { merge: true });
}

/** Save a document under at unit/blah/private/ocOnly
 * Data is merged with existing
 * @param {String} unitIdLoading
 * @param {String} updatedByUid
 * @param {Object} updatedData
 * @return {Undefined}
 */
async function saveUnitOcOnly({ unitId, updatedByUid, updatedData }) {
  const db = getFirestore();
  const data = {
    updatedByUid,
    updated: serverTimestamp(),
    ...updatedData,
  };
  const docRef = doc(db, `units/${unitId}/private/ocOnly`);
  await setDoc(docRef, data, { merge: true });
}

/** Make a coworker an OC member
 * Can only be done by an admin due to firestore permissions on memberships
 * @param {String} unitId
 * @param {String} coworkerId
 * @param {String} memberUid
 * @param {Boolean} isOcMember
 * @param {String} updatedByUid - the admin user who is updating this entry
 * @return {Undefined}
 */
async function updateOcMembership({
  unitId,
  coworkerId,
  memberUid,
  isOcMember,
  updatedByUid,
}) {
  const db = getFirestore();
  const batch = writeBatch(db);
  const data = {
    isOcMember,
    updatedByUid,
    updated: serverTimestamp(),
  };

  const coworkerRef = doc(db, `units/${unitId}/coworkers/${coworkerId}`);
  batch.update(coworkerRef, data);

  const membershipRef = doc(db, `memberships/${memberUid}`);
  batch.update(membershipRef, data);

  return batch.commit();
}

/** Soft delete a coworker document
 * If they're a member, remove their membership
 * and de-authorize their auth card
 * @param {String} unitId
 * @param {String} coworkerId
 * @param {String} memberUid (null if not a member)
 * @param {String} deletedByUid
 * @return {Undefined}
 */
async function deleteCoworker({
  unitId,
  coworkerId,
  memberUid = null,
  deletedByUid,
}) {
  const db = firebase.firestore();
  const batch = db.batch();

  // Soft delete coworker document
  const data = {
    isDeleted: true,
    deletedByUid,
    deleted: serverTimestamp(),
    updatedByUid: deletedByUid,
    updated: serverTimestamp(),
  };
  const coworkerRef = db.doc(`units/${unitId}/coworkers/${coworkerId}`);
  batch.update(coworkerRef, data);

  if (memberUid) {
    // Remove user's membership document
    const membershipRef = db.doc(`memberships/${memberUid}`);
    batch.delete(membershipRef);

    // De-authorize their auth card
    setAuthCardToBatch({
      batch,
      db,
      uid: memberUid,
      unitId,
      authCardData: { authorized: false },
      merge: true,
      updatedByUid: deletedByUid,
    });
  }

  await batch.commit();
}

/** Create a new invite
 * Note that a firebase function will be triggered based on invite creation
 * and will send the invite out
 * @param {String} unitId
 * @param {String} createdByUid - the uid of the user creating the entry
 * @param {Object} invitee - should contain at least coworkerId and firstName
 * @param {Object} inviter - for emails, should contain firstName, lastName, and email if available
 * @param {String} customMessage - optional message from inviter to include in email and join page
 * @param {String} messageBody - The body of the text message to be sent
 *    excluding the link, which will be appended in the firebase function as it goes out
 * @return {Undefined}
 */
async function createInvite({
  unitId,
  createdByUid,
  invitee,
  inviter,
  customMessage,
  messageBody,
  inviteMethod,
  oc = false,
}) {
  const data = {
    unitId,
    createdByUid,
    invitee,
    inviter,
    customMessage,
    messageBody,
    inviteMethod,
    created: serverTimestamp(),
  };
  // customMessage & messageBody are optional, don't store without values
  Object.keys(data).forEach((key) => {
    if (data[key] === undefined) {
      delete data[key];
    }
  });

  // invitee object has optional fields like jobTitle
  Object.keys(data.invitee).forEach((key) => {
    if (data.invitee[key] === undefined) {
      delete data.invitee[key];
    }
  });

  const db = getFirestore();
  const batch = writeBatch(db);

  const inviteRef = doc(collection(db, oc ? `ocInvites` : `invites`));
  batch.set(inviteRef, data);
  // Update lastInvited on coworker
  const coworkerRef = doc(
    db,
    `units/${unitId}/coworkers/${invitee.coworkerId}`
  );
  const coworkerData = {
    updatedByUid: createdByUid,
    updated: serverTimestamp(),
  };
  // If inviting to the OC, mark that on the coworker
  if (oc) {
    coworkerData.lastInvitedToOc = serverTimestamp();
    coworkerData.lastInviteToOcId = inviteRef.id;
    coworkerData.lastOcInviterCoworkerId = inviter.coworkerId;
  } else {
    coworkerData.lastInvited = serverTimestamp();
    coworkerData.lastInviteId = inviteRef.id;
  }
  batch.set(coworkerRef, coworkerData, { merge: true });

  await batch.commit();
}

/** Save an invite
 * Data is merged with existing
 * @param {String} inviteId
 * @param {String} updatedByUid -- the user who is updating this entry
 * @param {Object} inviteData -- fields and values to be saved on the invite
 * @return {Undefined}
 */
async function saveInvite({ inviteId, updatedByUid, inviteData }) {
  const data = {
    updatedByUid,
    updated: serverTimestamp(),
  };
  // TODO maybe make helper function for this, similar cleanup elsewhere in this file
  for (let key in inviteData) {
    data[key] = inviteData[key] || "";
  }

  await firebase
    .firestore()
    .doc(`invites/${inviteId}`)
    .set(data, { merge: true });
}

/** Leave a unit
 * De-authorize the auth card, remove the membership, update the coworker doc
 * @param {String} unitId
 * @param {String} coworkerId
 * @param {String} uid -- the uid of the user to leave the unit
 * @param {String} updatedByUid -- the user who is completing this transaction
 */
async function leaveUnit({
  unitId,
  coworkerId,
  uid,
  updatedByUid,
  supportRating = 3,
}) {
  const db = getFirestore();
  const batch = writeBatch(db);

  // Rescind auth card
  const authCardRef = doc(db, `units/${unitId}/authCards/${uid}`);
  batch.update(authCardRef, {
    authorized: false,
    updated: serverTimestamp(),
    updatedByUid,
  });

  // Delete membership
  const membershipRef = doc(db, `memberships/${uid}`);
  batch.delete(membershipRef);

  // Update coworker doc to remove membership and set support rating
  const coworkerRef = doc(db, `units/${unitId}/coworkers/${coworkerId}`);
  batch.update(coworkerRef, {
    supportRating,
    memberUid: deleteField(),
    matchedToMember: deleteField(),
    updated: serverTimestamp(),
    updatedByUid,
  });

  // TODO: if this was the only user in the unit, the unit should
  // probably self-destruct (do this via firebase function)
  await batch.commit();
}

async function saveTagsOnUnit({ newTags, unitId, updatedByUid }) {
  // always sort the tags alphabetically before saving to the unit
  await saveUnitOcOnly({
    unitId,
    updatedByUid,
    updatedData: {
      unitTags: newTags.sort((a, b) => a.name.localeCompare(b.name)),
    },
  });
}

async function saveTagsOnCoworker({
  coworkerId,
  unitId,
  tagIds,
  updatedByUid,
}) {
  const data = {
    unitId,
    updatedByUid,
    updatedData: {
      coworkerTags: {},
    },
  };
  data.updatedData.coworkerTags[coworkerId] = tagIds;
  await saveUnitOcOnly(data);
}

async function deleteTagFromUnitAndCoworkers({
  tagId,
  unitTags,
  coworkerTags,
  unitId,
  updatedByUid,
}) {
  const data = {
    unitId,
    updatedByUid,
    updatedData: {
      unitTags: unitTags.filter((t) => t.id !== tagId),
    },
  };
  // update coworkerTags key as well as long as at least one coworker had the tag
  const updatedCoworkerTags = {};
  Object.entries(coworkerTags).forEach(([coworkerId, tagIds]) => {
    if (tagIds.includes(tagId)) {
      updatedCoworkerTags[coworkerId] = tagIds.filter((id) => id !== tagId);
    }
  });
  if (Object.keys(updatedCoworkerTags).length > 0) {
    data.updatedData.coworkerTags = updatedCoworkerTags;
  }
  await saveUnitOcOnly(data);
}

export {
  joinUnitUsingInvite,
  joinUnitAsFirstMember,
  joinOcAsExistingMember,
  createCoworker,
  saveCoworker,
  saveCoworkerOcOnly,
  deleteCoworker,
  createInvite,
  saveInvite,
  createUnit,
  updateUnit,
  leaveUnit,
  updateOcMembership,
  deleteTagFromUnitAndCoworkers,
  saveTagsOnUnit,
  saveTagsOnCoworker,
};
