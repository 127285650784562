import useFirestoreQuery from "./useFirestoreQuery";
import { useEffect, useState } from "react";

function usePendingInvites({ unitId, oc = false }) {
  const [queries, setQueries] = useState([["unitId", "==", unitId]]);
  const [queryLoading, setQueryLoading] = useState(true);

  useEffect(() => {
    setQueries([["unitId", "==", unitId]]);
    setQueryLoading(!unitId);
  }, [unitId]);

  const [data, loading, error] = useFirestoreQuery(
    oc ? "ocInvites" : "invites",
    queries,
    queryLoading
  );

  // Each coworker should only have one usable invite, but there may be multiple coworkers with a pending invite
  const usable = data.filter((invite) => !invite.used && !invite.expired);
  return [usable, loading, error];
}

export default usePendingInvites;
