import documentLock from "../assets/images/document-lock.svg";
import browserNotFound from "../assets/images/browser-not-found.svg";
import fourHands from "../assets/images/four-hands.svg";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ImageCard from "./ImageCard";
import { useTranslation } from "react-i18next";
import "./PrivacyInfo.scss";

function PrivacyInfo({ className }) {
  const { t } = useTranslation();

  return (
    <Container className={`text-center privacy-info ${className}`}>
      <Row>
        <Col xs={12} md={4}>
          <ImageCard
            imgSrc={documentLock}
            imgAlt="Document box with a padlock"
            text={t("privacy.fromEmployer")}
          />
        </Col>
        <Col xs={12} md={4}>
          <ImageCard
            imgSrc={browserNotFound}
            imgAlt="A person with a magnifying glass and a not found error in a web browser."
            text={t("privacy.noSell")}
          />
        </Col>
        <Col xs={12} md={4}>
          <ImageCard
            imgSrc={fourHands}
            imgAlt="Documents with a check mark and magnifying glass"
            text={t("privacy.helpOrganize")}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default PrivacyInfo;
