import { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  onSnapshot,
  getFirestore,
} from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";

/* Hook to subscribe query on a firestore collection
 * @param {String} collectionPath - a path to the collection, delineated by slashes
 *   eg "collectionA/docX/collectionB"
 *   You can pass a null collectionPath and the hook will simply set data to null and loading false
 * @param {Array} query -- a firestore query of the format [fieldName, operator, value]
 *   Be careful not to create a new array on every render, or you'll get a loop
 * @param {Boolean} queryLoading - a boolean representing whether the necessary data for the query is
 *   currently loading. If so, we set the data to null and loading to true.
 *
 * @returns {Array} [data, loading, error]
 * data will contain an array with objects representing the documents retrieved
 */
function useFirestoreQuery(collectionPath, filters, queryLoading) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (queryLoading) {
      setData([]);
      setLoading(true);
      return;
    }
    if (!collectionPath) {
      setData([]);
      setLoading(false);
      return;
    }

    const db = getFirestore();
    let queryRef = query(collection(db, collectionPath));
    // Add each filter one by one. If multiple filters target different fields then Firestore might
    // generate an error in console.error below with a link to create a composite index. My recommendation
    // is to follow that link and see what index it wants, but rather than hit the create button create in the UI,
    // recreate what it wants in firestore.indexes.json
    filters.forEach((filter) => {
      queryRef = query(queryRef, where(...filter));
    });

    const handleData = (snapshot) => {
      if (snapshot.empty) {
        setData([]);
        setLoading(false);
      } else {
        const newData = [];
        snapshot.forEach((doc) => {
          newData.push({ ...doc.data(), id: doc.id });
        });
        setData(newData);
        setLoading(false);
      }
    };

    const handleError = (error) => {
      if (process.env.REACT_APP_development) {
        console.error(
          `Error with Firestore query on ${collectionPath} with filters: ${filters}`
        );
        console.error(error);
      }
      // do we actually want to display this to the user? it happens to get hidden behind a permanent spinner at the moment
      // because on error we never setLoading(false)
      setError(error);
      const analytics = getAnalytics();
      logEvent(analytics, "app_error", {
        error_message: error.message ?? "Unspecified",
        error_name: error.name ?? "FirebaseError",
        error_path: `useFirestoreQuery on ${collectionPath}`,
      });
    };

    const unsubscribe = onSnapshot(queryRef, handleData, handleError);

    return unsubscribe;
  }, [collectionPath, queryLoading, filters, setData, setLoading, setError]);

  return [data, loading, error];
}

export default useFirestoreQuery;
