import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import ContactUnitButton from "../../../components/buttonsAndLinks/ContactUnitButton";

function JoinOcWrongUnit({ firstName, unitName }) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <p>
        {t("joinOc.wrongUnit", {
          firstName,
          unitName,
        })}
      </p>
      <p>{t("joinOc.wrongUnit2")}</p>
      <div className="text-right mb-3">
        <Button
          data-cy="my-account-button"
          onClick={() => history.push("/account")}
        >
          {t("joinOc.myAccount")}
        </Button>
      </div>
      <p>{t("joinOc.wrongUnit3")}</p>
      <div className="text-right">
        <ContactUnitButton />
      </div>
    </>
  );
}

export default JoinOcWrongUnit;
