import Button from "react-bootstrap/Button";
import Spinner from "../../components/Spinner";
import LinkButton from "../../components/buttonsAndLinks/LinkButton";
import { useState } from "react";
import FormFields from "../../components/FormFields";
import { useForm } from "react-hook-form";
import { saveInvite } from "../../utilities/firestore";
import Card from "react-bootstrap/Card";
import InviteOnlyOnce from "./InviteOnlyOnce";
import { useTranslation } from "react-i18next";
import InviteUrl from "../../components/InviteUrl";

function DisplayInviteLink({ inviteUrl, message, invite, firstName, inviter }) {
  const { t } = useTranslation();
  const createdDateTime = invite?.created
    ? invite.created.toDate().toLocaleString(undefined, {
        dateStyle: "short",
        timeStyle: "short",
      })
    : null;

  return inviteUrl ? (
    <>
      <div>
        <div data-cy="link-label" htmlFor="link">
          {t("invite.linkCreated")} {createdDateTime}
        </div>

        <CustomMessage invite={invite} inviter={inviter} />
        <InviteOnlyOnce firstName={firstName} />
        <InviteUrl inviteUrl={inviteUrl} shareText={message} />
      </div>
    </>
  ) : (
    <Spinner />
  );
}

// This subcomponent handles displaying, editing, and saving custom messages for link invites
function CustomMessage({ invite, inviter }) {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const { handleSubmit, control, errors } = useForm();
  const fields = [
    {
      name: "customMessage",
      type: "textarea",
      defaultValue: invite?.customMessage,
      rows: 4,
    },
  ];

  async function save(data) {
    setIsSaving(true);
    const updateData = {
      inviteId: invite.id,
      updatedByUid: inviter.uid,
      inviteData: data,
    };
    await saveInvite(updateData);
    setIsEditing(false);
    setIsSaving(false);
  }

  if (isSaving) {
    return (
      // not sure why but the spinner looks super cramped without the padding
      <div className="mb-4">
        <Spinner />
      </div>
    );
  }

  if (isEditing) {
    return (
      <>
        <FormFields
          className="mt-3"
          fields={fields}
          control={control}
          errors={errors}
        />
        <div className="d-flex justify-content-end mb-3">
          <Button
            size="sm"
            disabled={isSaving}
            data-cy="save-custom-message"
            onClick={handleSubmit(save)}
          >
            {t("invite.save")}
          </Button>
        </div>
      </>
    );
  }

  if (invite?.customMessage) {
    return (
      <>
        <Card bg="light" className="my-3 pb-1">
          <Card.Body>
            <p className="text-secondary" data-cy="view-custom-message">
              {invite.customMessage}
            </p>

            <div className="d-flex justify-content-end">
              <small>
                <LinkButton
                  data-cy="edit-custom-message"
                  onClick={() => setIsEditing(true)}
                >
                  {t("invite.edit")}
                </LinkButton>
              </small>
            </div>
          </Card.Body>
        </Card>
      </>
    );
  } else {
    return (
      <small className="my-3 d-block">
        <LinkButton
          data-cy="add-custom-message"
          onClick={() => setIsEditing(true)}
        >
          {t("invite.addCustomMessage")}
        </LinkButton>
      </small>
    );
  }
}
export default DisplayInviteLink;
