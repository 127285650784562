import FormFields from "../../components/FormFields";
import { useTranslation } from "react-i18next";

function CaptureMobilePhone({ control, errors, firstName }) {
  const { t } = useTranslation();

  const fields = [
    {
      name: "mobilePhone",
      label: t("fieldName.mobilePhone"),
      type: "phone",
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.mobilePhone"),
        }),
      },
    },
  ];

  return (
    <>
      <p>{t("invite.missingMobile", { firstName })}</p>
      <FormFields fields={fields} control={control} errors={errors} />
    </>
  );
}

export default CaptureMobilePhone;
