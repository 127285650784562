import Tooltip from "../Tooltip";
import OutboundLink from "./OutboundLink";
import { formatPhoneNumber } from "react-phone-number-input";
import { useTranslation } from "react-i18next";

/*
 * An tel: link with an explanatory tooltip
 *
 * @param {Boolean} show
 * @param {String} phoneNumber -- ie +16172223333
 * @param {Boolean} disabled
 * @param {String} data-cy -- will get "link" appended
 */
function PhoneLink({
  show = true,
  phoneNumber,
  disabled = false,
  "data-cy": dataCy = "phone", // alias to allow hyphenated prop name
  ...props
}) {
  const { t } = useTranslation();

  if (!show || !phoneNumber) return null;

  const formattedPhoneNumber = formatPhoneNumber(phoneNumber);

  const tooltipText = t("callNumber", { number: formattedPhoneNumber });

  return (
    <Tooltip contents={tooltipText}>
      <OutboundLink
        disabled={disabled}
        data-cy={`${dataCy}-link`}
        href={`tel:${phoneNumber}`}
        onClick={(e) => e.stopPropagation()}
        {...props}
      >
        {formattedPhoneNumber}
      </OutboundLink>
    </Tooltip>
  );
}

export default PhoneLink;
