import { useState, useEffect } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";

// like useFirestoreQuery but with a single get request instead of subscription
function useFirestoreOnce(collectionPath, filters, queryLoading) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        if (queryLoading) {
          setData([]);
          setLoading(true);
          return;
        }
        if (!collectionPath) {
          setData([]);
          setLoading(false);
          return;
        }

        const db = getFirestore();
        let queryRef = query(collection(db, collectionPath));
        // Add each filter one by one. If multiple filters target different fields then Firestore might
        // generate an error in console.error below with a link to create a composite index. My recommendation
        // is to follow that link and see what index it wants, but rather than hit the create button create in the UI,
        // recreate what it wants in firestore.indexes.json
        filters.forEach((filter) => {
          queryRef = query(queryRef, where(...filter));
        });

        const querySnapshot = await getDocs(queryRef);
        const newData = [];
        querySnapshot.forEach((doc) => {
          newData.push({ ...doc.data(), id: doc.id });
        });
        setData(newData);
        setLoading(false);
      } catch (error) {
        if (process.env.REACT_APP_development) {
          console.error(
            `Error with Firestore query on ${collectionPath} with filters: ${filters}`
          );
          console.error(error);
        }
        // do we actually want to display this to the user? it happens to get hidden behind a permanent spinner at the moment
        // because on error we never setLoading(false)
        setError(error);
        const analytics = getAnalytics();
        logEvent(analytics, "app_error", {
          error_message: error.message ?? "Unspecified",
          error_name: error.name ?? "FirebaseError",
          error_path: `useFirestoreOnce on ${collectionPath}`,
        });
      }
    }
    fetchData(); // React pattern to avoid async useEffect
  }, [collectionPath, queryLoading, filters, setData, setLoading, setError]);

  return [data, loading, error];
}

export default useFirestoreOnce;
