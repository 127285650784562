import useFirestoreQuery from "./useFirestoreQuery";
import { useState } from "react";

function useAuthCards({ unitId }) {
  const [queries] = useState([["authorized", "==", true]]);
  const [data, loading, error] = useFirestoreQuery(
    `units/${unitId}/authCards`,
    queries,
    !unitId
  );

  return [data, loading, error];
}

export default useAuthCards;
