import { useEffect, useMemo } from "react";
import React from "react";
import UnitTable from "../../../components/table/UnitTable";
import { formatPhoneNumber } from "react-phone-number-input";
import {
  useFilters,
  useGlobalFilter,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import useViewportSize from "../../../hooks/useViewportSize";
import useBreakpointColumns from "../../../hooks/useBreakpointColumns";
import NameAndTitle from "../../../components/NameAndTitle";
import EmailLink from "../../../components/buttonsAndLinks/EmailLink";
import { useTranslation } from "react-i18next";

// Column headers and accessors for members table
function getColumns(t) {
  return [
    {
      Header: (
        <NameAndTitle
          firstName={t("signatures.table.name")}
          lastName=""
          jobTitle={t("fieldName.jobTitle")}
        />
      ),
      id: "nameTitle",
      accessor: (row) => `${row?.firstName} ${row?.lastName} ${row.jobTitle}`,
      Cell: ({ row }) => (
        <NameAndTitle
          coworker={row.original}
          showSigned={false} // hide ✅ since everyone on page has it
          linkTo={{
            pathname: `/coworker/${row.original.id}`,
            state: { from: "/signatures" },
          }}
        />
      ),
    },
    {
      Header: t("fieldName.mobilePhone"),
      id: "mobilePhone",
      accessor: (row) => formatPhoneNumber(row?.mobilePhone),
    },
    {
      Header: t("fieldName.email"),
      accessor: "email",
      Cell: ({ value }) => <EmailLink emails={[value]} />,
    },
    {
      id: "contactLinks",
      accessor: "email",
      Cell: ({ value }) => <EmailLink emails={[value]} asIcon={true} />,
    },
  ];
}

const hiddenOnSmallScreens = ["mobilePhone", "email"];
const hiddenOnLargeScreens = [];

/**
 * A table containing the members of the unit
 **/
function SignaturesTable({
  onClickMember,
  members = [],
  filterText,
  setEmails,
}) {
  const { t } = useTranslation();
  const { breakpoint } = useViewportSize();

  const initialState = useMemo(() => {
    const hiddenColumns = ["xs", "sm", "md"].includes(breakpoint)
      ? hiddenOnSmallScreens
      : hiddenOnLargeScreens;
    return {
      sortBy: [{ id: "nameTitle", desc: false }],
      hiddenColumns,
    };
  }, [breakpoint]);

  const columns = useMemo(() => {
    return getColumns(t);
  }, [t]);

  const reactTable = useTable(
    {
      columns,
      data: members,
      initialState,
      disableSortRemove: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useRowSelect, // needed for below hook
    // Here we will use a plugin to add our selection column from
    // https://react-table.tanstack.com/docs/examples/row-selection
    // Our changes are 1) pass in row in IndeterminateCheckbox 2) remove unnecessary divs
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            // "selection" is a magic id that prevents click hijacking in TableBody.js
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <IndeterminateCheckbox
                row={row}
                {...row.getToggleRowSelectedProps()}
              />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  // Global filter
  useEffect(() => {
    reactTable.setGlobalFilter(filterText);
  }, [filterText, reactTable]);

  // keep emails in sync with selected rows in the table
  useEffect(() => {
    setEmails(
      reactTable.selectedFlatRows
        .map((x) => x.original.email)
        .filter((x) => x !== undefined)
    );
  }, [reactTable.selectedFlatRows, setEmails]);

  useBreakpointColumns({
    setHiddenColumns: reactTable.setHiddenColumns,
    breakpoint,
    hiddenOnLargeScreens,
    hiddenOnSmallScreens,
  });

  return (
    <UnitTable
      onClickEntry={onClickMember}
      filterText={filterText}
      reactTable={reactTable}
    />
  );
}

// IndeterminateCheckbox for table selection from
// https://react-table.tanstack.com/docs/examples/row-selection
// our changes are just to pass in row, set data-cy based on it, and sometimes hide the checkbox
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, row, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      if (resolvedRef.current) {
        resolvedRef.current.indeterminate = indeterminate;
      }
    }, [resolvedRef, indeterminate]);

    // Hide the checkbox from members that don't have emails. I also looked into disabling the checkbox
    // but the reactTable methods that check all still sets it through disabled. It'd also work to leave
    // the pointless checkmark since the email button can handle the missing emails anyway.
    if (row && !row.original.email) {
      return null;
    }

    return (
      <>
        <input
          type="checkbox"
          data-cy={!row ? "toggle-all-checkbox" : null}
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);

export default SignaturesTable;
