import { useContext, useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { NavLink } from "react-router-dom";
import { logOut } from "../utilities/auth";
import { UserUnitContext } from "../contexts/userUnitContext";
import UnitNameLogo from "./UnitNameLogo";
import LinkButton from "./buttonsAndLinks/LinkButton";
import { useTranslation } from "react-i18next";
import OutboundLink from "./buttonsAndLinks/OutboundLink";
import LanguageSwitcher from "./LanguageSwitcher";

function NavBar({
  showNav = true,
  showLoginOut = true,
  showAccountDropdown = true,
}) {
  const { unit, user } = useContext(UserUnitContext);
  const [showAccountLink, setShowAccountLink] = useState(false);
  const { t } = useTranslation();

  // Only show the Account link if the user has a unit or a password to edit
  // TODO Remove this when the Account page has enough on it that we always want to link to it
  useEffect(() => {
    const canEditPassword = user?.providerData?.some(
      (provider) => provider.providerId === "password"
    );
    if (unit || canEditPassword) {
      setShowAccountLink(true);
    } else {
      setShowAccountLink(false);
    }
  }, [unit, user]);

  const logoContents = (
    <UnitLogoLink
      oc={user?.isOcMember || user?.isAdmin}
      unitName={unit?.unitName}
    />
  );
  const appNavContents = (
    <AppNav
      className="mr-auto"
      showMemberLinks={unit || user?.isAdmin}
      oc={user?.isOcMember || user?.isAdmin}
      t={t}
    />
  );
  const languageAndAccountContents = (
    <Nav className="ml-auto">
      <LanguageSwitcher />

      {showAccountLink && showAccountDropdown ? (
        <AccountDropdown user={user} t={t} />
      ) : (
        showLoginOut && <LogInOut loggedIn={!!user} t={t} />
      )}
    </Nav>
  );

  return showNav ? (
    <Navbar expand="lg">
      {logoContents}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        {appNavContents}
        {languageAndAccountContents}
      </Navbar.Collapse>
    </Navbar>
  ) : (
    <Navbar>
      {logoContents}
      {languageAndAccountContents}
    </Navbar>
  );
}

function LogInOut({ loggedIn, t }) {
  return loggedIn ? (
    <LinkButton className="nav-link" onClick={logOut}>
      {t("account.logout")}
    </LinkButton>
  ) : (
    <NavLink className="nav-link" to="/login">
      {t("account.login")}
    </NavLink>
  );
}

function UnitLogoLink({ oc = false, unitName }) {
  return !unitName ? (
    <OutboundLink href={process.env.REACT_APP_staticHostname}>
      <UnitNameLogo text="unit" />
    </OutboundLink>
  ) : (
    <NavLink to={oc ? "/ochome" : "/memberhome"}>
      <UnitNameLogo text={unitName || "unit"} />
    </NavLink>
  );
}

function AppNav({ showMemberLinks, oc, t, ...props }) {
  return (
    <Nav {...props}>
      {oc ? (
        <NavLink className="nav-link" to="/ochome">
          {t("pageName.ochome")}
        </NavLink>
      ) : (
        <NavLink className="nav-link" to="/memberhome">
          {t("pageName.home")}
        </NavLink>
      )}

      {showMemberLinks && (
        <>
          <NavLink className="nav-link" to="/signatures">
            {t("pageName.signatures")}
          </NavLink>
          <NavLink className="nav-link" to="/coworkers">
            {t("pageName.coworkers")}
          </NavLink>
        </>
      )}

      {oc && (
        <>
          <NavLink className="nav-link" to="/oc">
            {t("pageName.oc")}
          </NavLink>
        </>
      )}
    </Nav>
  );
}

function AccountDropdown({ t, user }) {
  return (
    <NavDropdown
      alignRight
      title={user?.firstName || user?.email}
      id="account-nav-dropdown"
    >
      <NavDropdown.Item href="/account">
        {t("pageName.account")}
      </NavDropdown.Item>
      <NavDropdown.Divider />
      <LinkButton className="dropdown-item" onClick={logOut}>
        {t("account.logout")}
      </LinkButton>
    </NavDropdown>
  );
}

export default NavBar;
