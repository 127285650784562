import SupportRating from "../../components/SupportRating";
import SupportSelect from "../../components/SupportSelect";
import { useTranslation, Trans } from "react-i18next";

function UpdateSupport({
  control,
  errors,
  firstName,
  supportRating,
  onChange,
}) {
  const { t } = useTranslation();

  return (
    <div data-cy="invite-update-support">
      <p>
        <Trans
          i18nKey="invite.updateSupport.onlySupporters"
          components={[
            <SupportRating badge rating={supportRating}></SupportRating>,
          ]}
          values={{ firstName }}
        />
      </p>
      <p>{t("invite.updateSupport.pleaseUpdate")}</p>
      <SupportSelect control={control} errors={errors} onChange={onChange} />
    </div>
  );
}

export default UpdateSupport;
