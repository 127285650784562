import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import YouCheck from "../../../components/YouCheck";
import TransLinks from "../../../components/TransLinks";
import { ProgressBar } from "react-bootstrap";

function BuildOcCard({ numNonManagerEmployees, numOcGoal, ocMembers }) {
  const { t } = useTranslation();

  return (
    <Card data-cy="build-oc-card">
      <Card.Body>
        <Card.Title>{t("home.buildOc.title")}</Card.Title>

        <ProgressBar
          now={100 * (ocMembers.length / numOcGoal)}
          className="mb-2"
        />
        <p>
          {t("home.buildOc.explanatoryText", {
            numNonManagerEmployees,
            numOcGoal,
          })}
        </p>

        <TransLinks
          i18nKey="home.buildOc.1"
          guide="/how-to-talk-to-your-coworkers-about-unionizing/"
        />
        <ul>
          <li>
            <TransLinks i18nKey="home.buildOc.2" url="/oc" />
          </li>
          <li>
            <TransLinks
              i18nKey="home.buildOc.3"
              guide="/whats-an-organizing-committee/"
            />
          </li>
        </ul>

        {t("home.buildOc.currentMembers")}
        <ul className="list-unstyled ml-3">
          {ocMembers // sort oc members by name ascending
            .sort((a, b) =>
              `${a.firstName} ${a.lastName}`.localeCompare(
                `${b.firstName} ${b.lastName}`
              )
            )
            .map((coworker) => (
              <li key={`coworker-${coworker.id}`}>
                {coworker.firstName} {coworker.lastName}{" "}
                <YouCheck memberUid={coworker.memberUid} />
              </li>
            ))}
        </ul>

        <Link data-cy="build-oc-link" to="/oc">
          <Button size="sm">{t("home.buildOc.buildOc")}</Button>
        </Link>
      </Card.Body>
    </Card>
  );
}

export default BuildOcCard;
