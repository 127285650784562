import Modal from "../Modal";
import Spinner from "../../components/Spinner";
import { useForm } from "react-hook-form";
import UnitFormFields from "../../components/UnitFormFields";
import { updateUnit } from "../../utilities/firestore";
import { useContext } from "react";
import { UserUnitContext } from "../../contexts/userUnitContext";
import firebase from "firebase/compat/app";
import Button from "react-bootstrap/Button";
import { ModalContext } from "../../contexts/modalContext";
import FormFields from "../../components/FormFields";
import { useTranslation } from "react-i18next";

// Edit the current unit
function EditUnitModal() {
  const { user, unit, loading } = useContext(UserUnitContext);
  const { handleSubmit, formState, control, errors } = useForm();
  const { setShowModal } = useContext(ModalContext);
  const { t } = useTranslation();

  if (!unit) return null;

  async function onSubmit({
    unitName,
    companyName,
    industry,
    numNonManagerEmployees,
    address,
    address2,
    city,
    state,
    zip,
    isRemote,
    voteThresholdPercent,
  }) {
    const unitData = {
      unitName,
      companyName,
      industry: industry[0], // Typeahead uses an array
      numNonManagerEmployees: parseInt(numNonManagerEmployees),
      workSite: {
        address,
        address2,
        city,
        state,
        zip,
        isRemote: !!isRemote, // otherwise we write "" instead of false to firestore
      },
      updatedByUid: user.uid,
    };
    if (voteThresholdPercent) {
      unitData.voteThresholdPercent = parseFloat(voteThresholdPercent);
    }
    await updateUnit({ unitId: unit.id, unitData, updatedByUid: user.uid });
    firebase.analytics().logEvent("unit_updated");
    setShowModal(false);
  }

  const unitFields = [
    {
      name: "unitName",
      label: t("fieldName.unitName"),
      type: "text",
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.unitName"),
        }),
      },
      defaultValue: unit.unitName,
    },
  ];

  if (user.isAdmin) {
    unitFields.push({
      name: "voteThresholdPercent",
      label: t("fieldName.voteThreshold"),
      type: "number",
      rules: {
        required: t("fieldValidation.isRequired", {
          fieldName: t("fieldName.voteThreshold"),
        }),
      },
      defaultValue: unit.voteThresholdPercent,
    });
  }

  return (
    <Modal
      title={t("editUnit.modalTitle")}
      body={
        loading ? (
          <Spinner />
        ) : (
          <>
            <h4 className="mt-3">{t("editUnit.unitTitle")}</h4>
            <FormFields control={control} errors={errors} fields={unitFields} />
            <UnitFormFields
              control={control}
              errors={errors}
              defaults={{ ...unit, industry: [unit.industry] }}
              showOptional={false}
            />
          </>
        )
      }
      footerActions={
        <Button
          disabled={formState.isSubmitting}
          data-cy="update-unit-submit"
          onClick={handleSubmit(onSubmit)}
        >
          {t("editUnit.submit")}
        </Button>
      }
      data-cy="edit-unit-modal"
    />
  );
}

export default EditUnitModal;
