import { useContext } from "react";
import PageLayout from "../../components/PageLayout";
import { UserUnitContext } from "../../contexts/userUnitContext";
import { useTranslation } from "react-i18next";
import PrivacyDetail from "../../components/PrivacyDetail";
import useCoworkers from "../../hooks/useCoworkers";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";
import usePendingInvites from "../../hooks/usePendingInvites";
import OcMembersTable from "./components/OcMembersTable";
import { useHistory } from "react-router-dom";
import { ModalContext } from "../../contexts/modalContext";
import { ocMemberGoal } from "../../utilities/oc";
import OutboundLink from "../../components/buttonsAndLinks/OutboundLink";
import QuestionCircleIcon from "../../components/icons/QuestionCircleIcon";
import Tooltip from "../../components/Tooltip";
import LinkButton from "../../components/buttonsAndLinks/LinkButton";

function OcPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { user, unit } = useContext(UserUnitContext);
  const [coworkers, coworkersLoading, coworkersError] = useCoworkers({
    unitId: unit?.id,
  });
  const [invites, invitesLoading, invitesError] = usePendingInvites({
    unitId: unit?.id,
    oc: true,
  });

  return (
    <PageLayout pageTitle={t("oc.shortTitle")} requireVerifiedEmail>
      <OcPageContents
        user={user}
        unit={unit}
        coworkers={coworkers}
        invites={invites}
        history={history}
        loading={coworkersLoading || invitesLoading}
        error={coworkersError || invitesError}
      />
    </PageLayout>
  );
}

function OcStatistics({ unit, coworkers, pendingOcInviteIds }) {
  const { t } = useTranslation();

  const memberCount = coworkers.filter((c) => c.isOcMember).length;
  // double check their lastInviteToOcId against the pending invites to see if it's still good
  const invitePendingCount = coworkers.filter(
    (c) =>
      !c.isOcMember &&
      c.lastInviteToOcId &&
      pendingOcInviteIds.has(c.lastInviteToOcId)
  ).length;

  return (
    <>
      <p className="mx-3 my-2">
        <span data-cy="oc-member-count">
          {t("oc.members")}: {memberCount}
        </span>
        <br />
        <span data-cy="goal-member-count">
          {t("oc.goal")}: {ocMemberGoal(unit.numNonManagerEmployees)}{" "}
          {t("oc.goalOrMore")}
        </span>
        <br />
        <span data-cy="invite-pending-count">
          {t("oc.invitesPending")}: {invitePendingCount}
        </span>
      </p>
    </>
  );
}

function OcPageContents({
  unit,
  user,
  coworkers,
  invites,
  history,
  loading,
  error,
}) {
  const { t } = useTranslation();
  const { setModal, setShowModal } = useContext(ModalContext);

  const fromCoworkerIdToInviterName = new Map(
    coworkers.map((coworker) => [
      coworker.id,
      coworker.id === user.id
        ? "You"
        : `${coworker.firstName} ${coworker.lastName}`.trim(),
    ])
  );

  if (loading) return <Spinner />;
  if (error) return <Error error={error} />;
  // double check their lastInviteToOcId against the pending invites to see if it's still good
  const pendingOcInviteIds = new Set(invites.map((i) => i.id));
  const activeAndPendingOcMembers = coworkers
    .filter(
      (coworker) =>
        coworker.isOcMember ||
        (coworker.lastInviteToOcId &&
          pendingOcInviteIds.has(coworker.lastInviteToOcId))
    )
    .map((coworker) => {
      const pendingOcInvite = invites.find(
        (i) => i.id === coworker.lastInviteToOcId
      );
      const inviterCoworkerId =
        pendingOcInvite?.inviter?.coworkerId ||
        coworker?.lastOcInviterCoworkerId;
      const invitedBy =
        fromCoworkerIdToInviterName.get(inviterCoworkerId) ?? "--";
      return {
        ...coworker,
        invitedBy,
        pendingOcInvite,
      };
    });

  return (
    <>
      <h3>{t("oc.title")}</h3>
      <PrivacyDetail
        contents={t("privacy.oc")}
        placement="bottom"
        triggerText={t("privacy.whoCanSee.oc")}
      />
      <div>
        <OutboundLink
          href={`${process.env.REACT_APP_knowledgeBaseHostname}/whats-an-organizing-committee/`}
        >
          <QuestionCircleIcon className="inline-icon" />
          {t("oc.whatIs")}
        </OutboundLink>
      </div>
      <Tooltip
        contents={t("oc.whoInviteContents")}
        placement="bottom"
        trigger="click"
      >
        <LinkButton className="text-nowrap">
          <QuestionCircleIcon className="inline-icon" />
          {t("oc.whoInvite")}
        </LinkButton>
      </Tooltip>

      <OcStatistics
        unit={unit}
        pendingOcInviteIds={pendingOcInviteIds}
        coworkers={coworkers}
      />
      <OcMembersTable
        onClickCoworker={(coworker) => {
          if (coworker.id)
            history.push("/coworker/" + coworker.id, {
              from: "/oc",
            });
        }}
        onClickNewOcMember={() => {
          setModal({
            name: "inviteToOc",
          });
          setShowModal(true);
        }}
        coworkers={activeAndPendingOcMembers}
      />
    </>
  );
}

export default OcPage;
